import React, { useRef } from "react";
import { FiShare2 } from "react-icons/fi";

const ShareButtons = ({ url }) => {
  const urlRef = useRef(null);

  const copyToClipboard = () => {
    if (urlRef.current) {
      const range = document.createRange();
      range.selectNode(urlRef.current);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("copy");
      selection.removeAllRanges();
    }
  };

  return (
    <div className="flex items-center">
      <div className="flex w-[200px] h-[30px] md:w-[290px] md:h-[40px]   py-1 overflow-hidden px-2 border-1 hover:bg-gray-900 hover:text-[#fff] active:bg-[#5ab2ff] ease-in-out duration-200 rounded-2xl border-gray-900 align-items-center justify-content-between">
        <div className="text-share text-xs whitespace-nowrap overflow-hidden  w-6/12 md:w-7/12" ref={urlRef}>
          {url}
        </div>
        <span
          onClick={copyToClipboard}
          className=" flex justify-center items-center w-6/12 md:w-5/12 text-xs md:text-sm py-1 px-1 "
          style={{ cursor: "pointer" }}
        >
          <FiShare2 size={15} />
          &nbsp; Copy Link
        </span>
      </div>
      <div className="d-flex justify-content-between gap-2">
        <a
          href={`https://www.linkedin.com/shareArticle?url=${url}`}
          target="blank"
          className="i-button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            width="24"
            className="w-6 h-6 text-gray-800 dark:text-white"
            viewBox="0,0,256,256"
          >
            <g
              fill="#ffffff"
              fill-rule="nonzero"
              stroke="none"
              strokeWidth="1"
              strokeLinecap="butt"
              stroke-linejoin="miter"
              stroke-miterlimit="10"
              strokeDasharray=""
              stroke-dashoffset="0"
              font-family="none"
              font-weight="none"
              font-size="none"
              text-anchor="none"
            >
              <g transform="scale(8.53333,8.53333)">
                <path d="M24,4h-18c-1.105,0 -2,0.895 -2,2v18c0,1.105 0.895,2 2,2h18c1.105,0 2,-0.895 2,-2v-18c0,-1.105 -0.895,-2 -2,-2zM10.954,22h-2.95v-9.492h2.95zM9.449,11.151c-0.951,0 -1.72,-0.771 -1.72,-1.72c0,-0.949 0.77,-1.719 1.72,-1.719c0.948,0 1.719,0.771 1.719,1.719c0,0.949 -0.771,1.72 -1.719,1.72zM22.004,22h-2.948v-4.616c0,-1.101 -0.02,-2.517 -1.533,-2.517c-1.535,0 -1.771,1.199 -1.771,2.437v4.696h-2.948v-9.492h2.83v1.297h0.04c0.394,-0.746 1.356,-1.533 2.791,-1.533c2.987,0 3.539,1.966 3.539,4.522z"></path>
              </g>
            </g>
          </svg>
        </a>
        <a
          href={`https://api.whatsapp.com/send?text=${url}`}
          target="blank"
          className="i-button"
        >
          <svg
            viewBox="0 0 24 24"
            fill="none"
            height="24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            className="w-6 h-6 text-gray-800 dark:text-white"
          >
            <path
              clipRule="evenodd"
              d="M12 4a8 8 0 0 0-6.895 12.06l.569.718-.697 2.359 2.32-.648.379.243A8 8 0 1 0 12 4ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.96 9.96 0 0 1-5.016-1.347l-4.948 1.382 1.426-4.829-.006-.007-.033-.055A9.958 9.958 0 0 1 2 12Z"
              fillRule="evenodd"
              fill="currentColor"
            ></path>
            <path
              d="M16.735 13.492c-.038-.018-1.497-.736-1.756-.83a1.008 1.008 0 0 0-.34-.075c-.196 0-.362.098-.49.291-.146.217-.587.732-.723.886-.018.02-.042.045-.057.045-.013 0-.239-.093-.307-.123-1.564-.68-2.751-2.313-2.914-2.589-.023-.04-.024-.057-.024-.057.005-.021.058-.074.085-.101.08-.079.166-.182.249-.283l.117-.14c.121-.14.175-.25.237-.375l.033-.066a.68.68 0 0 0-.02-.64c-.034-.069-.65-1.555-.715-1.711-.158-.377-.366-.552-.655-.552-.027 0 0 0-.112.005-.137.005-.883.104-1.213.311-.35.22-.94.924-.94 2.16 0 1.112.705 2.162 1.008 2.561l.041.06c1.161 1.695 2.608 2.951 4.074 3.537 1.412.564 2.081.63 2.461.63.16 0 .288-.013.4-.024l.072-.007c.488-.043 1.56-.599 1.804-1.276.192-.534.243-1.117.115-1.329-.088-.144-.239-.216-.43-.308Z"
              fill="currentColor"
            ></path>
          </svg>
        </a>

        <a
          href={`https://twitter.com/intent/post?url=${url}`}
          target="blank"
          className="i-button"
        >
          <svg
            viewBox="0 0 24 24"
            fill="currentColor"
            height="24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            className="w-6 h-6 text-gray-800 dark:text-white"
          >
            <path
              clipRule="evenodd"
              d="M22 5.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.343 8.343 0 0 1-2.605.981A4.13 4.13 0 0 0 15.85 4a4.068 4.068 0 0 0-4.1 4.038c0 .31.035.618.105.919A11.705 11.705 0 0 1 3.4 4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 6.1 13.635a4.192 4.192 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 2 18.184 11.732 11.732 0 0 0 8.291 20 11.502 11.502 0 0 0 19.964 8.5c0-.177 0-.349-.012-.523A8.143 8.143 0 0 0 22 5.892Z"
              fillRule="evenodd"
            ></path>
          </svg>
        </a>
      </div>
      {/* </div> */}
    </div>
  );
};

export default ShareButtons;
