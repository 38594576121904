import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Loader2 from "../../components/Loaders/Loader2";
import KycPopUp from "../../components/PopUp1/PopUp2";
import { IoEyeSharp } from "react-icons/io5";
import { HiMiniEyeSlash } from "react-icons/hi2";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import AlertGreen from "../../components/Alerts/AlertGreen.jsx";
import AlertRed from "../../components/Alerts/AlertRed.jsx";
import "./Login.css";
import secureLocalStorage from "react-secure-storage";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { Helmet } from "react-helmet";
import AdminInfoApp from "../../API/AdminApi.js";
import EmployeeInfoApp from "../../API/Employee.js";
import AgentInfoApp from "../../API/AgentInfoApi.js";

function Login() {
  const { pan } = CheckAuth();
  const [username, setUsername] = useState("");
  const [loader, setLoader] = useState(false);
  const [pass, setPass] = useState("");
  const [kycPop, setKycPop] = useState(false);
  const [verifyLogin, setVerifyLogin] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const storedData = secureLocalStorage.getItem("kycData");
  const storedData1 = secureLocalStorage.getItem("blockchainData");
  const storedData2 = secureLocalStorage.getItem("ownershipData");
  const storedData3 = secureLocalStorage.getItem("tempData");
  const storedData4 = secureLocalStorage.getItem("couponData");
  const [message, setMessage] = useState("");
  const [email2, setEmail2] = useState(null);
  const [status, setStatus] = useState(null);
  const navigateTimeout = useRef(null);
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (navigateTimeout.current) clearTimeout(navigateTimeout.current);
    navigateToDashboard(email2, status);
  };

  useEffect(() => {
    if (loader) {
      document.body.style.cursor = "not-allowed";
    } else {
      document.body.style.cursor = "auto";
    }

    return () => {
      document.body.style.cursor = "auto";
    };
  }, [loader]);

  useEffect(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 3000);
      navigateTimeout.current = setTimeout(handleNavigate, 3000);
    }
    return () => {
      if (navigateTimeout.current) clearTimeout(navigateTimeout.current);
    };
  }, [message]);

  if (pan) {
    navigate("/");
  }

  function getDeviceInfo() {
    const deviceInfo = {
      platform: navigator.platform,
      userAgent: navigator.userAgent,
      browser: navigator.appName,
      browserVersion: navigator.appVersion,
      language: navigator.language,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
    };
    return deviceInfo;
  }

  const handleLogin = (e) => {
    secureLocalStorage.removeItem("kycData");
    secureLocalStorage.removeItem("chatHistory");
    secureLocalStorage.removeItem("blockchainData");
    secureLocalStorage.removeItem("ownershipData");
    secureLocalStorage.removeItem("tempData");
    secureLocalStorage.removeItem("couponData");
    secureLocalStorage.removeItem("access_token_time");
    setLoader(true);
    e.preventDefault();
    const deviceInfo = getDeviceInfo();
    fetch(`https://api-scdndestates.com/api/login_check/`, {
      method: "POST",
      body: JSON.stringify({
        username: username,
        password: pass,
        device_info: deviceInfo,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to login");
        }
        return response.json();
      })
      .then((response) => {
        if (response.data === "Dual login detected") {
          setLoader(false);
          setErrorMessage(true);
        } else {
          if (response.data === "user not registered") {
            setVerifyLogin(1);
            setLoader(false);
          } else {
            if (response.data === "wrong password, try again") {
              setVerifyLogin(2);
              setErrorMessage(false);
              setLoader(false);
            } else if (response.data === "wrong password, can't again") {
              setMessage(response.data);
              setVerifyLogin(0);
              setErrorMessage(false);
              setLoader(false);
            } else {
              setTokens(
                response.data.access_token,
                response.data.refresh_token
              );
              const cookies = document.cookie.split("; ");
              setVerifyLogin(0);
              if (
                storedData === null ||
                storedData1 === null ||
                storedData2 === null ||
                storedData3 === null ||
                storedData4 === null
              ) {
                secureLocalStorage.setItem(
                  "kycData",
                  JSON.stringify(response.data5)
                );
                secureLocalStorage.setItem(
                  "blockchainData",
                  JSON.stringify(response.data1)
                );
                secureLocalStorage.setItem(
                  "ownershipData",
                  JSON.stringify(response.data2)
                );
                secureLocalStorage.setItem(
                  "tempData",
                  JSON.stringify(response.data3)
                );
                secureLocalStorage.setItem(
                  "couponData",
                  JSON.stringify(response.data4)
                );
              }
              setMessage(response.data.message);
              setEmail2(response.email);
              setStatus(response.data.status);
            }
          }
        }
      })
      .catch((error) => {
        // console.error("Error: ", error);
      });
  };

  const handleGoogleLoginSuccess = (response) => {
    secureLocalStorage.removeItem("kycData");
    secureLocalStorage.removeItem("chatHistory");
    secureLocalStorage.removeItem("blockchainData");
    secureLocalStorage.removeItem("ownershipData");
    secureLocalStorage.removeItem("tempData");
    secureLocalStorage.removeItem("couponData");
    secureLocalStorage.removeItem("access_token_time");
    setLoader(true);
    const deviceInfo = getDeviceInfo();
    // Send response token to the backend for verification
    fetch("https://api-scdndestates.com/google/callback/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: response.credential,
        device_info: deviceInfo,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((response) => {
        if (response.data === "Dual login detected") {
          setLoader(false);
          setErrorMessage(true);
        } else {
          if (response.data === "user not registered") {
            setVerifyLogin(1);
            setLoader(false);
            setUsername(response.name);
            navigate("/register", {
              state: {
                email: response.email,
                name: response.name,
                alert:
                  "Gmail ID provided does not match our records. Please register.",
              },
            });
          } else {
            if (response.data === "wrong password, try again") {
              setVerifyLogin(2);
              setErrorMessage(false);
              setLoader(false);
            } else if (response.data === "wrong password, can't again") {
              setMessage(response.data);
              setVerifyLogin(0);
              setErrorMessage(false);
              setLoader(false);
            } else {
              setTokens(
                response.data.access_token,
                response.data.refresh_token
              );
              const cookies = document.cookie.split("; ");
              setVerifyLogin(0);
              if (
                storedData === null ||
                storedData1 === null ||
                storedData2 === null ||
                storedData3 === null ||
                storedData4 === null
              ) {
                secureLocalStorage.setItem(
                  "kycData",
                  JSON.stringify(response.data5)
                );
                secureLocalStorage.setItem(
                  "blockchainData",
                  JSON.stringify(response.data1)
                );
                secureLocalStorage.setItem(
                  "ownershipData",
                  JSON.stringify(response.data2)
                );
                secureLocalStorage.setItem(
                  "tempData",
                  JSON.stringify(response.data3)
                );
                secureLocalStorage.setItem(
                  "couponData",
                  JSON.stringify(response.data4)
                );
              }
              setMessage(response.data.message);
              setEmail2(response.email);
              setStatus(response.data.status);
            }
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleGoogleLoginFailure = (error) => {
    console.error("Google SignIn Failure", error);
  };

  const handleLoginHere = () => {
    setLoader(true);
    secureLocalStorage.removeItem("kycData");
    secureLocalStorage.removeItem("chatHistory");
    secureLocalStorage.removeItem("blockchainData");
    secureLocalStorage.removeItem("ownershipData");
    secureLocalStorage.removeItem("tempData");
    secureLocalStorage.removeItem("couponData");
    secureLocalStorage.removeItem("access_token_time");
    const deviceInfo = getDeviceInfo();
    fetch(`https://api-scdndestates.com/api/login/`, {
      method: "POST",
      body: JSON.stringify({
        username: username,
        password: pass,
        device_info: deviceInfo,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to login");
        }
        return response.json();
      })
      .then((response) => {
        if (response.data === "user not registered") {
          setVerifyLogin(1);
          setLoader(false);
        } else {
          if (response.data === "wrong password, try again") {
            setVerifyLogin(2);
            setErrorMessage(false);
            setLoader(false);
          } else if (response.data === "wrong password, can't again") {
            setMessage(response.data);
            setVerifyLogin(0);
            setErrorMessage(false);
            setLoader(false);
          } else {
            setTokens(response.data.access_token, response.data.refresh_token);
            const cookies = document.cookie.split("; ");
            setVerifyLogin(0);
            if (
              storedData === null ||
              storedData1 === null ||
              storedData2 === null ||
              storedData3 === null ||
              storedData4 === null
            ) {
              secureLocalStorage.setItem(
                "kycData",
                JSON.stringify(response.data5)
              );
              secureLocalStorage.setItem(
                "blockchainData",
                JSON.stringify(response.data1)
              );
              secureLocalStorage.setItem(
                "ownershipData",
                JSON.stringify(response.data2)
              );
              secureLocalStorage.setItem(
                "tempData",
                JSON.stringify(response.data3)
              );
              secureLocalStorage.setItem(
                "couponData",
                JSON.stringify(response.data4)
              );
            }
            setMessage(response.data.message);
            setEmail2(response.email);
            setStatus(response.data.status);
          }
        }
      })
      .catch((error) => {
        // console.error("Error: ", error);
      });
  };

  const setTokens = (accessToken, refreshToken) => {
    const access_token_expires = new Date();
    const refresh_token_expires = new Date();

    // Set access token to expire after 1 minute
    access_token_expires.setTime(
      access_token_expires.getTime() + 2 * 60 * 1000
    );

    // Set refresh token to expire after 12 hours
    refresh_token_expires.setTime(
      refresh_token_expires.getTime() + 12 * 60 * 60 * 1000
    );

    document.cookie = `access_token = ${accessToken};expires=${access_token_expires.toUTCString()};path=/`;
    document.cookie = `refresh_token = ${refreshToken};expires=${refresh_token_expires.toUTCString()};path=/`;
    secureLocalStorage.setItem("access_token_time", access_token_expires);
  };

  const navigateToDashboard = (email, status) => {
    const isAdmin = AdminInfoApp.some((admin) => admin.email === email);
    const isEmployee = EmployeeInfoApp.some((admin) => admin.email === email);
    const isAgent = AgentInfoApp.some((admin) => admin.email === email);

    setTimeout(() => {
      window.location.reload();
    }, 500);
    if (isAdmin || isEmployee) {
      navigate("/adminDashboard");
    } else if (isAgent) {
      navigate("/agentDashboard");
    } else {
      if (status === 0 || status === null || status === undefined) {
        setKycPop(true);
        setLoader(false);
      } else {
        window.history.back();
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Login to your account | SCDND Estates</title>
        <meta
          name="description"
          content="Login to your account securely, authenticate yourself using password to access all the pages and personalized rsources of the website."
        />
        <meta
          name="description"
          content="Login to your personalized dashboard securely, authenticate yourself using Email or Phone plus password to access all the functionalities of the portal and perform transactions."
        />
        <link rel="canonical" href="https://scdndestates.com/login" />
        {/* Open Graph */}
        <meta
          property="og:title"
          content="Login to your account - SCDND Estates"
        />
        <meta
          property="og:description"
          content="Login to your account securely, authenticate yourself using password to access all the pages and personalized rsources of the website."
        />

        <meta property="og:url" content="https://scdndestates.com/login" />
        <meta property="og:type" content="website" />
        {/* twitter Graph */}
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="Login to your account - SCDND Estates"
        />
        <meta
          name="twitter:description"
          content="Login to your account securely, authenticate yourself using password to access all the pages and personalized rsources of the website."
        />
        <meta name="twitter:domain" content="scdndestates.com" />
      </Helmet>
      {loader && <Loader2 />}
      {kycPop && pan !== "AAXCS0834Q" && <KycPopUp />}
      {errorMessage && (
        <>
          <div className="caveman-body">
            <div className="rounded-2xl relative bg-[#FFF] max-h-[630px] mx-auto px-5 pop-up-animation max-md:scale-[60%] md:scale-75 ">
              <h4 className=" mt-4 font-bold text-3xl  text-[#5ab2ff] text-center">
                Dual Login Alert!
              </h4>
              <div className="text-caveman ">
                <p>403</p>
              </div>
              <div className="container-caveman ">
                <div className="caveman">
                  <div className="leg">
                    <div className="foot">
                      <div className="fingers"></div>
                    </div>
                  </div>
                  <div className="leg">
                    <div className="foot">
                      <div className="fingers"></div>
                    </div>
                  </div>
                  <div className="shape">
                    <div className="circle"></div>
                    <div className="circle"></div>
                  </div>
                  <div className="head">
                    <div className="eye">
                      <div className="nose"></div>
                    </div>
                    <div className="mouth"></div>
                  </div>
                  <div className="arm-right">
                    <div className="club"></div>
                  </div>
                </div>
                <div className="caveman">
                  <div className="leg">
                    <div className="foot">
                      <div className="fingers"></div>
                    </div>
                  </div>
                  <div className="leg">
                    <div className="foot">
                      <div className="fingers"></div>
                    </div>
                  </div>
                  <div className="shape">
                    <div className="circle"></div>
                    <div className="circle"></div>
                  </div>
                  <div className="head">
                    <div className="eye">
                      <div className="nose"></div>
                    </div>
                    <div className="mouth"></div>
                  </div>
                  <div className="arm-right">
                    <div className="club"></div>
                  </div>
                </div>
              </div>
              <div id="link">
                <p className="text-[#212121] w-full  text-lg md:text-xl font-semibold text-center px-14">
                  Your session is currently active in another
                  window/browser/system. Click on 'Login Here' to continue and
                  end the previous one.
                </p>
                <div className="mt-3 flex justify-center items-center gap-3">
                  <button
                    type="button"
                    className="cursor-pointer flex  w-52 h-8 md:h-12 items-center  justify-center rounded-md border-2 hover:shadow-[inset_0px_0px_2px_0px_#5ab2ff] border-[#5ab2ff] px-2 max-md:py-6 md:py-1 text-lg  font-semibold leading-6 duration-300 text-[#212121] shadow-sm hover:bg-[#ddedfd]"
                    data-mdb-ripple-init
                    data-mdb-ripple-color="dark"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Go Back
                  </button>
                  <button
                    type="submit"
                    className="cursor-pointer flex w-52  h-8 md:h-12  items-center justify-center rounded-md border-2 border-[#5ab2ff] bg-[#5ab2ff] px-2  max-md:py-6 md:py-1 text-lg font-semibold leading-6 text-white shadow-sm hover:bg-[#3aa0ff]"
                    onClick={() => {
                      handleLoginHere(); // Call the handleLoginHere function
                      // setErrorMessage(true);
                    }}
                  >
                    Login here
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="container mt-10">
        <div className="bg-fixed flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="w-full mx-auto">
            <a href="/">
              <img
                className="mx-auto h-16 w-auto"
                src="/images/logo01.webp"
                alt="Your Company"
              />
            </a>
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-[#212121]">
              Sign in to your account
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" action="#" method="POST">
              <div>
                {verifyLogin === 1 && (
                  <>
                    <p className="text-danger mb-2 mt-0 font-size1 text-center">
                      User is not Registered.
                    </p>
                  </>
                )}
                <label
                  htmlFor="email"
                  className="block text-sm font-semibold  leading-4 text-[#212121]"
                >
                  Enter your Username
                </label>
                <div className="mt-2">
                  <input
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email/Phone"
                    autoComplete="email"
                    className={`px-2 block w-full rounded-md  py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-[#DBEDF9]  focus:ring-inset  sm:text-sm sm:leading-6 ${
                      verifyLogin === 1
                        ? "border-danger border-2"
                        : "border-dark"
                    }`}
                    required
                  />
                </div>
              </div>

              <div>
                {verifyLogin === 2 && (
                  <>
                    <p className="text-danger my-2 font-size1 text-center">
                      Password is incorrect. Try again!
                    </p>
                  </>
                )}
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-semibold  leading-4 text-[#212121] "
                  >
                    Enter your Password
                  </label>

                  <div className="text-sm">
                    <a
                      href="/forgetPassword"
                      className="font-semibold text-[#5ab2ff] hover:text-[#3aa0ff]"
                    >
                      Forgot password?
                    </a>
                  </div>
                </div>

                <div className="mt-2">
                  <div
                    className={`flex ${
                      verifyLogin === 2
                        ? "border-danger border-2 rounded-md"
                        : "border-dark"
                    }`}
                  >
                    <input
                      value={pass}
                      onChange={(e) => {
                        setPass(e.target.value);
                      }}
                      type={`${showPassword === false ? "password" : "text"}`}
                      id="password"
                      name="password"
                      placeholder="Password"
                      autoComplete="current-password"
                      required
                      className="px-2 block w-full rounded-md border-0 py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#DBEDF9] sm:text-sm sm:leading-6"
                    />
                    <div
                      className="px-2 flex align-items-center "
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword === false ? (
                        <HiMiniEyeSlash size={22} />
                      ) : (
                        <IoEyeSharp size={22} />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  onClick={handleLogin}
                  className="flex w-full justify-center rounded-md bg-[#5ab2ff] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#3aa0ff] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign in
                </button>
              </div>
            </form>

            <p className="mt-10 text-center text-sm text-gray-500">
              Don't have an account?{" "}
              <a
                href="/register"
                className="font-semibold leading-6 text-[#5ab2ff] hover:text-[#3aa0ff]"
              >
                Register Here
              </a>
            </p>
            <p className="mt-1  text-center text-sm font-semibold text-gray-500 flex-column">
              Or Sign-up with:{" "}
              <div className="signup-icons mt-2 flex justify-center">
                <GoogleOAuthProvider clientId="666963340992-cigf9d6on3gtajkrdg8qoj1i9stkv3i0.apps.googleusercontent.com">
                  <div className="">
                    <GoogleLogin
                      onSuccess={handleGoogleLoginSuccess}
                      onError={handleGoogleLoginFailure}
                      scope="https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/user.phonenumbers.read"
                      size="large"
                      width="300px"
                    />
                  </div>
                </GoogleOAuthProvider>
              </div>
            </p>
          </div>
        </div>
      </div>
      {message === "successfully logged in" ? (
        <AlertGreen
          message="Successfully logged in."
          onClose={() => {
            setMessage("");
            handleNavigate();
          }}
        />
      ) : (
        message === "wrong password, can't again" && (
          <AlertRed
            message="User has been LOCKED OUT due to repeated login attempts with wrong credentials. Please recover your account by Email & Mobile OTP."
            onClose={() => {
              setMessage("");
            }}
          />
        )
      )}
    </>
  );
}

export default Login;
