const AgentInfoApp = [
  {
    id: 0,
    AgentName: "Rajarshi Das",
    AgentCode: 1,
    designation: "Agent",
    Address: "Sweet Kunj, Chocolate Vihar, Uttar Pradesh-201890",
    email: "contactrajarshi@gmail.com",
    mobile: "6299716789",
  },
  {
    id: 1,
    AgentName: "Ritoja Das",
    AgentCode: 2,
    designation: "Agent",
    Address: "Sweet Kunj, Chocolate Vihar, Uttar Pradesh-201890",
    email: "ritoja.das96@gmail.com",
    mobile: "6299716789",
  },
  {
    id: 3,
    AgentName: "Aditya Malhotra",
    AgentCode: 3,
    Address: "Sweet Kunj, Chocolate Vihar, Uttar Pradesh-201890",
    email: "aditya02@gmail.com",
    mobile: "6299716789",
  },
  {
    id: 4,
    AgentName: "Anjali Shinde",
    AgentCode: 4,
    Address: "Barfi Nagar, Uttar Pradesh-201890",
    email: "brucoffee02@gmail.com",
    mobile: "6299712451",
  },
  {
    id: 5,
    AgentName: "Richa Chadda",
    AgentCode: 5,
    Address: "Barfi Nagar, Uttar Pradesh-201890",
    email: "brucoffee02@gmail.com",
    mobile: "6299657686",
  },
  {
    id: 6,
    AgentName: "Richa",
    AgentCode: 6,
    Address: "Barfi Nagar, Uttar Pradesh-201890",
    email: "brucoffee02@gmail.com",
    mobile: "6299657686",
  },
];
export default AgentInfoApp;
