import React, { useState, useEffect, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./EventScheduler.css";
import { MdSkipNext, MdSkipPrevious } from "react-icons/md";
import Loader from "../components/Loaders/Loader1.jsx";
import CheckAuth from "../components/ServiceFunctions/CheckAuth.jsx";
import { MdSecurityUpdateGood } from "react-icons/md";
import AlertYellow from "../components/Alerts/AlertYellow.jsx";
import AlertRed from "../components/Alerts/AlertRed.jsx";
import AlertGreen from "../components/Alerts/AlertGreen.jsx";
import secureLocalStorage from "react-secure-storage";
import TaskSpecks from "./TaskSpecks.jsx";
import DependencyPopup from "../Admin/Dependency.jsx";

function EventScheduler({
  adminCalender,
  allEvents,
  onReload,
  emp,
  st,
  filter,
  searchValue,
  onBack,
}) {
  const { email, phone } = CheckAuth();
  const timerRef = useRef(null);
  const filterRef = useRef(null);
  const resultRef = useRef(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [events, setEvents] = useState([]);
  const [dependentEvents, setDependentEvents] = useState([]);
  const [dependency, setDependency] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [loader4, setLoader4] = useState(true);
  const [allStatus, setAllStatus] = useState([]);
  const [allTitle, setAllTitle] = useState([]);
  const [allPriority, setAllPriority] = useState([]);
  const [allRating, setAllRating] = useState([]);
  const [rating, setRating] = useState("All");
  const [status, setStatus] = useState(st ? st : "All");
  const [priority, setPriority] = useState("All");
  const [duration, setDuration] = useState("All");
  const [showPopup, setShowPopup] = useState(false);
  const [showModal, setShowModal] = useState(-1);
  const [isFilterOpen1, setIsFilterOpen1] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState([]);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [timer, setTimer] = useState(300);
  const [userOtp, setUserOtp] = useState();
  const [otp, setOtp] = useState("");
  const [maxLength, setMaxLength] = useState(120);
  const [maxLengthTitle, setMaxLengthTitle] = useState(120);
  const [dependencyOpen, setDependencyOpen] = useState(null);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [showAllFiles, setShowAllFiles] = useState(false);
  const allDuration = ["Passed", "Today", "Next 2 days", "In 1 week", "Later"];

  const calculateDeadlineForEach = (
    selectedEvent,
    adminCalender,
    enteredDate
  ) => {
    let finalDeadline = "";
    const holidays = new Set();
    const leaves = new Set();
    const overtime = new Set();
    Object.values(adminCalender || {}).forEach((data) => {
      if (data.National_holidays) {
        Object.keys(data.National_holidays).forEach((val) => {
          holidays.add(val);
        });
      }
      if (data.Leave_data) {
        Object.entries(data.Leave_data).forEach(([key, val]) => {
          const assignee = selectedEvent?.assignee
            ?.replaceAll("_", " ")
            .toLowerCase();
          Object.keys(val).forEach((person) => {
            if (person?.replaceAll("_", " ").toLowerCase() === assignee) {
              if (val[person] === "Sick" || val[person] === "Privilege") {
                leaves.add(key);
              } else if (val[person] === "Overtime") {
                overtime.add(key);
              }
            }
          });
        });
      }
    });

    function convertintoHours(duration) {
      if (!duration) return 0;
      if (duration.includes("hour")) {
        return parseFloat(duration);
      } else if (duration.includes("day")) {
        return parseFloat(duration) * 8;
      } else if (duration.includes("week")) {
        return parseFloat(duration) * 5 * 8;
      }

      return 0; // Default case for unexpected inputs
    }

    function getWorkingHoursBetween(start, end) {
      let startTime = new Date(start);
      let endTime = new Date(end);

      let workStartHour = 10.5; // 10:30 AM
      let workEndHour = 18.5; // 6:30 PM
      let workHoursPerDay = workEndHour - workStartHour; // 8 hours

      let totalHours = 0;

      if (startTime.toDateString() === endTime.toDateString()) {
        let startHour = startTime.getHours() + startTime.getMinutes() / 60;
        let endHour = endTime.getHours() + endTime.getMinutes() / 60;
        startHour = Math.max(startHour, workStartHour);
        endHour = Math.min(endHour, workEndHour);
        totalHours += Math.max(0, endHour - startHour);
      } else {
        let startHour = startTime.getHours() + startTime.getMinutes() / 60;
        startHour = Math.max(startHour, workStartHour);
        totalHours += Math.max(0, workEndHour - startHour);

        let endHour = endTime.getHours() + endTime.getMinutes() / 60;
        endHour = Math.min(endHour, workEndHour);
        totalHours += Math.max(0, endHour - workStartHour);

        let current = new Date(startTime);
        current.setDate(current.getDate() + 1);

        while (current < endTime) {
          totalHours += workHoursPerDay;
          current.setDate(current.getDate() + 1);
        }
      }
      return totalHours;
    }

    const addWorkingHours = (currentDate, remainingHours) => {
      const endWorkHour = 18.5; // 18.5 means 18:30 (6:30 PM)
      const startWorkHour = 10.5; // 10.5 means 10:30 AM

      while (remainingHours > 0) {
        const currentDateFormatted = currentDate
          ?.toLocaleString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })
          .replaceAll(",", "");

        let isHolidayOrWeekend = false;
        let currentDay = currentDate?.getDay();
        let currentHour =
          currentDate?.getHours() + currentDate?.getMinutes() / 60;

        // Check weekends
        if (currentDay === 0 || currentDay === 6) {
          isHolidayOrWeekend = true;
        }

        // Check holidays
        if (holidays.has(currentDateFormatted)) {
          isHolidayOrWeekend = true;
        }

        // If it's a holiday or weekend and not overtime, skip this day
        if (!overtime.has(currentDateFormatted) && isHolidayOrWeekend) {
          currentDate.setDate(currentDate.getDate() + 1);
          currentDate.setHours(10, 30, 0, 0); // Reset to 10:30 AM
          continue;
        }

        // If current time is before work hours, reset to 10:30 AM
        if (currentHour < startWorkHour) {
          currentDate.setHours(10, 30, 0, 0);
          currentHour = startWorkHour;
        }

        // Calculate available work hours today
        const remainingToday = Math.max(0, endWorkHour - currentHour);

        // If we can finish today, add remainingHours and return
        if (remainingHours <= remainingToday) {
          currentDate.setTime(
            currentDate.getTime() + remainingHours * 60 * 60 * 1000
          );
          return formatDate(currentDate);
        }

        // Otherwise, use up all today's hours and move to next working day
        remainingHours -= remainingToday;
        currentDate.setDate(currentDate.getDate() + 1);
        currentDate.setHours(10, 30, 0, 0); // Move to next day at 10:30 AM
      }

      return formatDate(currentDate);
    };

    function formatDate(date) {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, "0"); // Ensuring two digits for minutes

      return `${day}-${month}-${year}, ${hours}:${minutes}`;
    }

    if (selectedEvent?.Date && selectedEvent?.Duration) {
      let eventDate = selectedEvent?.Date
        ? new Date(selectedEvent?.Date)
        : enteredDate
        ? new Date(enteredDate)
        : null;
      let currentDate = new Date(eventDate);
      let remainingHours = selectedEvent?.Duration
        ? convertintoHours(selectedEvent?.Duration)
        : null;
      const interruptedDate = selectedEvent?.interrupted
        ? new Date(selectedEvent?.interrupted)
        : null;
      const resumptionDate = selectedEvent?.resumption
        ? new Date(selectedEvent?.resumption)
        : enteredDate
        ? new Date(enteredDate)
        : null;

      if (!interruptedDate || !resumptionDate) {
        finalDeadline = addWorkingHours(currentDate, remainingHours);
      } else {
        let hoursBeforeInterruption = getWorkingHoursBetween(
          eventDate,
          interruptedDate
        );
        let hoursAfterResumption = remainingHours - hoursBeforeInterruption;

        currentDate = resumptionDate;

        finalDeadline = addWorkingHours(currentDate, hoursAfterResumption);
      }
    }
    return finalDeadline;
  };

  // Open Search
  const handleGetResult = () => {
    const fetchResults = async () => {
      try {
        const response = await fetch(
          "https://api-scdndestates.com/api/admin_search_function/",
          {
            method: "POST", // Use POST method
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ query }), // Send the query in the body
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setResults(data);
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    };
    const delayDebounceFn = setTimeout(() => {
      fetchResults();
    }, 300); // debounce by 300ms

    return () => clearTimeout(delayDebounceFn);
  };

  const handleMultiSearch = (query) => {
    if (query.length > 3) {
      const fetchResults = async () => {
        try {
          const response = await fetch(
            "https://api-scdndestates.com/api/multi_search_function/",
            {
              method: "POST", // Use POST method
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ query }), // Send the query in the body
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const data = await response.json();
          let titles = [];
          data.map((val, index) => {
            titles.push({
              title: val._source.title,
              Date: val._source.Date.replaceAll("-", "_"),
            });
          });
          if (titles.length > 0) {
            setAllTitle(titles);
            setRating("All");
            setPriority("All");
            setStatus("All");
          }
        } catch (error) {
          console.error("Error fetching search results:", error);
        }
      };
      const delayDebounceFn = setTimeout(() => {
        fetchResults();
      }, 300); // debounce by 300ms

      return () => clearTimeout(delayDebounceFn);
    } else {
      setResults([]);
    }
  };

  const handleDownload = (link) => {
    fetch(`https://api-scdndestates.com/api/download-s3-files/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        link,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to download file");
        }
        return response.json();
      })
      .then((response) => {
        if (response.message === "success") {
          const link = document.createElement("a");
          link.href = response.file_url;
          link.setAttribute("download", "");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          alert(response.error);
        }
      })
      .catch((error) => {
        // console.error("Error during logout:", error);
      });
  };

  // useEffect for Description
  useEffect(() => {
    const updateMaxLength = () => {
      if (window.innerWidth < 640) {
        // Small (sm)
        setMaxLength(60);
      } else if (window.innerWidth < 1024) {
        // Large (lg)
        setMaxLength(60);
      } else if (window.innerWidth < 1280) {
        // Large (lg)
        setMaxLength(60);
      } else if (window.innerWidth < 1600) {
        // Large (lg)
        setMaxLength(110);
      } else {
        // Extra Large (xl)
        setMaxLength(130);
      }
    };

    updateMaxLength(); // Set initial value
    window.addEventListener("resize", updateMaxLength);
    return () => window.removeEventListener("resize", updateMaxLength);
  }, []);

  // Use Effect for title
  useEffect(() => {
    const updateMaxLengthTitle = () => {
      if (window.innerWidth < 640) {
        // Small (sm)
        setMaxLengthTitle(50);
      } else if (window.innerWidth < 1024) {
        // Large (lg)
        setMaxLengthTitle(40);
      } else if (window.innerWidth < 1280) {
        // Large (lg)
        setMaxLengthTitle(50);
      } else if (window.innerWidth < 1600) {
        // Large (lg)
        setMaxLengthTitle(120);
      } else {
        // Extra Large (xl)
        setMaxLengthTitle(160);
      }
    };

    updateMaxLengthTitle(); // Set initial value
    window.addEventListener("resize", updateMaxLengthTitle);

    return () => window.removeEventListener("resize", updateMaxLengthTitle);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setIsFilterOpen(false);
      }
    };

    // Attach the event listener
    document.addEventListener("click", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (resultRef.current && !resultRef.current.contains(event.target)) {
        setShow2(false);
      }
    };

    // Attach the event listener
    document.addEventListener("click", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleFilter = () => setIsFilterOpen(!isFilterOpen);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
    return formattedTime;
  };

  const handleTimer = () => {
    timerRef.current = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(timerRef.current);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    setLoader4(true);
    if (query.length <= 0) {
      setAllTitle([]);
    }
    if (allTitle.length <= 0) {
      setLoader(true);
      const depend = [];
      const eventsOnDate = allEvents
        .map((event) => {
          const dependentEvents2 = [];
          Object.entries(event?.Dependency || {}).forEach(([date, title]) => {
            if (typeof title !== "string") {
              console.warn("Skipping invalid title:", title); // Debugging log
              return; // Skip this iteration if title is not a string
            }

            depend.push({ title: title, Date: date });

            allEvents.find((ev) => {
              if (
                typeof ev.title === "string" &&
                ev.title.toLowerCase() === title.toLowerCase()
              ) {
                dependentEvents2.push({
                  date: date,
                  title: title,
                  status: ev?.Status || "Unknown",
                  assignee: ev?.Assignee || "Not Assigned",
                  rating: ev?.Rating || "Unknown",
                  dep_deadline: calculateDeadlineForEach(
                    ev,
                    adminCalender,
                    null
                  ),
                });
              }
            });

            dependentEvents.find((ev) => {
              if (
                typeof ev.title === "string" &&
                ev.title.toLowerCase() === title.toLowerCase() &&
                ev.Assignee !== emp
              ) {
                dependentEvents2.push({
                  date: date,
                  title: title,
                  status: ev?.Status || "Unknown",
                  assignee: ev?.Assignee || "Not Assigned",
                  rating: ev?.Rating || "Unknown",
                  dep_deadline: calculateDeadlineForEach(
                    ev,
                    adminCalender,
                    null
                  ),
                });
              }
            });
          });

          return {
            event_date: event.Date,
            title: event.title,
            assignee: event.Assignee,
            desc: event.Description,
            blocker: event.Blocker,
            rating: event.Rating,
            priority: event.Priority,
            status: event.Status,
            budget: event.Budget,
            duration: event.Duration,
            deadline: calculateDeadlineForEach(event, adminCalender, null),
            completion: event.Completion,
            dependency: dependentEvents2,
            validators: event.file_validator,
            interrupted: event.Interrupted,
            resumption: event.Resumption,
            wtdfiles: event.WTD_files,
            assigneefiles: event.Assignee_files,
          };
        })
        .sort((a, b) => new Date(a.event_date) - new Date(b.event_date));
      setDependency(depend);
      setAllRating([...new Set(eventsOnDate.map((event) => event.rating))]);
      setAllStatus([...new Set(eventsOnDate.map((event) => event.status))]);
      setAllPriority([...new Set(eventsOnDate.map((event) => event.priority))]);

      const filteredEvents = eventsOnDate.filter((event) => {
        const ratingMatch = rating === "All" || event.rating === rating;
        const assigneeMatch = event.assignee === emp;
        const statusMatch = status === "All" || event.status === status;
        const priorityMatch = priority === "All" || event.priority === priority;

        let durationMatch = false;
        const now = new Date();
        const deadline = new Date(event.deadline);
        if (duration === "Passed") {
          durationMatch = deadline < now;
        } else if (duration === "Today") {
          const startOfDay = new Date(now.setHours(0, 0, 0, 0));
          const endOfDay = new Date(now.setHours(23, 59, 59, 999));
          durationMatch = deadline >= startOfDay && deadline <= endOfDay;
        } else if (duration === "Next 2 days") {
          const startOfDay = new Date(now.setHours(0, 0, 0, 0));
          const endOfDay = new Date(now.setHours(23, 59, 59, 999));
          const twoDaysFromNow = new Date(startOfDay);
          twoDaysFromNow.setDate(twoDaysFromNow.getDate() + 2);
          durationMatch = deadline > startOfDay && deadline <= twoDaysFromNow;
        } else if (duration === "In 1 week") {
          const startOfDay = new Date(now.setHours(0, 0, 0, 0));
          const oneWeekFromNow = new Date(startOfDay);
          oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);
          durationMatch = deadline > startOfDay && deadline <= oneWeekFromNow;
        } else if (duration === "Later") {
          durationMatch = deadline > now;
        } else {
          durationMatch = duration === "All";
        }
        return (
          ratingMatch &&
          assigneeMatch &&
          statusMatch &&
          priorityMatch &&
          durationMatch
        );
      });
      setEvents(filteredEvents);
      setLoader(false);
    }
    setLoader4(false);
  }, [
    JSON.stringify(allEvents),
    JSON.stringify(allTitle),
    query,
    JSON.stringify(dependentEvents),
    rating,
    status,
    priority,
    duration,
  ]);

  useEffect(() => {
    setLoader4(true);
    fetch(`https://api-scdndestates.com/api/get-filtered-event-and-reminders/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ dependency }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to save event/reminder");
        }
        return response.json();
      })
      .then((response) => {
        setDependentEvents(
          response.events.map((event) => ({
            ...event,
            deadline: calculateDeadlineForEach(event, adminCalender, null),
          }))
        );
      })
      .catch((error) => {
        // console.error("Error during logout:", error);
      });
  }, [JSON.stringify(dependency)]);

  useEffect(() => {
    setLoader4(true);
    if (allTitle.length > 0) {
      fetch(`https://api-scdndestates.com/api/get-filtered-event-and-reminders/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ allTitle }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to save event/reminder");
          }
          return response.json();
        })
        .then((response) => {
          setLoader(true);
          const depend = [];
          const eventsOnDate = response.events
            .map((event) => {
              const dependentEvents2 = [];

              Object.entries(event?.Dependency || {}).forEach(
                ([date, title]) => {
                  if (typeof title !== "string") {
                    console.warn("Skipping invalid title:", title); // Debugging log
                    return; // Skip this iteration if title is not a string
                  }

                  depend.push({ title: title, Date: date });

                  allEvents.find((ev) => {
                    if (
                      typeof ev.title === "string" &&
                      ev.title.toLowerCase() === title.toLowerCase()
                    ) {
                      dependentEvents2.push({
                        date: date,
                        title: title,
                        status: ev?.Status || "Unknown",
                        assignee: ev?.Assignee || "Not Assigned",
                        rating: ev?.Rating || "Unknown",
                        dep_deadline: calculateDeadlineForEach(
                          ev,
                          adminCalender,
                          null
                        ),
                      });
                    }
                  });

                  dependentEvents.find((ev) => {
                    if (
                      typeof ev.title === "string" &&
                      ev.title.toLowerCase() === title.toLowerCase() &&
                      ev.Assignee !== emp
                    ) {
                      dependentEvents2.push({
                        date: date,
                        title: title,
                        status: ev?.Status || "Unknown",
                        assignee: ev?.Assignee || "Not Assigned",
                        rating: ev?.Rating || "Unknown",
                        dep_deadline: calculateDeadlineForEach(
                          ev,
                          adminCalender,
                          null
                        ),
                      });
                    }
                  });
                }
              );

              return {
                event_date: event.Date,
                title: event.title,
                assignee: event.Assignee,
                desc: event.Description,
                blocker: event.Blocker,
                rating: event.Rating,
                priority: event.Priority,
                status: event.Status,
                budget: event.Budget,
                duration: event.Duration,
                dependency: dependentEvents2,
                deadline: calculateDeadlineForEach(event, adminCalender, null),
                completion: event.Completion,
                validators: event.file_validator,
                interrupted: event.Interrupted,
                resumption: event.Resumption,
                wtdfiles: event.WTD_files,
                assigneefiles: event.Assignee_files,
              };
            })
            .sort((a, b) => new Date(a.event_date) - new Date(b.event_date));
          setDependency(depend);
          setAllRating([...new Set(eventsOnDate.map((event) => event.rating))]);
          setAllStatus([...new Set(eventsOnDate.map((event) => event.status))]);
          setAllPriority([
            ...new Set(eventsOnDate.map((event) => event.priority)),
          ]);
          const filteredEvents = eventsOnDate.filter((event) => {
            const ratingMatch = rating === "All" || event.rating === rating;
            const statusMatch = status === "All" || event.status === status;
            const priorityMatch =
              priority === "All" || event.priority === priority;

            let durationMatch = false;
            const now = new Date();
            const deadline = new Date(event.deadline);
            if (duration === "Passed") {
              durationMatch = deadline < now;
            } else if (duration === "Today") {
              const startOfDay = new Date(now.setHours(0, 0, 0, 0));
              const endOfDay = new Date(now.setHours(23, 59, 59, 999));
              durationMatch = deadline >= startOfDay && deadline <= endOfDay;
            } else if (duration === "Next 2 days") {
              const startOfDay = new Date(now.setHours(0, 0, 0, 0));
              const endOfDay = new Date(now.setHours(23, 59, 59, 999));
              const twoDaysFromNow = new Date(startOfDay);
              twoDaysFromNow.setDate(twoDaysFromNow.getDate() + 2);
              durationMatch =
                deadline > startOfDay && deadline <= twoDaysFromNow;
            } else if (duration === "In 1 week") {
              const startOfDay = new Date(now.setHours(0, 0, 0, 0));
              const oneWeekFromNow = new Date(startOfDay);
              oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);
              durationMatch =
                deadline > startOfDay && deadline <= oneWeekFromNow;
            } else if (duration === "Later") {
              durationMatch = deadline > now;
            } else {
              durationMatch = duration === "All";
            }
            return ratingMatch && statusMatch && priorityMatch && durationMatch;
          });
          setEvents(filteredEvents);
          setLoader(false);
        })
        .catch((error) => {
          // console.error("Error during logout:", error);
        });
    }
    setLoader4(false);
  }, [
    JSON.stringify(allTitle),
    JSON.stringify(dependentEvents),
    rating,
    status,
    priority,
    duration,
  ]);

  useEffect(() => {
    const today = new Date();
    const firstUpcomingEventIndex = events?.findIndex(
      (event) => new Date(event.event_date) >= today
    );
    if (firstUpcomingEventIndex !== -1) {
      const defaultPage = Math.ceil(
        (firstUpcomingEventIndex + 1) / rowsPerPage
      );
      setCurrentPage(defaultPage);
    } else {
      setCurrentPage(1);
    }
    setLoader(false);
  }, [JSON.stringify(events), status, rating, priority]);

  // useEffect(() => {
  //   if (showPopup || show1) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "auto";
  //   }

  //   return () => {
  //     document.body.style.overflow = "auto";
  //   };
  // }, [showPopup, show1]);

  function formattedDate(dateString) {
    const date = new Date(dateString);
    const monthShortName = date.toLocaleString("en-IN", { month: "short" });
    const day = String(date.getDate()).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2); // Last two digits of the year
    return `${day}-${monthShortName}-${year}`;
  }

  const handleDeleteEvent = (val, date) => {
    if (userOtp === otp) {
      setLoader2(true);
      fetch(`https://api-scdndestates.com/api/delete-event-and-reminders/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          val,
          date,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to delete event/reminder");
          }
          return response.json();
        })
        .then((response) => {
          if (response.message === "Success") {
            setShowModal(2);
            setTimeout(() => {
              setShowModal(-1);
              setLoader2(false);
            }, 3000);
            setShow1(false);
            secureLocalStorage.setItem(
              "admin-calendar",
              JSON.stringify(response.dates)
            );
            onReload(response.dates);
          }
          setUserOtp("");
        })
        .catch((error) => {
          // console.error("Error during logout:", error);
        });
    } else {
      setShowModal(1);
      setTimeout(() => {
        setShowModal(-1);
      }, 3000);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleEvent = (title, date, key, value) => {
    setLoader3(true);
    fetch(`https://api-scdndestates.com/api/edit-event-and-reminders/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title,
        filter,
        employee: emp,
        date,
        key,
        value,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete event/reminder");
        }
        return response.json();
      })
      .then((response) => {
        setLoader3(false);
        if (response.message !== "Success") {
          alert(response.message);
        } else {
          setEvents((prevEvents) =>
            prevEvents?.map((event) =>
              event.title === title
                ? { ...event, [key?.toLowerCase()]: value }
                : event
            )
          );
          setSelectedEvent((prevEvents) => ({
            ...prevEvents,
            [key?.toLowerCase()]: value,
          }));
          setShowModal(9);
          setTimeout(() => {
            setShowModal(-1);
          }, 3000);
        }
        onReload(response.dates);
        setLoader2(false);
        setOtp();
        setUserOtp();
      })
      .catch((error) => {
        // console.error("Error during logout:", error);
      });
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= Math.ceil(events?.length / rowsPerPage)) {
      setCurrentPage(page);
    }
  };

  const rowsPerPage = 6;
  const start = (currentPage - 1) * rowsPerPage;
  const end = start + rowsPerPage;

  const sendDeleteOtp = (val) => {
    if (phone?.length !== 10) {
      setShowModal(3);
      setTimeout(() => {
        setShowModal(-1);
      }, 5000);
    } else {
      fetch(`https://api-scdndestates.com/api/work-delete-otp/`, {
        method: "POST",
        body: JSON.stringify({
          title: val,
          phone: "91" + phone,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to send otp");
          }
          return response.json();
        })
        .then((response) => {
          if (response.error === "Phone number not found") {
            setShowModal(4);
            setTimeout(() => {
              setShowModal(-1);
            }, 5000);
          } else if (response.error === "task not completed") {
            setShowModal(5);
            setTimeout(() => {
              setShowModal(-1);
            }, 5000);
          } else {
            setOtp(response.otp);
            setShow1(true);
          }
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });
    }
  };
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [openSearchBar, setOpenSearchBar] = useState(false);

  const highlightMatch = (content, query) => {
    if (!query) return content;
    const regex = new RegExp(`(${query})`, "gi");
    const parts = content.split(regex);
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <span key={index} className="text-[#0590F8]  decoration-2 font-bold">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <div className="w-full overflow-hidden h-full mx-auto  rounded-lg relative">
      <div className="flex gap-2 items-center justify-between mb-2 py-1 px-0.5">
        <button
          type="submit"
          className="flex items-center duration-300 ease-in-out w-fit justify-center rounded-md bg-[#5ab2ff] max-md:px-2.5 py-1.5 md:px-3 md:py-1.5 text-xs md:text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#3aa0ff] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 uppercase gap-2 whitespace-nowrap"
          onClick={() => onBack()}
        >
          <span>
            <svg
              className="w-3 h-3 md:w-3.5 md:h-3.5"
              version="1.1"
              id="fi_271220"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsHlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 492 492"
              xmlSpace="preserve"
            >
              <g>
                <g>
                  <path
                    d="M198.608,246.104L382.664,62.04c5.068-5.056,7.856-11.816,7.856-19.024c0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12
C361.476,2.792,354.712,0,347.504,0s-13.964,2.792-19.028,7.864L109.328,227.008c-5.084,5.08-7.868,11.868-7.848,19.084
c-0.02,7.248,2.76,14.028,7.848,19.112l218.944,218.932c5.064,5.072,11.82,7.864,19.032,7.864c7.208,0,13.964-2.792,19.032-7.864
l16.124-16.12c10.492-10.492,10.492-27.572,0-38.06L198.608,246.104z"
                  ></path>
                </g>
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
          </span>
          back
        </button>
        <div className="relative">
          <div
            onClick={() => setOpenSearchBar(true)}
            className={`pl-[11px] pr-1 overflow-hidden h-9 w-9 md:w-[400px] xl:w-[450px] rounded-full
                shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] flex items-center cursor-pointer justify-between
                transition-all duration-500 md:bg-[#ffffff]
                ${
                  openSearchBar
                    ? "w-[250px] bg-[#ffffff]"
                    : "max-sm:w-9 bg-[#59B2FF]"
                }`}
          >
            <div
              className={`${
                openSearchBar ? "fill-[#000000]" : "fill-[#ffffff]"
              } flex items-center justify-start  md:fill-[#000000]`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Isolation_Mode"
                data-name="Isolation Mode"
                viewBox="0 0 24 24"
                className="w-4 h-4"
              >
                <path d="M18.9,16.776A10.539,10.539,0,1,0,16.776,18.9l5.1,5.1L24,21.88ZM10.5,18A7.5,7.5,0,1,1,18,10.5,7.507,7.507,0,0,1,10.5,18Z"></path>
              </svg>
              <input
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                  if (e.target.value.length > 3) {
                    handleGetResult();
                  } else {
                    setResults([]);
                  }
                }}
                placeholder="Search..."
                className={`outline-none text-[20px] bg-transparent text-[#000000] font-normal px-2 placeholder:text-sm text-sm w-0 md:w-[300px] xl:w-[350px] transition-all duration-300 ${
                  openSearchBar ? "w-[150px]" : "w-0"
                }`}
              />
            </div>
            <button
              onClick={(e) => {
                e.stopPropagation(); // Prevent closing when clicking the button
                handleMultiSearch(query);
                setResults([]);
                setShow2(false);
              }}
              className="justify-center align-center bg-[#5AB2FF] hover:bg-[#3AA0FF] text-xs md:text-sm font-semibold
                 rounded-full text-[#ffffff] px-2.5 py-1 duration-300 whitespace-nowrap"
            >
              Search
            </button>
          </div>

          {results.length > 0 && query.length > 3 && (
            <div className="absolute top-[110%] z-[10] right-0 w-[332px] md:w-full bg-[#ffffff] flex flex-col gap-2 p-1.5 rounded-xl shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] duration-300">
              {results.map((data, index) => (
                <div
                  key={index}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  onClick={() => {
                    setAllTitle([
                      {
                        title: data._source.title,
                        Date: data._source.Date.replaceAll("-", "_"),
                      },
                    ]);
                    setRating("All");
                    setPriority("All");
                    setStatus("All");
                    setResults([]);
                  }}
                  className=" hover:bg-[#e2f1fc] cursor-pointer p-2 text-sm font-semibold text-gray-700 border-b-2 border-gray-100 hover:rounded-lg flex"
                >
                  <span className="text-[13px] md:text-sm font-medium text-gray-700 flex items-center justify-between">
                    <span className="border-r-2 border-[#3AA0FF] pr-2 whitespace-nowrap">
                      {data._source.Date}
                    </span>
                  </span>
                  <span className="text-[13px] md:text-sm pl-2 font-medium whitespace-nowrap overflow-hidden">
                    {data?._source.title
                      ? highlightMatch(
                          data._source.title.length > 40
                            ? `${data._source.title.slice(0, 40)}...`
                            : data._source.title,
                          query
                        )
                      : "No title available"}
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {/* Title and Pagination Control */}
      <div
        className={`flex justify-between duration-500 ease-in-out transition-all bg-sky-600 rounded-t-lg items-center py-2.5 px-2 md:px-3 ${
          showPopup ? "opacity-20" : "opacity-100"
        }`}
      >
        <h1 className="text-base md:text-lg font-bold text-[#ffffff]">
          Sprint
        </h1>
        <div className="flex gap-5 items-center relative">
          {/* {label === "Admin" && ( */}
          <div ref={filterRef} className="relative   max-md:hidden">
            {/* Increased z-index here */}
            <button
              onClick={toggleFilter}
              className="flex bg-sky-100 hover:bg-[#BAE6FC] gap-1 pl-1 py-1 pr-2 rounded text-sm font-semibold items-center hover:scale-95 duration-300"
            >
              <span className="rotate-90">
                <svg
                  id="fi_9702724"
                  className="w-4 h-4 text-black"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsSvg="http://www.w3.org/2000/svg"
                >
                  <g
                    id="layer1"
                    fill-rule="evenodd"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="4.1"
                    transform="translate(-300 -100)"
                  >
                    <path
                      id="path6012"
                      d="m321 106.01215c-2.40719 0-4.43155 1.72541-4.89844 4h-13.10156c-.55228 0-1 .44772-1 1s.44772 1 1 1h13.10156c.46689 2.27459 2.49125 4 4.89844 4s4.43155-1.72541 4.89844-4h3.10156c.55228 0 1-.44772 1-1s-.44772-1-1-1h-3.10156c-.46689-2.27459-2.49125-4-4.89844-4zm0 2c1.66867 0 3 1.33133 3 3s-1.33133 3-3 3-3-1.33133-3-3 1.33133-3 3-3z"
                    ></path>
                    <path
                      id="path6014"
                      d="m311 116.01215c-2.40719 0-4.43155 1.72541-4.89844 4h-3.10156c-.55228 0-1 .44772-1 1s.44772 1 1 1h3.10156c.46689 2.27459 2.49125 4 4.89844 4s4.43155-1.72541 4.89844-4h13.10156c.55228 0 1-.44772 1-1s-.44772-1-1-1h-13.10156c-.46689-2.27459-2.49125-4-4.89844-4zm0 2c1.66867 0 3 1.33133 3 3s-1.33133 3-3 3-3-1.33133-3-3 1.33133-3 3-3z"
                    ></path>
                  </g>
                </svg>
              </span>
              Filter
            </button>
            {isFilterOpen && (
              <div className="alert-animation absolute right-0 z-[50] mt-2 w-full bg-white border border-gray-200 rounded-md shadow-lg min-w-[200px] ">
                <div className="p-2.5 space-y-3 z-20">
                  {/* Assignee Dropdown */}
                  <div>
                    <label className="block text-sm font-semibold text-gray-800">
                      Rating
                    </label>
                    <select
                      value={rating}
                      onChange={(e) => {
                        setRating(e.target.value);
                        if (e.target.value) {
                          setIsFilterOpen(false);
                        }
                        setCurrentPage(1);
                      }}
                      className="w-full mt-1 px-1.5 text-sm py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="All">All</option>
                      {allRating.map((a) => (
                        <option key={a} value={a}>
                          {a}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Status Dropdown */}
                  <div>
                    <label className="block text-sm font-semibold text-gray-800">
                      Status
                    </label>
                    <select
                      value={status}
                      onChange={(e) => {
                        setStatus(e.target.value);
                        if (e.target.value) {
                          setIsFilterOpen(false);
                        }
                        setCurrentPage(1);
                      }}
                      className="w-full mt-1 px-1.5 text-sm py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="All">All</option>
                      {allStatus.map((a) => (
                        <option key={a} value={a}>
                          {a}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Priority Dropdown */}
                  <div>
                    <label className="block text-sm font-semibold text-gray-800">
                      Priority
                    </label>
                    <select
                      value={priority}
                      onChange={(e) => {
                        setPriority(e.target.value);
                        if (e.target.value) {
                          setIsFilterOpen(false);
                        }
                        setCurrentPage(1);
                      }}
                      className="w-full mt-1 px-1.5 text-sm py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="All">All</option>
                      {allPriority
                        .sort((a, b) => {
                          const numA = parseInt(a.substring(1), 10);
                          const numB = parseInt(b.substring(1), 10);
                          return numA - numB;
                        })
                        .map((a) => (
                          <option key={a} value={a}>
                            {a}
                          </option>
                        ))}
                    </select>
                  </div>

                  {/* Duration Dropdown */}
                  <div>
                    <label className="block text-sm font-semibold text-gray-800">
                      Duration
                    </label>
                    <select
                      value={duration}
                      onChange={(e) => {
                        setDuration(e.target.value);
                        setStatus("All");
                        if (e.target.value) {
                          setIsFilterOpen(false);
                        }
                        setCurrentPage(1);
                      }}
                      className="w-full mt-1 px-1.5 text-sm py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="All">All</option>
                      {allDuration.map((a) => (
                        <option key={a} value={a}>
                          {a}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* )} */}
          {/* Side bar */}
          <div className="md:hidden pl-[120px]">
            {/* Increased z-index here */}
            <button
              onClick={() => setIsFilterOpen1(true)}
              className="flex bg-sky-100 hover:bg-[#BAE6FC] gap-1 p-1 rounded text-sm font-semibold items-center hover:scale-95 duration-300"
            >
              <span className="rotate-90">
                <svg
                  id="fi_9702724"
                  className="w-4 h-4 text-black"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsSvg="http://www.w3.org/2000/svg"
                >
                  <g
                    id="layer1"
                    fill-rule="evenodd"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="4.1"
                    transform="translate(-300 -100)"
                  >
                    <path
                      id="path6012"
                      d="m321 106.01215c-2.40719 0-4.43155 1.72541-4.89844 4h-13.10156c-.55228 0-1 .44772-1 1s.44772 1 1 1h13.10156c.46689 2.27459 2.49125 4 4.89844 4s4.43155-1.72541 4.89844-4h3.10156c.55228 0 1-.44772 1-1s-.44772-1-1-1h-3.10156c-.46689-2.27459-2.49125-4-4.89844-4zm0 2c1.66867 0 3 1.33133 3 3s-1.33133 3-3 3-3-1.33133-3-3 1.33133-3 3-3z"
                    ></path>
                    <path
                      id="path6014"
                      d="m311 116.01215c-2.40719 0-4.43155 1.72541-4.89844 4h-3.10156c-.55228 0-1 .44772-1 1s.44772 1 1 1h3.10156c.46689 2.27459 2.49125 4 4.89844 4s4.43155-1.72541 4.89844-4h13.10156c.55228 0 1-.44772 1-1s-.44772-1-1-1h-13.10156c-.46689-2.27459-2.49125-4-4.89844-4zm0 2c1.66867 0 3 1.33133 3 3s-1.33133 3-3 3-3-1.33133-3-3 1.33133-3 3-3z"
                    ></path>
                  </g>
                </svg>
              </span>
            </button>
          </div>
          <div className="max-md:hidden md:flex space-x-4 items-center">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`px-2 text-sm py-1 bg-sky-100 text-[#212121] font-semibold rounded hover:bg-sky-200 transition ${
                currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              Previous
            </button>
            <span className="text-[#ffffff] text-sm">
              <span className="font-semibold">Page {currentPage}</span> of{" "}
              {Math.ceil(events?.length / rowsPerPage)}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === Math.ceil(events?.length / rowsPerPage)}
              className={`px-2 text-sm py-1 bg-sky-100 text-[#212121] font-semibold rounded hover:bg-sky-200 transition ${
                currentPage === Math.ceil(events?.length / rowsPerPage)
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
            >
              Next
            </button>
          </div>
        </div>
        <div className="max-md:flex md:hidden space-x-4 items-center">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-2 text-sm py-1 bg-sky-100 text-[#212121] font-semibold rounded hover:bg-sky-200 transition ${
              currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            <MdSkipPrevious />
          </button>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === Math.ceil(events?.length / rowsPerPage)}
            className={`px-2 text-sm py-1 bg-sky-100 text-[#212121] font-semibold rounded hover:bg-sky-200 transition ${
              currentPage === Math.ceil(events?.length / rowsPerPage)
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
          >
            <MdSkipNext />
          </button>
        </div>
      </div>

      {/* Event Table */}
      <div
        className={`w-full h-[600px]  duration-500 ease-in-out transition-all overflow-y-scroll  bg-white ${
          showPopup ? "opacity-20" : "opacity-100"
        }`}
      >
        {events?.length === 0 ? (
          <div className="m-2 space-y-2 group overflow-y-scroll h-[630px]">
            <div className="w-full h-14 rounded-lg">No data</div>
          </div>
        ) : (
          events
            ?.slice(start, end)
            ?.filter(
              (event) =>
                event.title
                  .toLowerCase()
                  .includes(searchValue?.toLowerCase()) ||
                event.assignee
                  .toLowerCase()
                  .includes(searchValue?.toLowerCase()) ||
                event.desc.toLowerCase().includes(searchValue?.toLowerCase())
            )
            .map((event, index) =>
              loader ? (
                <div className="relative flex left-[15%] md:left-[42%] md:-translate-[50%]">
                  <Loader />
                </div>
              ) : (
                <div
                  className="border-b border-gray-200 hover:bg-[#f0f9ff9a] hover:shadow-md py-1  min-w-[950px]"
                  onClick={() => setResults(false)}
                >
                  <div
                    key={index}
                    className={`w-full flex gap-3 items-start justify-between relative transition duration-200 text-[#313131] font-semibold text-sm table-fade-in`}
                  >
                    <div className="flex items-start gap-3 p-2 w-fit ">
                      <div className="p-2 min-w-[140px]  w-fit">
                        <div className="w-full overflow-hidden flex flex-col justify-start items-start whitespace-nowrap px-1.5">
                          <span>{event.assignee}</span>
                          <span className="text-xs font-normal">
                            {formattedDate(event.event_date)}
                          </span>
                        </div>
                      </div>
                      <div className="p-2 ">
                        <div className="w-full flex flex-col justify-start gap-1.5 items-start overflow-hidden whitespace-nowrap px-1.5 cursor-pointer">
                          <div
                            onClick={() => {
                              setSelectedEvent(event);
                              setShowPopup(true);
                              window.scroll(0, 0);
                            }}
                            className="flex flex-col gap-1 w-full overflow-hidden"
                          >
                            <div className="font-semibold text-[15px]">
                              {event?.title
                                ? highlightMatch(
                                    event.title.length > maxLengthTitle
                                      ? `${event.title.slice(
                                          0,
                                          maxLengthTitle
                                        )}...`
                                      : event.title,
                                    query
                                  )
                                : "No title available"}
                            </div>
                            <div className="text-sm font-normal overflow-hidden text-gray-500 text-wrap">
                              {event?.desc
                                ? highlightMatch(
                                    event.desc.length > maxLength
                                      ? `${event.desc.slice(0, maxLength)}...`
                                      : event.desc,
                                    query
                                  )
                                : "No description available"}
                            </div>
                          </div>
                          {(event?.wtdfiles || event?.assigneefiles) && (
                            <div className="flex justify-start items-start gap-x-5 gap-y-2  w-[450px] xl:w-[100%] overflow-x-scroll pt-2">
                              {event?.wtdfiles && (
                                <div className="text-xs  rounded-lg   text-gray-500 font-normal flex-col items-start justify-start gap-y-1">
                                  {/* Files Attached */}
                                  {/* {event?.wtdfiles && (
                                    <div className="text-xs font-semibold flex items-center gap-x-2 text-black ">
                                      <div className="w-3 h-1  bg-yellow-400 rounded-lg"></div>
                                      WTD Files
                                    </div>
                                  )} */}
                                  <div className="flex flex-col items-start justify-start gap-y-1">
                                    {event?.wtdfiles &&
                                      (() => {
                                        const fileEntries = Object.entries(
                                          event.wtdfiles
                                        );
                                        const fileCount = fileEntries.length;

                                        // CSS for animation
                                        const animationStyles = {
                                          entering: {
                                            maxHeight: "0px",
                                            opacity: 0,
                                            overflow: "hidden",
                                            transition:
                                              "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                                          },
                                          entered: {
                                            maxHeight: "500px", // Adjust based on your needs
                                            opacity: 1,
                                            overflow: "hidden",
                                            transition:
                                              "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                                          },
                                        };
                                        return (
                                          <>
                                            <div className="flex items-center justify-start gap-x-2">
                                              {/* Always show first file */}
                                              {fileCount > 0 &&
                                                (() => {
                                                  const [fileName, fileUrl] =
                                                    fileEntries[0];
                                                  return (
                                                    <a
                                                      key={fileName}
                                                      href={fileUrl}
                                                      onClick={() => {
                                                        handleDownload(fileUrl);
                                                      }}
                                                      className="bg-[#f5fafd] flex items-center justify-start gap-1.5 px-2 py-1 rounded-full border-[1px] border-[#5ab2ff] text-xs w-48  overflow-hidden decoration-inherit"
                                                    >
                                                      <span>
                                                        {fileName
                                                          .split("_")
                                                          .pop() === "pdf" ? (
                                                          <img
                                                            src="/images/pdf.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : fileName
                                                            .split("_")
                                                            .pop() ===
                                                          "pptx" ? (
                                                          <img
                                                            src="/images/ppt.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : [
                                                            "xlsx",
                                                            "xlsm",
                                                            "xls",
                                                          ].includes(
                                                            fileName
                                                              .split("_")
                                                              .pop()
                                                          ) ? (
                                                          <img
                                                            src="/images/xls.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : fileName
                                                            .split("_")
                                                            .pop() === "txt" ? (
                                                          <img
                                                            src="/images/txt.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : [
                                                            "doc",
                                                            "docs",
                                                            "docx",
                                                            "docm",
                                                          ].includes(
                                                            fileName
                                                              .split("_")
                                                              .pop()
                                                          ) ? (
                                                          <img
                                                            src="/images/doc.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : [
                                                            "png",
                                                            "jpg",
                                                            "jpeg",
                                                            "webp",
                                                            "svg",
                                                          ].includes(
                                                            fileName
                                                              .split("_")
                                                              .pop()
                                                          ) ? (
                                                          <img
                                                            src="/images/picture.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : [
                                                            "mp4",
                                                            "mkv",
                                                            "webm",
                                                            "mov",
                                                            "avi",
                                                            "wmv",
                                                          ].includes(
                                                            fileName
                                                              .split("_")
                                                              .pop()
                                                          ) ? (
                                                          <img
                                                            src="/images/video-player.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : [
                                                            "mp3",
                                                            "wav",
                                                          ].includes(
                                                            fileName
                                                              .split("_")
                                                              .pop()
                                                          ) ? (
                                                          <img
                                                            src="/images/microphone.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : fileName
                                                            .split("_")
                                                            .pop() === "jsx" ? (
                                                          <img
                                                            src="/images/jsx.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : fileName
                                                            .split("_")
                                                            .pop() ===
                                                          "json" ? (
                                                          <img
                                                            src="/images/json.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : fileName
                                                            .split("_")
                                                            .pop() === "css" ? (
                                                          <img
                                                            src="/images/css.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : fileName
                                                            .split("_")
                                                            .pop() === "js" ? (
                                                          <img
                                                            src="/images/js.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : fileName
                                                            .split("_")
                                                            .pop() ===
                                                          "html" ? (
                                                          <img
                                                            src="/images/html.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : fileName
                                                            .split("_")
                                                            .pop() === "py" ? (
                                                          <img
                                                            src="/images/python.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : fileName
                                                            .split("_")
                                                            .pop() === "xml" ? (
                                                          <img
                                                            src="/images/xml.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : fileName
                                                            .split("_")
                                                            .pop() === "zip" ? (
                                                          <img
                                                            src="/images/zip.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : (
                                                          <img
                                                            src="/images/unknown.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        )}
                                                      </span>
                                                      <span className="truncate">
                                                        {fileName.replace(
                                                          /_(?=[^_]*$)/,
                                                          "."
                                                        )}
                                                      </span>
                                                    </a>
                                                  );
                                                })()}

                                              {/* Show +n button if there are more files */}
                                              {fileCount > 1 &&
                                                !showAllFiles && (
                                                  <button
                                                    onClick={() =>
                                                      setShowAllFiles(true)
                                                    }
                                                    className="flex items-center justify-center w-6 h-6 rounded-full bg-gray-200 text-gray-600 text-xs font-medium hover:bg-gray-300 transition-colors"
                                                  >
                                                    +{fileCount - 1}
                                                  </button>
                                                )}

                                              {/* Show less button when expanded */}
                                              {showAllFiles &&
                                                fileCount > 1 && (
                                                  <button
                                                    onClick={() =>
                                                      setShowAllFiles(false)
                                                    }
                                                    className="text-xs w-6 h-6 p-1.5 rounded-full "
                                                  >
                                                    <img
                                                      className="-rotate-90 scale-100 hover:scale-95"
                                                      src="/images/right-arrow.svg"
                                                      alt=""
                                                    />
                                                  </button>
                                                )}
                                            </div>

                                            {/* Animation container for additional files */}
                                            <div
                                              className="w-full transition-all duration-300 overflow-hidden"
                                              style={
                                                showAllFiles
                                                  ? {
                                                      maxHeight: `${
                                                        (fileCount - 1) * 40
                                                      }px`,
                                                      opacity: 1,
                                                    }
                                                  : {
                                                      maxHeight: "0px",
                                                      opacity: 0,
                                                    }
                                              }
                                            >
                                              <div className="flex flex-col gap-y-1 pt-1">
                                                {/* Show remaining files if expanded */}
                                                {fileEntries
                                                  .slice(1)
                                                  .map(
                                                    ([fileName, fileUrl]) => (
                                                      <button
                                                        onClick={() => {
                                                          handleDownload(
                                                            fileUrl
                                                          );
                                                        }}
                                                        className="flex items-center justify-start gap-1.5 px-2 py-1 rounded-full border w-fit overflow-hidden"
                                                      >
                                                        <span>
                                                          {fileName
                                                            .split("_")
                                                            .pop() === "pdf" ? (
                                                            <img
                                                              src="/images/pdf.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : fileName
                                                              .split("_")
                                                              .pop() ===
                                                            "pptx" ? (
                                                            <img
                                                              src="/images/ppt.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : [
                                                              "xlsx",
                                                              "xlsm",
                                                              "xls",
                                                            ].includes(
                                                              fileName
                                                                .split("_")
                                                                .pop()
                                                            ) ? (
                                                            <img
                                                              src="/images/xls.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : fileName
                                                              .split("_")
                                                              .pop() ===
                                                            "txt" ? (
                                                            <img
                                                              src="/images/txt.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : [
                                                              "doc",
                                                              "docs",
                                                              "docx",
                                                              "docm",
                                                            ].includes(
                                                              fileName
                                                                .split("_")
                                                                .pop()
                                                            ) ? (
                                                            <img
                                                              src="/images/doc.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : [
                                                              "png",
                                                              "jpg",
                                                              "jpeg",
                                                              "webp",
                                                              "svg",
                                                            ].includes(
                                                              fileName
                                                                .split("_")
                                                                .pop()
                                                            ) ? (
                                                            <img
                                                              src="/images/picture.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : [
                                                              "mp4",
                                                              "mkv",
                                                              "webm",
                                                              "mov",
                                                              "avi",
                                                              "wmv",
                                                            ].includes(
                                                              fileName
                                                                .split("_")
                                                                .pop()
                                                            ) ? (
                                                            <img
                                                              src="/images/video-player.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : [
                                                              "mp3",
                                                              "wav",
                                                            ].includes(
                                                              fileName
                                                                .split("_")
                                                                .pop()
                                                            ) ? (
                                                            <img
                                                              src="/images/microphone.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : fileName
                                                              .split("_")
                                                              .pop() ===
                                                            "jsx" ? (
                                                            <img
                                                              src="/images/jsx.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : fileName
                                                              .split("_")
                                                              .pop() ===
                                                            "json" ? (
                                                            <img
                                                              src="/images/json.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : fileName
                                                              .split("_")
                                                              .pop() ===
                                                            "css" ? (
                                                            <img
                                                              src="/images/css.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : fileName
                                                              .split("_")
                                                              .pop() ===
                                                            "js" ? (
                                                            <img
                                                              src="/images/js.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : fileName
                                                              .split("_")
                                                              .pop() ===
                                                            "html" ? (
                                                            <img
                                                              src="/images/html.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : fileName
                                                              .split("_")
                                                              .pop() ===
                                                            "py" ? (
                                                            <img
                                                              src="/images/python.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : fileName
                                                              .split("_")
                                                              .pop() ===
                                                            "xml" ? (
                                                            <img
                                                              src="/images/xml.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : fileName
                                                              .split("_")
                                                              .pop() ===
                                                            "zip" ? (
                                                            <img
                                                              src="/images/zip.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : (
                                                            <img
                                                              src="/images/unknown.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          )}
                                                        </span>
                                                        <span className="truncate">
                                                          {fileName.replace(
                                                            /_(?=[^_]*$)/,
                                                            "."
                                                          )}
                                                        </span>
                                                      </button>
                                                    )
                                                  )}
                                              </div>
                                            </div>
                                          </>
                                        );
                                      })()}
                                  </div>
                                </div>
                              )}
                              {event?.assigneefiles && (
                                <div className="text-xs  rounded-lg  text-gray-500 font-normal flex-col items-start justify-start gap-y-1">
                                  {/* Files Attached */}
                                  {/* {event?.wtdfiles && (
                                  <div className="text-xs font-semibold flex items-center gap-x-2 text-black ">
                                    <div className="w-3 h-1  bg-yellow-400 rounded-lg"></div>
                                    WTD Files
                                  </div>
                                )} */}
                                  <div className="flex flex-col items-start justify-start gap-y-1">
                                    {event?.assigneefiles &&
                                      (() => {
                                        const fileEntries = Object.entries(
                                          event.assigneefiles
                                        );
                                        const fileCount = fileEntries.length;

                                        // CSS for animation
                                        const animationStyles = {
                                          entering: {
                                            maxHeight: "0px",
                                            opacity: 0,
                                            overflow: "hidden",
                                            transition:
                                              "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                                          },
                                          entered: {
                                            maxHeight: "500px", // Adjust based on your needs
                                            opacity: 1,
                                            overflow: "hidden",
                                            transition:
                                              "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
                                          },
                                        };
                                        return (
                                          <>
                                            <div className="flex items-center justify-start gap-x-2">
                                              {/* Always show first file */}
                                              {fileCount > 0 &&
                                                (() => {
                                                  const [fileName, fileUrl] =
                                                    fileEntries[0];
                                                  return (
                                                    <button
                                                      onClick={() => {
                                                        handleDownload(fileUrl);
                                                      }}
                                                      className="flex items-center justify-start gap-1.5 px-2 py-1 rounded-full border w-fit overflow-hidden"
                                                    >
                                                      <span>
                                                        {fileName
                                                          .split("_")
                                                          .pop() === "pdf" ? (
                                                          <img
                                                            src="/images/pdf.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : fileName
                                                            .split("_")
                                                            .pop() ===
                                                          "pptx" ? (
                                                          <img
                                                            src="/images/ppt.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : [
                                                            "xlsx",
                                                            "xlsm",
                                                            "xls",
                                                          ].includes(
                                                            fileName
                                                              .split("_")
                                                              .pop()
                                                          ) ? (
                                                          <img
                                                            src="/images/xls.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : fileName
                                                            .split("_")
                                                            .pop() === "txt" ? (
                                                          <img
                                                            src="/images/txt.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : [
                                                            "doc",
                                                            "docs",
                                                            "docx",
                                                            "docm",
                                                          ].includes(
                                                            fileName
                                                              .split("_")
                                                              .pop()
                                                          ) ? (
                                                          <img
                                                            src="/images/doc.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : [
                                                            "png",
                                                            "jpg",
                                                            "jpeg",
                                                            "webp",
                                                            "svg",
                                                          ].includes(
                                                            fileName
                                                              .split("_")
                                                              .pop()
                                                          ) ? (
                                                          <img
                                                            src="/images/picture.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : [
                                                            "mp4",
                                                            "mkv",
                                                            "webm",
                                                            "mov",
                                                            "avi",
                                                            "wmv",
                                                          ].includes(
                                                            fileName
                                                              .split("_")
                                                              .pop()
                                                          ) ? (
                                                          <img
                                                            src="/images/video-player.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : [
                                                            "mp3",
                                                            "wav",
                                                          ].includes(
                                                            fileName
                                                              .split("_")
                                                              .pop()
                                                          ) ? (
                                                          <img
                                                            src="/images/microphone.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : fileName
                                                            .split("_")
                                                            .pop() === "jsx" ? (
                                                          <img
                                                            src="/images/jsx.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : fileName
                                                            .split("_")
                                                            .pop() ===
                                                          "json" ? (
                                                          <img
                                                            src="/images/json.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : fileName
                                                            .split("_")
                                                            .pop() === "css" ? (
                                                          <img
                                                            src="/images/css.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : fileName
                                                            .split("_")
                                                            .pop() === "js" ? (
                                                          <img
                                                            src="/images/js.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : fileName
                                                            .split("_")
                                                            .pop() ===
                                                          "html" ? (
                                                          <img
                                                            src="/images/html.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : fileName
                                                            .split("_")
                                                            .pop() === "py" ? (
                                                          <img
                                                            src="/images/python.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : fileName
                                                            .split("_")
                                                            .pop() === "xml" ? (
                                                          <img
                                                            src="/images/xml.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : fileName
                                                            .split("_")
                                                            .pop() === "zip" ? (
                                                          <img
                                                            src="/images/zip.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        ) : (
                                                          <img
                                                            src="/images/unknown.svg"
                                                            className="w-4 h-4"
                                                          />
                                                        )}
                                                      </span>
                                                      <span className="truncate">
                                                        {fileName.replace(
                                                          /_(?=[^_]*$)/,
                                                          "."
                                                        )}
                                                      </span>
                                                    </button>
                                                  );
                                                })()}

                                              {/* Show +n button if there are more files */}
                                              {fileCount > 1 &&
                                                !showAllFiles && (
                                                  <button
                                                    onClick={() =>
                                                      setShowAllFiles(true)
                                                    }
                                                    className="flex items-center justify-center w-6 h-6 rounded-full bg-gray-200 text-gray-600 text-xs font-medium hover:bg-gray-300 transition-colors"
                                                  >
                                                    +{fileCount - 1}
                                                  </button>
                                                )}

                                              {/* Show less button when expanded */}
                                              {showAllFiles &&
                                                fileCount > 1 && (
                                                  <button
                                                    onClick={() =>
                                                      setShowAllFiles(false)
                                                    }
                                                    className="text-xs w-6 h-6 p-1.5 rounded-full "
                                                  >
                                                    <img
                                                      className="-rotate-90 scale-100 hover:scale-95"
                                                      src="/images/right-arrow.svg"
                                                      alt=""
                                                    />
                                                  </button>
                                                )}
                                            </div>

                                            {/* Animation container for additional files */}
                                            <div
                                              className="w-full transition-all duration-300 overflow-hidden"
                                              style={
                                                showAllFiles
                                                  ? {
                                                      maxHeight: `${
                                                        (fileCount - 1) * 40
                                                      }px`,
                                                      opacity: 1,
                                                    }
                                                  : {
                                                      maxHeight: "0px",
                                                      opacity: 0,
                                                    }
                                              }
                                            >
                                              <div className="flex flex-col gap-y-1 pt-1">
                                                {/* Show remaining files if expanded */}
                                                {fileEntries
                                                  .slice(1)
                                                  .map(
                                                    ([fileName, fileUrl]) => (
                                                      <a
                                                        key={fileName}
                                                        href={fileUrl}
                                                        onClick={() => {
                                                          handleDownload(
                                                            fileUrl
                                                          );
                                                        }}
                                                        className="bg-[#fdfff1] flex items-center justify-start gap-1.5 px-2 py-1 rounded-full border-[1px] border-[#b7ca58] text-xs w-48 overflow-hidden"
                                                      >
                                                        <span>
                                                          {fileName
                                                            .split("_")
                                                            .pop() === "pdf" ? (
                                                            <img
                                                              src="/images/pdf.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : fileName
                                                              .split("_")
                                                              .pop() ===
                                                            "pptx" ? (
                                                            <img
                                                              src="/images/ppt.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : [
                                                              "xlsx",
                                                              "xlsm",
                                                              "xls",
                                                            ].includes(
                                                              fileName
                                                                .split("_")
                                                                .pop()
                                                            ) ? (
                                                            <img
                                                              src="/images/xls.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : fileName
                                                              .split("_")
                                                              .pop() ===
                                                            "txt" ? (
                                                            <img
                                                              src="/images/txt.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : [
                                                              "doc",
                                                              "docs",
                                                              "docx",
                                                              "docm",
                                                            ].includes(
                                                              fileName
                                                                .split("_")
                                                                .pop()
                                                            ) ? (
                                                            <img
                                                              src="/images/doc.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : [
                                                              "png",
                                                              "jpg",
                                                              "jpeg",
                                                              "webp",
                                                              "svg",
                                                            ].includes(
                                                              fileName
                                                                .split("_")
                                                                .pop()
                                                            ) ? (
                                                            <img
                                                              src="/images/picture.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : [
                                                              "mp4",
                                                              "mkv",
                                                              "webm",
                                                              "mov",
                                                              "avi",
                                                              "wmv",
                                                            ].includes(
                                                              fileName
                                                                .split("_")
                                                                .pop()
                                                            ) ? (
                                                            <img
                                                              src="/images/video-player.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : [
                                                              "mp3",
                                                              "wav",
                                                            ].includes(
                                                              fileName
                                                                .split("_")
                                                                .pop()
                                                            ) ? (
                                                            <img
                                                              src="/images/microphone.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : fileName
                                                              .split("_")
                                                              .pop() ===
                                                            "jsx" ? (
                                                            <img
                                                              src="/images/jsx.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : fileName
                                                              .split("_")
                                                              .pop() ===
                                                            "json" ? (
                                                            <img
                                                              src="/images/json.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : fileName
                                                              .split("_")
                                                              .pop() ===
                                                            "css" ? (
                                                            <img
                                                              src="/images/css.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : fileName
                                                              .split("_")
                                                              .pop() ===
                                                            "js" ? (
                                                            <img
                                                              src="/images/js.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : fileName
                                                              .split("_")
                                                              .pop() ===
                                                            "html" ? (
                                                            <img
                                                              src="/images/html.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : fileName
                                                              .split("_")
                                                              .pop() ===
                                                            "py" ? (
                                                            <img
                                                              src="/images/python.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : fileName
                                                              .split("_")
                                                              .pop() ===
                                                            "xml" ? (
                                                            <img
                                                              src="/images/xml.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : fileName
                                                              .split("_")
                                                              .pop() ===
                                                            "zip" ? (
                                                            <img
                                                              src="/images/zip.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          ) : (
                                                            <img
                                                              src="/images/unknown.svg"
                                                              className="w-4 h-4"
                                                            />
                                                          )}
                                                        </span>
                                                        <span className="truncate">
                                                          {fileName.replace(
                                                            /_(?=[^_]*$)/,
                                                            "."
                                                          )}
                                                        </span>
                                                      </a>
                                                    )
                                                  )}
                                              </div>
                                            </div>
                                          </>
                                        );
                                      })()}
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {dependencyOpen === index &&
                      selectedEvent.dependency.length >= 0 && (
                        <DependencyPopup
                          OnDataChange={(e) => {
                            secureLocalStorage.setItem(
                              "admin-calendar",
                              JSON.stringify(e)
                            );
                            onReload(e);
                            setDependencyOpen(-1);
                          }}
                          employee={emp}
                          filter={filter}
                          data={selectedEvent}
                          events={dependentEvents}
                          onClose={() => setDependencyOpen(-1)}
                        />
                      )}
                    <div className="flex items-center gap-3 p-2">
                      {loader4 ? (
                        <>
                          <div className="w-full gap-2  min-w-16 flex rounded-md bg-gray-300 animate-pulse text-gray-300 px-2.5 py-1">
                            <div className="opacity-0  ">P</div>
                          </div>
                        </>
                      ) : (
                        <div
                          className="flex  items-center w-16 justify-center  border  bg-[#fff] hover:shadow-[inset_0px_0px_3px_1.5px_#a0aec0] duration-300 ease-in-out px-2.5   py-1 shadow-[inset_0px_0px_2px_1px_#a0aec0]  gap-1 rounded-md cursor-pointer  group"
                          onClick={() => {
                            setDependencyOpen(index);
                            setSelectedEvent(event);
                          }}
                        >
                          <span>
                            <span>
                              <svg
                                id="fi_14232400"
                                enable-background="new 0 0 500 500"
                                viewBox="0 0 500 500"
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-5 h-5 "
                              >
                                <g fill="#000">
                                  <ellipse
                                    cx="327.097"
                                    cy="79.067"
                                    rx="44.406"
                                    ry="44.406"
                                    transform="matrix(.988 -.155 .155 .988 -8.297 51.589)"
                                  ></ellipse>
                                  <path d="m137.343 271.568-35.872 69.108-79.136 82.51c-10.513 10.961-10.149 28.37.812 38.883 5.331 5.113 12.186 7.653 19.032 7.653 7.23 0 14.45-2.834 19.851-8.464l81.874-85.365c1.816-1.894 3.352-4.038 4.561-6.366l29.904-57.61-14.13-7.739c-12.859-7.044-22.452-18.764-26.896-32.61z"></path>
                                  <path d="m470.675 112.424c-5.188-1.895-10.929.776-12.823 5.964l-21.762 59.612h-66.138l-162.713-114.83c-12.047-8.501-28.654-5.99-37.647 5.693l-66.099 85.866c-9.265 12.035-7.019 29.302 5.016 38.566 12.035 9.265 29.302 7.019 38.566-5.016l50.007-64.96 29.783 21.019-67.999 92.575c-4.087 7.776-5.318 16.815-3.34 25.481 2.357 10.32 9.034 19.156 18.318 24.242l77.435 41.117-50.716 32.321c-12.808 8.163-16.574 25.163-8.411 37.971 5.244 8.228 14.133 12.724 23.217 12.724 5.057 0 10.173-1.394 14.753-4.312l90.755-57.838c8.273-5.273 13.111-14.554 12.695-24.357-.416-9.802-6.022-18.64-14.713-23.193l-61.815-32.385 51.317-66.83 37.007 26.116c4.639 3.274 10.178 5.032 15.856 5.032h54.785l-4.954 13.571c-1.894 5.188.776 10.929 5.964 12.823 1.132.413 2.29.609 3.428.609 4.081 0 7.914-2.518 9.395-6.574l7.46-20.431h4.698c15.188 0 27.5-12.312 27.5-27.5 0-10.08-5.427-18.889-13.515-23.677l20.654-56.576c1.894-5.188-.776-10.929-5.964-12.823z"></path>
                                </g>
                              </svg>
                            </span>
                          </span>
                          <p className="pl-1">
                            <span
                              className={`${
                                event?.dependency?.some(
                                  (item) =>
                                    item.status === "Completed" ||
                                    item.status === "Overdue"
                                )
                                  ? "text-green-700"
                                  : "text-[#000000]"
                              } text-sm font-bold`}
                            >
                              {
                                event?.dependency?.filter(
                                  (item) =>
                                    item.status === "Completed" ||
                                    item.status === "Overdue"
                                ).length
                              }
                            </span>
                            /
                            <span className="text-sm">
                              {event?.dependency
                                ? event?.dependency?.length
                                : 0}
                            </span>
                          </p>
                        </div>
                      )}

                      {loader3 === true &&
                      event.title === selectedEvent.title ? (
                        <div className="w-full gap-2  flex rounded-md bg-gray-300 animate-pulse text-gray-300 px-2 py-1">
                          <div className="opacity-0  min-w-[40px]">P</div>
                        </div>
                      ) : (
                        <div className="flex gap-2 w-full items-center justify-start  whitespace-nowrap border px-2 py-1 rounded-md">
                          <select
                            value={event.priority || "P0"}
                            onChange={(e) => {
                              setSelectedEvent(event);
                              handleEvent(
                                event.title,
                                event.event_date,
                                "Priority",
                                e.target.value
                              );
                            }}
                            className="w-full whitespace-nowrap cursor-pointer  min-w-[40px] outline-none"
                          >
                            <option>P0</option>
                            <option>P1</option>
                            <option>P2</option>
                            <option>P3</option>
                            <option>P4</option>
                            <option>P5</option>
                          </select>
                        </div>
                      )}

                      <div className="">
                        {event.status === "Pending" ? (
                          <>
                            <img
                              src="/images/hourglass.svg"
                              className="min-w-6 max-w-6"
                              alt=""
                            />
                          </>
                        ) : event.status === "Defective" ? (
                          <>
                            <img
                              src="/images/radioactive.svg"
                              className="min-w-6 max-w-6"
                              alt=""
                            />
                          </>
                        ) : event.status === "Completed" ? (
                          <>
                            <img
                              src="/images/checklist.svg"
                              className="min-w-6 max-w-6"
                              alt=""
                            />
                          </>
                        ) : event.status === "In Progress" ? (
                          <>
                            <img
                              src="/images/processing-time.svg"
                              className="min-w-6 max-w-6"
                              alt=""
                            />
                          </>
                        ) : event.status === "Overdue" ? (
                          <>
                            <img
                              src="/images/coorperation.svg"
                              className="min-w-6 max-w-6"
                              alt=""
                            />
                          </>
                        ) : event.status === "In Research" ? (
                          <>
                            <img
                              src="/images/magnifying-glass.svg"
                              className="min-w-6 max-w-6"
                              alt=""
                            />
                          </>
                        ) : event.status === "For Review" ? (
                          <>
                            <img
                              src="/images/send.svg"
                              className="min-w-6 max-w-6"
                              alt=""
                            />
                          </>
                        ) : event.status === "Postponed" ? (
                          <>
                            <img
                              src="/images/time.svg"
                              className="min-w-6 max-w-6"
                              alt=""
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src="/images/cancel.svg"
                              className="min-w-6 max-w-6 opacity-0.5 filter"
                              alt=""
                            />
                          </>
                        )}
                      </div>

                      <div className="flex items-center justify-center cursor-pointer bg-red-100 p-0.5 rounded-md">
                        <span
                          onClick={() => {
                            sendDeleteOtp(event.title);
                            setSelectedEvent(event);
                            setTimer(90);
                            handleTimer();
                          }}
                        >
                          <svg
                            className="w-[22px] h-[22px]"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            id="fi_6460112"
                          >
                            <g id="Flat_Color" data-name="Flat Color">
                              <path
                                d="m15 4h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2z"
                                fill="#e63946"
                              ></path>
                              <path
                                d="m15 22h-6a4 4 0 0 1 -4-4v-12h14v12a4 4 0 0 1 -4 4z"
                                fill="#e63946"
                              ></path>
                              <path
                                d="m20 8h-16a1 1 0 0 1 0-2h16a1 1 0 0 1 0 2z"
                                fill="#c9273a"
                              ></path>
                              <g fill="#edebea">
                                <path d="m10 18a1 1 0 0 1 -1-1v-6a1 1 0 0 1 2 0v6a1 1 0 0 1 -1 1z"></path>
                                <path d="m14 18a1 1 0 0 1 -1-1v-6a1 1 0 0 1 2 0v6a1 1 0 0 1 -1 1z"></path>
                              </g>
                            </g>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )
        )}
      </div>

      {/* Side bar option */}
      <div
        className={`md:hidden absolute -left-6 -top-8 h-screen z-[5] mt-2 w-full bg-white border border-gray-200 rounded-md  max-w-[260px] transition-transform duration-500 ease-in-out ${
          isFilterOpen1 ? "translate-x-0 shadow-lg" : "-translate-x-full"
        }`}
      >
        <div className="p-4 space-y-3 z-20">
          <div className="flex items-center justify-between">
            <p className="text-[15px] font-bold text-black">Filter</p>

            <div
              onClick={() => setIsFilterOpen1(false)}
              className=" flex justify-end p-0"
            >
              <button
                className="circleadmin"
                data-animation="showShadow"
                data-remove="3000"
              ></button>
            </div>
          </div>
          <hr />
          {/* Assignee Dropdown */}
          <div>
            <label className="block text-sm font-semibold text-gray-800">
              Rating
            </label>
            <select
              value={rating}
              onChange={(e) => {
                setRating(e.target.value);
                if (e.target.value) {
                  setIsFilterOpen(false);
                }
                setCurrentPage(1);
              }}
              className="w-full mt-1 px-1.5 text-sm py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="All">All</option>
              {allRating.map((a) => (
                <option key={a} value={a}>
                  {a}
                </option>
              ))}
            </select>
          </div>

          {/* Status Dropdown */}
          <div>
            <label className="block text-sm font-semibold text-gray-800">
              Status
            </label>
            <select
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
                if (e.target.value) {
                  setIsFilterOpen1(false);
                }
                setCurrentPage(1);
              }}
              className="w-full mt-1 px-1.5 text-sm py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="All">All</option>
              {allStatus.map((a) => (
                <option key={a} value={a}>
                  {a}
                </option>
              ))}
            </select>
          </div>

          {/* Priority Dropdown */}
          <div>
            <label className="block text-sm font-semibold text-gray-800">
              Priority
            </label>
            <select
              value={priority}
              onChange={(e) => {
                setPriority(e.target.value);
                if (e.target.value) {
                  setIsFilterOpen(false);
                }
                setCurrentPage(1);
              }}
              className="w-full mt-1 px-1.5 text-sm py-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="All">All</option>
              {allPriority
                .sort((a, b) => {
                  const numA = parseInt(a.substring(1), 10);
                  const numB = parseInt(b.substring(1), 10);
                  return numA - numB;
                })
                .map((a) => (
                  <option key={a} value={a}>
                    {a}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
      {show1 && (
        <div className="demo1-container ">
          <div className="bg-white w-[85%] md:w-[50%] xl:w-[30%] p-3 rounded-xl pop-up-animation">
            <div className="d-flex justify-content-end align-items-center">
              <button
                className="circle fromMiddle scale-90 left-2"
                data-animation="magnify"
                data-remove="3000"
                onClick={() => {
                  setShow1(false);
                  setUserOtp("");
                }}
              >
                <span></span>
              </button>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center p-3">
              <MdSecurityUpdateGood
                size={100}
                color="#fff"
                className="otp-icon"
              />
              <h6 className="text-center text-sm md:text-base mt-4 font-semibold text-[#000000]">
                Are you sure you want to permanently delete this task{" "}
                <span className="font-bold text-blue-500">
                  {`${selectedEvent?.title.slice(0, 50)}...`}
                </span>{" "}
                dated{" "}
                <span className="font-bold text-blue-500">
                  {formattedDate(selectedEvent?.event_date)}
                </span>
              </h6>
              <h6 className="text-sm text-center font-medium pb-2 mt-2">
                A code has been sent to xxxxxx{phone?.slice(-4)}
              </h6>
              <div
                id="otp"
                className="inputs d-flex flex-row justify-content-center mt-2 "
              >
                <input
                  className=" text-center border-2 rounded p-1.5"
                  type="number"
                  placeholder="Enter OTP"
                  value={userOtp}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setUserOtp(value);
                    }
                  }}
                />
              </div>
              <div className="mt-4 flex items-center gap-4">
                {loader2 ? (
                  <button className="btn btn-sm btn-primary w-[110px] flex justify-center items-center">
                    <img
                      key="1"
                      className="h-4 remove-white-bg"
                      src="/images/btn-transparent.gif"
                      alt={""}
                      autoPlay
                      loop
                      muted
                    />
                  </button>
                ) : (
                  <button
                    className="btn btn-sm btn-primary w-[110px]"
                    onClick={() =>
                      handleDeleteEvent(
                        selectedEvent?.title,
                        selectedEvent?.event_date
                      )
                    }
                  >
                    Verify OTP
                  </button>
                )}

                {timer === 0 ? (
                  <button
                    className="  btn-sm rounded border w-[110px]"
                    onClick={() => {
                      sendDeleteOtp(selectedEvent?.title);
                      setTimer(90);
                      handleTimer();
                    }}
                  >
                    Resend OTP
                  </button>
                ) : (
                  <button className="btn-sm rounded border w-[110px]">
                    <span className="text-xs">{formatTime(timer)}</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* popup start */}
      <div
        className={`bg-white h-full absolute  overflow-y-hidden top-0 right-0 transition-all duration-1000 ease-in-out rounded-lg w-[100%] border-2 ${
          showPopup ? "translate-x-[0%]" : "translate-x-[105%]"
        }`}
      >
        <TaskSpecks
          adminCalender={adminCalender}
          onReload={(val) => {
            onReload(val);
          }}
          filter={filter}
          employee={emp}
          selectedEvent={selectedEvent}
          setSelectedEvent={(val) => setSelectedEvent(val)}
          closePopup2={(i) => {
            if (i === 9) {
              setShowModal(9);
              setTimeout(() => {
                setShowModal(-1);
              }, 3000);
            }
            setShowPopup(false);
          }}
          status={status}
        />
      </div>
      {/* popup end */}

      {showModal === 1 ? (
        <AlertYellow
          message="Incorrect OTP!"
          onClose={() => setShowModal(-1)}
        />
      ) : showModal === 2 ? (
        <AlertGreen
          message="OTP is verified."
          onClose={() => setShowModal(-1)}
        />
      ) : showModal === 3 ? (
        <AlertYellow
          message="Mobile number must be of 10 digits"
          onClose={() => setShowModal(-1)}
        />
      ) : showModal === 4 ? (
        <AlertRed
          message="Mobile number provided is not Registered"
          onClose={() => setShowModal(-1)}
        />
      ) : (
        showModal === 9 && (
          <AlertGreen
            message="Task edited successfully."
            onClose={() => setShowModal(-1)}
          />
        )
      )}
    </div>
  );
}

export default EventScheduler;
