import React, { useEffect, useRef } from "react";
import { QRCodeSVG } from 'qrcode.react';
import "./MarketAnimation.css";
import AssetChart from "./AssetChart.jsx";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function MarketAnimation() {
  const decentralisedRef = useRef(null);
  const waveRef1 = useRef(null);
  const waveRef2 = useRef(null);
  const waveRef3 = useRef(null);
  const servedMarketRef = useRef(null);
  const stepRefs = useRef([]);
  const message = "Our state-of-the-art App will be launched on January 31st, 2026. Please use the fully operational website for all transactions.";

  useEffect(() => {
    [waveRef1, waveRef2, waveRef3].forEach((waveRef, index) => {
      gsap.fromTo(
        waveRef.current,
        { scale: 0, opacity: 1 },
        {
          scale: 4,
          opacity: 0,
          duration: 5,
          repeat: -1,
          delay: index * 1.5,
          ease: "power1.out",
        }
      );
    });

    stepRefs.current.forEach((step, index) => {
      gsap.fromTo(
        step,
        { opacity: 0, x: 200 },
        {
          opacity: 1,
          x: 0,
          duration: 1,
          ease: "power2.out",
          scrollTrigger: {
            trigger: step,
            start: "top 80%",
            end: "top 50%",
            scrub: 1,
            markers: false,
            toggleActions: "play none none reverse",
          },
        }
      );
    });

    let obj = { val: `0` };
    gsap.to(obj, {
      val: 25,
      duration: 2,
      ease: "none",
      scrollTrigger: {
        trigger: servedMarketRef.current,
        start: "top 80%",
        toggleActions: "play none none none",
      },
      onUpdate: function () {
        if (servedMarketRef.current) {
          servedMarketRef.current.textContent = `${Math.round(obj.val)}%`;
        }
      },
      onComplete: () => {
        if (servedMarketRef.current) {
          servedMarketRef.current.textContent = "25% +";
        }
      },
    });
  }, []);
  

  return (
    <div className="w-full flex justify-center ">
      <div className="h-max mt-10  lg:mt-20 bg-white w-[95%] md:w-[90%] overflow-hidden">
        <div
          className="header-title flex flex-col  text-center   xl:text-left leading-tight  font-semibold tracking-wide text-gray-800 p-2   mb-5 "
          ref={(el) => (stepRefs.current[0] = el)}
        >
          <div className="font-bold  mt-1 mx-0 text-base  md:text-xl lg:text-2xl xl:text-3xl 2xl:text-text-[34px]">
            
            <span className="text-sky-400">Disrupting asset management</span>
          </div>
          <div className="font-bold  mt-1 mx-0 text-base  md:text-xl lg:text-2xl xl:text-3xl 2xl:text-text-[34px]">
            in a growing
            economy
          </div>
        </div>

        <div className="flex items-center justify-center max-lg:flex-col-reverse gap-x-20" ref={(el) => (stepRefs.current[1] = el)}>
<div className="flex flex-col items-center max-lg:-top-11 relative">
{/* <p className="text-[10px] md:text-xs text-[#212121] font-semibold mb-2">Download Now!</p> */}
  <div className="flex max-lg:flex-row lg:flex-col items-center">
<div  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
      <QRCodeSVG
        value={message}  // The value to encode in the QR code
        
        bgColor="#ffffff" // Background color
        fgColor="#000000" // Foreground color (QR code color)
        level="L"        // Error correction level
        style={{ border: '1px solid #000' }} // Optional styling
        className="w-16 h-16 lg:w-24 lg:h-24 p-1 rounded-lg max-lg:mr-4"
      />
    </div>
<div class="flex justify-center items-center flex-col ">
  {/* <p className="text-[10px] md:text-xs text-[#313131] font-semibold">Download Now!</p> */}
  <div className="flex max-lg:flex-col max-lg:gap-2 lg:gap-3 ">
  <button class="cursor-pointer">
    <div
      class="flex max-w-48   rounded-xl items-center justify-center  text-white dark:text-black dark:bg-white lg::h-14"
    >
      <svg viewBox="30 336.7 120.9 129.2" class="w-5 lg:w-7">
        <path
          d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z"
          fill="#FFD400"
        ></path>
        <path
          d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z"
          fill="#FF3333"
        ></path>
        <path
          d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z"
          fill="#48FF48"
        ></path>
        <path
          d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z"
          fill="#3BCCFF"
        ></path>
      </svg>
      {/* <div>
        <div class="text-[.5rem] sm:text-xs text-left">GET IT ON</div>
        <div class="text-sm font-semibold font-sans -mt-1 sm:text-xl">
          Google Play
        </div>
      </div> */}
    </div>
  </button>

  <button class="cursor-pointer">
    <div
      class="flex max-w-48  rounded-xl items-center justify-center  text-white dark:text-black dark:bg-white lg:h-14"
    >
      <svg viewBox="0 0 384 512" class="w-5 lg:w-7">
        <path
          d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
          fill="#000000"
        ></path>
      </svg>
      {/* <div>
        <div class="text-[.5rem] sm:text-xs text-left">Download on the</div>
        <div class="text-lg font-semibold font-sans -mt-1 sm:text-2xl">
          App Store
        </div>
      </div> */}
    </div>
  </button></div>
</div></div>
</div>
  <div><AssetChart /></div>
          
          
        </div>
      </div>
    </div>
  );
}

export default MarketAnimation;
