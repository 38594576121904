import React, { useState, useEffect } from "react";
import "./admin.css";
import { MdCloudUpload } from "react-icons/md";
import AlertRed from "../components/Alerts/AlertRed.jsx";
import AlertGreen from "../components/Alerts/AlertGreen.jsx";
import { FaDownload } from "react-icons/fa6";
import AdminInfoApp from "../API/AdminApi.js";
import EmployeeInfoApp from "../API/Employee.js";
import CheckAuth from "../components/ServiceFunctions/CheckAuth.jsx";

function DatabaseOperations() {
  const { email } = CheckAuth();
  const user = [...AdminInfoApp, ...EmployeeInfoApp].find(
    (user) => user?.email?.toLowerCase() === email?.toLowerCase()
  );
  const admin = AdminInfoApp.some(
    (admin) => admin?.email?.toLowerCase() === email?.toLowerCase()
  );
  const name = user ? user.name : null;
  const dur = `${new Date().toLocaleString("en-US", {
    month: "short",
  })} ${new Date().getFullYear()}`;
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [specificResults, setSpecificResults] = useState([]);
  const [isSpecificLoading, setIsSpecificLoading] = useState(false);
  const [filename, setFilename] = useState();
  const [disable, setDisable] = useState(true);
  const [message, setMessage] = useState("");

  const formatDate = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  useEffect(() => {
    fetch(`https://api-scdndestates.com/api/get-event-and-reminders/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ employee: name, filter: dur }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to save event/reminder");
        }
        return response.json();
      })
      .then((response) => {
        if (response.dates?.length > 0) {
          let couponDates = [];
          response.dates?.forEach((event) => {
            if (event.title === "Next Coupon Payment Dates") {
              couponDates = event.dates;
            }
          });
          const today = new Date();
          // const today = "2025-03-27";
          couponDates.some((date) => {
            const couponDate = new Date(date);
            couponDate.setDate(couponDate.getDate() - 1);
            if (formatDate(couponDate) === formatDate(today)) {
              setDisable(false);
            }
          });
        }
      })
      .catch((error) => {
        console.error("Error during logout:", error);
      });
  }, [disable]);

  const executeFunctions = async () => {
    setIsLoading(true);

    const response = await fetch(
      `https://api-scdndestates.com/api/execute_database_functions/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    const functionNames = Object.keys(data);

    for (let functionName of functionNames) {
      const status = data[functionName];
      setResults((prevResults) => [...prevResults, { functionName, status }]);
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    setIsLoading(false);
  };

  const handleDeleteFiles = () => {
    fetch(`https://api-scdndestates.com/api/delete-4-excel-together/`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        setMessage(JSON.stringify(data));

        setTimeout(() => {
          setMessage("");
        }, 5000);
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  const executeSpecificFunctions = async () => {
    setIsSpecificLoading(true);

    const response = await fetch(
      `https://api-scdndestates.com/api/quarterend_functions/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    const functionNames = Object.keys(data);

    for (let functionName of functionNames) {
      const status = data[functionName];
      setSpecificResults((prevResults) => [
        ...prevResults,
        { functionName, status },
      ]);
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    setIsSpecificLoading(false);
  };

  const Download_Excel = (excelname) => {
    fetch(`https://api-scdndestates.com/api/download-excel/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        excel: excelname,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to download the file");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${excelname}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error("Error during file download:", error);
      });
  };

  const Upload_Excel = (excelname, file) => {
    const formData = new FormData();
    formData.append("excel", excelname);
    formData.append("file", file);

    fetch(`https://api-scdndestates.com/api/upload-excel/`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to upload the file");
        }
        return response.json();
      })
      .then((response) => {
        setMessage(response.message);

        setTimeout(() => {
          setMessage("");
        }, 5000);
        if (excelname === "UserPoolAccount") {
          setDisable(false);
        }
      })
      .catch((error) => {
        console.error("Error during file upload:", error);
      });
  };

  return (
    <>
      <div className="text-black">
        {/* Day end program */}
        <div className="px-3 py-4 border relative mt-2 bg-light rounded-lg bg-white shadow-sm">
          <h2 className="absolute -top-3 bg-white border rounded-lg px-2 py-2 font-bold text-black">
            <marquee behavior="" direction="">
              Day-end Program
            </marquee>
          </h2>

          <div className="my-4 max-md:flex-col md:flex items-center justify-between">
            <span className="font-semibold text-base xl:text-lg flex items-center gap-2">
              <img
                src="/images/add-money.svg"
                className="w-8 p-1 border rounded-md"
                alt=""
              />
              Wallet withdraw-Adhoc Bulkpay
            </span>
            <div className="flex justify-end gap-5 max-md:mt-2">
              <button
                className="button-excel w-[130px] md:w-[120px] xl:w-[160px] animationIcon-shine-blue h-[32px] md:h-[38px]"
                onClick={() => Download_Excel("DebitMoney")}
              >
                <i className="text-base md:text-lg">
                  <FaDownload className="icon" />
                </i>
                <p className="font-semibold text-sm md:text-base">Download</p>
              </button>
            </div>
          </div>

          <div className="my-4 max-md:flex-col md:flex items-center justify-between">
            <span className="font-semibold text-base xl:text-lg flex items-center gap-2">
              <img
                src="/images/axisBank.webp"
                className="w-8 h-8 p-1 border rounded-md remove-white-bg"
                alt=""
              />
              E-collect Credit plus Debit
            </span>
            <div className="text-base max-md:mt-2 xl:text-lg flex justify-end gap-3">
              <span className="rounded-lg">
                <input
                  type="file"
                  onChange={(e) => setFilename(e.target.files[0])}
                  className="flex h-[32px] md:h-[38px] max-md:w-full w-[220px] xl:w-full rounded-md border border-input bg-white px-3 py-1.5 text-sm text-gray-400 file:border-0 file:bg-transparent file:text-gray-600 file:text-sm file:font-medium"
                />
              </span>
              <button
                className="button-excel w-[235px] md:w-[120px] xl:w-[160px] animationIcon-shine-blue"
                onClick={() => Upload_Excel("CreditMoney", filename)}
              >
                <i className="text-lg md:text-xl">
                  <MdCloudUpload className="icon" />
                </i>
                <p className="font-semibold text-sm md:text-base">Upload</p>
              </button>
            </div>
          </div>

          <div className="mt-4 max-md:flex-col md:flex items-center justify-between">
            <span className="font-semibold text-base xl:text-lg flex items-center gap-2">
              <img
                src="/images/mongodb.svg"
                className="w-8 p-1 border rounded-md"
                alt=""
              />
              MongoDB Updation
            </span>
            <div className="flex justify-end">
              <button
                className={`${
                  isLoading ? "disabled-button" : "start-button"
                } p-2.5 text-xs xl:text-[13px] rounded-lg font-semibold border  max-md:mt-2 md:w-[120px] xl:w-[160px]`}
                type="button"
                onClick={executeFunctions}
                disabled={isLoading}
              >
                {isLoading ? "Executing..." : "Day-end Programs"}
              </button>
            </div>
          </div>

          <div className="overflow-x-scroll">
            <div className="w-fit h-fit flex gap-2 justify-start items-start">
              {results.length > 0 &&
                results.map(({ functionName, status }, index) => (
                  <div className="relative flex-col items-start">
                    {!status ? (
                      <>
                        <div className="flex flex-col justify-start items-center  w-[210px] h-[90px] rounded-lg border-2 mb-2 p-1 pt-2 break-all text-center">
                          <svg
                            width="30px"
                            height="30px"
                            viewBox="-5 -5 93 93"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <circle
                                id="oval"
                                stroke="#ff0000"
                                strokeWidth="9"
                                fill="transparent"
                                cx="41.5"
                                cy="41.5"
                                r="41.5"
                              ></circle>
                              <line
                                id="line1"
                                x1="20"
                                y1="20"
                                x2="63"
                                y2="63"
                                stroke="#ff0000"
                                strokeWidth="9"
                              ></line>
                              <line
                                id="line2"
                                x1="20"
                                y1="63"
                                x2="63"
                                y2="20"
                                stroke="#ff0000"
                                strokeWidth="9"
                              ></line>
                            </g>
                          </svg>
                          <span className="mx-2 text-sm text-[#8c8c8c] ">
                            {functionName}
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex flex-col justify-start items-center  w-[210px] h-[90px] rounded-lg border-2 mb-2 p-1 pt-2 break-all text-center">
                          <svg
                            width="30px"
                            height="30px"
                            viewBox="-5 -5 93 93"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <circle
                                id="oval"
                                stroke="#43ac6a"
                                strokeWidth="9"
                                fill="transparent"
                                cx="41.5"
                                cy="41.5"
                                r="41.5"
                              ></circle>
                              <polygon
                                id="tick"
                                fill="#43ac6a"
                                points="66 29.01 35.03 59.97 16 40.94 22.92 34.02 35.03 46.13 59.08 22.09"
                              ></polygon>
                            </g>
                          </svg>
                          <span className="mx-2 text-sm text-[#8c8c8c] ">
                            {functionName}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                ))}
            </div>
          </div>

          <div className="my-4 max-md:flex-col md:flex items-center justify-between">
            <span className="font-semibold text-base xl:text-lg flex items-center gap-2">
              <img
                src="/images/axisBank.webp"
                className="w-8 p-1 border rounded-md"
                alt=""
              />
              Failure refund-Adhoc Bulkpay
            </span>
            <div className="text-base flex justify-end gap-5">
              <button
                className="button-excel w-[140px] md:w-[120px] xl:w-[160px] animationIcon-shine-blue h-[32px] md:h-[38px]"
                onClick={() => Download_Excel("CreditMoneyRejected")}
              >
                <i className="text-base md:text-lg">
                  <FaDownload className="icon" />
                </i>
                <p className="font-semibold text-sm md:text-base">Download</p>
              </button>
            </div>
          </div>
        </div>

        {/* Quarter end program */}
        <div className="px-3 py-4 border relative mt-4 bg-light rounded-lg bg-white shadow-sm">
          <h2 className="absolute -top-4 bg-white border rounded-lg px-2 py-2 font-bold text-black">
            <marquee behavior="" direction="">
              Quarter-end Program
            </marquee>
          </h2>

          <div className="mt-4 max-md:flex-col md:flex items-center justify-between">
            <span className="font-semibold text-base xl:text-lg flex items-center gap-2">
              <img
                src="/images/mongodb.svg"
                className="w-8 p-1 border rounded-md"
                alt=""
              />
              MongoDB Updation
            </span>
            <div className="flex justify-end">
              <button
                type="button"
                onClick={executeSpecificFunctions}
                className={`${
                  disable || isSpecificLoading
                    ? "disabled-button cursor-not-allowed"
                    : "start-button cursor-pointer"
                } p-2.5 text-xs xl:text-[13px] rounded-lg font-semibold border  max-md:mt-2 md-[140px] md:w-[120px] xl:w-[160px]`}
                disabled={disable || isSpecificLoading}
              >
                {isSpecificLoading ? "Executing..." : "Quarter-end Program"}
              </button>
            </div>
          </div>
          <div className="overflow-x-scroll">
            <div className="w-fit h-fit flex gap-2 justify-start items-start">
              {specificResults.length > 0 &&
                specificResults.map(({ functionName, status }, index) => (
                  <div className="relative flex-col items-start">
                    {!status ? (
                      <>
                        <div className="flex flex-col justify-start items-center  w-[210px] h-[90px] rounded-lg border-2 mb-2 p-1 pt-2 break-all text-center">
                          <svg
                            width="30px"
                            height="30px"
                            viewBox="-5 -5 93 93"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <circle
                                id="oval"
                                stroke="#ff0000"
                                strokeWidth="9"
                                fill="transparent"
                                cx="41.5"
                                cy="41.5"
                                r="41.5"
                              ></circle>
                              <line
                                id="line1"
                                x1="20"
                                y1="20"
                                x2="63"
                                y2="63"
                                stroke="#ff0000"
                                strokeWidth="9"
                              ></line>
                              <line
                                id="line2"
                                x1="20"
                                y1="63"
                                x2="63"
                                y2="20"
                                stroke="#ff0000"
                                strokeWidth="9"
                              ></line>
                            </g>
                          </svg>
                          <span className="mx-2 text-sm text-[#8c8c8c] ">
                            {functionName}
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex flex-col justify-start items-center  w-[210px] h-[90px] rounded-lg border-2 mb-2 p-1 pt-2 break-all text-center">
                          <svg
                            width="30px"
                            height="30px"
                            viewBox="-5 -5 93 93"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <circle
                                id="oval"
                                stroke="#43ac6a"
                                strokeWidth="9"
                                fill="transparent"
                                cx="41.5"
                                cy="41.5"
                                r="41.5"
                              ></circle>
                              <polygon
                                id="tick"
                                fill="#43ac6a"
                                points="66 29.01 35.03 59.97 16 40.94 22.92 34.02 35.03 46.13 59.08 22.09"
                              ></polygon>
                            </g>
                          </svg>
                          <span className="mx-2 text-sm text-[#8c8c8c] ">
                            {functionName}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                ))}
            </div>
          </div>

          <div className="my-4 max-md:flex-col md:flex items-center justify-between">
            <span className="font-semibold text-base xl:text-lg flex items-center gap-2">
              <img
                src="/images/icici-bank.svg"
                className="w-8 p-1 border rounded-md"
                alt=""
              />
              WALR Coupons (ICICI Bank)
            </span>
            <div className="text-base xl:text-lg flex justify-end gap-5">
              <button
                className="button-excel w-[140px] md:w-[120px] xl:w-[160px] animationIcon-shine-blue h-[32px] md:h-[38px]"
                onClick={() => Download_Excel("ExcelF")}
              >
                <i className="text-base md:text-lg">
                  <FaDownload className="icon" />
                </i>
                <p className="font-semibold text-sm md:text-base">Download</p>
              </button>
            </div>
          </div>

          <div className="my-4 max-md:flex-col md:flex items-center justify-between">
            <span className="font-semibold text-base xl:text-lg flex items-center gap-2">
              <img
                src="/images/bob-bank.svg"
                className="w-8 p-1 border rounded-md"
                alt=""
              />
              RESIDEX Coupons (BOB)
            </span>

            <div className="text-base xl:text-lg flex justify-end gap-5">
              <button
                className="button-excel w-[140px] md:w-[120px] xl:w-[160px] animationIcon-shine-blue h-[32px] md:h-[38px]"
                onClick={() => Download_Excel("ExcelG")}
              >
                <i className="text-base md:text-lg">
                  <FaDownload className="icon" />
                </i>
                <p className="font-semibold text-sm md:text-base">Download</p>
              </button>
            </div>
          </div>

          <div className="my-4 max-md:flex-col md:flex items-center justify-between">
            <span className="font-semibold text-base xl:text-lg flex items-center gap-2">
              <img
                src="/images/excel.svg"
                className="w-8 p-1 border rounded-md"
                alt=""
              />
              Excel A, B1, F and G
            </span>
            <div className="flex justify-end">
              <button
                className="noselect max-md:mt-2 delete-button h-[33px] xl:h-[38px] w-[140px] md:w-[120px] xl:w-[160px]"
                onClick={handleDeleteFiles}
              >
                <span className="text">Delete</span>
                <span className="icon hover:w-[60px] xl:hover:w-[80px] w-[40px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-[20px] h-[12px] xl:w-[24px] xl:h-[20px]"
                    viewBox="0 0 24 24"
                  >
                    <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Modal for showing alert */}
      {message !== "" && (
        <>
          {message === "File uploaded successfully" ? (
            <AlertGreen
              message={message}
              onClose={() => {
                setMessage("");
              }}
            />
          ) : (
            <AlertRed
              message={message}
              onClose={() => {
                setMessage("");
              }}
            />
          )}
        </>
      )}
    </>
  );
}

export default DatabaseOperations;
