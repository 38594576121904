import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header.jsx";
import Welcome from "../../components/Welcome/Welcome.jsx";
import CircularNavigation from "./CircularNavigation";
import ProgressiveDisclosureTable from "../../components/WhatIs/ProgressiveDisclosureTable.jsx";
import ContactUs from "../../components/ContactUs/ContactUs.jsx";
import MarketAnimation from "../Home/MarketAnimation.jsx";
import { Helmet } from "react-helmet";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>SCDND Estates</title>
        <meta
          name="description"
          content="We create and market-make decentralized versions of Mutual Funds (or synthetic ETFs/REITs)."
        />
        <meta
          name="description"
          content="Unit NAVs and quarterly Coupons directly linked to public financial indices or real economic survey-data."
        />
        <meta
          name="description"
          content="Client funds are protected by verifiable liquid collateral held in Escrow, valuation changes reported in real-time."
        />
        <meta
          name="description"
          content="Ownership records stored securely & immutably, Peer-2-Peer transactions processed instantly & cheaply."
        />

        <link rel="canonical" href="https://scdndestates.com/" />

        {/* Open Graph */}
        <meta property="og:title" content="SCDND Estates" />
        <meta
          property="og:site_name"
          content="scdndestates - Online Stock and Share Trading"
        ></meta>
        <meta
          property="og:description"
          content="We create and market-make decentralized versions of Mutual Funds (or synthetic ETFs/REITs)."
        />
        <meta property="og:url" content="https://scdndestates.com" />
        <meta property="og:type" content="website" />
        {/* Twitter Graph */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="SCDND Estates" />
        <meta name="twitter:domain" content="scdndestates.com" />
        <meta
          name="twitter:description"
          content="We create and market-make decentralized versions of Mutual Funds (or synthetic ETFs/REITs)."
        />
      </Helmet>

      <Header />
      <Welcome />
      <div className="" style={{ position: "relative" }}>
        <CircularNavigation />
        <ContactUs />
        <MarketAnimation />
        <ProgressiveDisclosureTable />
      </div>
    </>
  );
}

export default Home;
