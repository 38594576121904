import React, { useState, useEffect, useRef } from "react";
import secureLocalStorage from "react-secure-storage";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import AdminInfoApp from "../../API/AdminApi";
import EmployeeInfoApp from "../../API/Employee";

function SchedulePopup({ onReload, onClose }) {
  const searchRef = useRef();
  let eventFormData = JSON.parse(secureLocalStorage.getItem("eventFormData"));
  const { email, phone } = CheckAuth();
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [tags, setTags] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [formData, setFormData] = useState(
    eventFormData !== null
      ? eventFormData
      : {
          title: "",
          date: new Date().toISOString().slice(0, 10),
          deputed: "",
          description: "",
          duration: "",
          budget: "",
        }
  );
  // Update your state to include a new array for selected assignees
  const [selectedAssignees, setSelectedAssignees] = useState(
    eventFormData?.deputed ? eventFormData.deputed.split(", ") : []
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    // Attach the event listener
    document.addEventListener("click", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleSelectTitle = (title, Date) => {
    if (!selectedTitles.some((item) => item.title === title)) {
      setSelectedTitles([...selectedTitles, { title, Date }]);
    }
    setQuery(""); // Clear search input
    setResults([]); // Hide search results after selection
  };

  const handleRemoveTag = (title, date) => {
    setSelectedTitles(
      selectedTitles.filter((t) => t.title !== title || t.date !== date)
    );
  };

  const handleAddTag = (selectedTag = null) => {
    const newTags = selectedTag
      ? [selectedTag]
      : query
          .split(",")
          .map((tag) => tag.trim())
          .filter((tag) => tag !== "");

    if (newTags.length > 0) {
      setTags([...tags, ...newTags]);
      setResults([]);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (query.length >= 10) {
        handleAddTag();
      }
    }
  };

  useEffect(() => {
    if (query.length > 3) {
      const fetchResults = async () => {
        try {
          const response = await fetch(
            "https://api-scdndestates.com/api/admin_search_function/",
            {
              method: "POST", // Use POST method
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ query }), // Send the query in the body
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const data = await response.json();
          setResults(data);
        } catch (error) {
          console.error("Error fetching search results:", error);
        }
      };
      const delayDebounceFn = setTimeout(() => {
        fetchResults();
      }, 300); // debounce by 300ms

      return () => clearTimeout(delayDebounceFn);
    } else {
      setResults([]);
    }
  }, [query]);

  // Modified validateForm function
  const validateForm = () => {
    const { title, date, description, duration } = formData;

    if (
      !title ||
      !date ||
      (formData.deputed === "" && selectedAssignees.length === 0) ||
      !description ||
      !duration
    ) {
      alert("All fields are required.");
      setLoader(false);
      return false;
    }
    return true;
  };

  // Modified input change handler
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // For deputed field, only update the input value, not the formData.deputed yet
    if (name === "deputed") {
      setFormData({
        ...formData,
        [name]: value,
      });
    } else {
      // For other fields, update as normal
      setFormData({
        ...formData,
        [name]: value,
      });

      // Save to local storage
      const updatedFormData = {
        ...formData,
        [name]: value,
      };
      secureLocalStorage.setItem(
        "eventFormData",
        JSON.stringify(updatedFormData)
      );
      eventFormData = JSON.parse(secureLocalStorage.getItem("eventFormData"));
    }
  };

  // New function to handle assignee selection
  const handleAssigneeSelect = (assignee) => {
    // Only allow up to 2 assignees
    if (selectedAssignees.length < 2) {
      const newSelectedAssignees = [...selectedAssignees, assignee];
      setSelectedAssignees(newSelectedAssignees);

      // Update the formData.deputed with comma-separated string
      const deputedString = newSelectedAssignees.join(", ");
      setFormData({
        ...formData,
        deputed: "", // Clear the input field
      });

      // Update formData in localStorage with the deputedString
      const updatedFormData = {
        ...formData,
        deputed: deputedString, // Store the comma-separated string
      };
      secureLocalStorage.setItem(
        "eventFormData",
        JSON.stringify(updatedFormData)
      );
      eventFormData = JSON.parse(secureLocalStorage.getItem("eventFormData"));

      setShow(false);
    }
  };

  // Function to remove an assignee
  const removeAssignee = (index) => {
    const newSelectedAssignees = selectedAssignees.filter(
      (_, i) => i !== index
    );
    setSelectedAssignees(newSelectedAssignees);

    // Update the formData.deputed with comma-separated string
    const deputedString = newSelectedAssignees.join(", ");
    setFormData({
      ...formData,
      deputed: deputedString,
    });

    // Update formData in localStorage
    const updatedFormData = {
      ...formData,
      deputed: deputedString,
    };
    secureLocalStorage.setItem(
      "eventFormData",
      JSON.stringify(updatedFormData)
    );
    eventFormData = JSON.parse(secureLocalStorage.getItem("eventFormData"));
  };

  // Modified file change handler for immediate upload
  const handleFileChange = async (event) => {
    const newFiles = Array.from(event.target.files);

    // Add new files to state with loading status
    const filesWithStatus = newFiles.map((file) => ({
      file,
      name: file.name,
      id: Math.random().toString(36).substring(2, 9), // Generate unique ID
      isUploading: true,
      error: null,
      uploaded: false,
    }));

    // Add files to state first
    setUploadedFiles((prev) => [...prev, ...filesWithStatus]);

    // Upload each file individually for immediate feedback
    filesWithStatus.forEach((fileInfo) => {
      uploadSingleFile(fileInfo);
    });
  };

  // Function to handle individual file uploads
  const uploadSingleFile = async (fileInfo) => {
    try {
      // Create a new FormData instance for this file
      const singleFileFormData = new FormData();
      singleFileFormData.append("file", fileInfo.file);

      // For this implementation, we'll just mark the file as "processed"
      // without actually sending to the server until the form is submitted

      // Simulate upload completion after a short delay
      setTimeout(() => {
        // Mark file as uploaded after "completion"
        setUploadedFiles((prev) =>
          prev.map((item) =>
            item.id === fileInfo.id
              ? { ...item, isUploading: false, uploaded: true }
              : item
          )
        );
      }, 1500);
    } catch (error) {
      console.error("Error processing file:", error);

      // Update file with error status
      setUploadedFiles((prev) =>
        prev.map((item) =>
          item.id === fileInfo.id
            ? { ...item, isUploading: false, error: "Error processing file" }
            : item
        )
      );
    }
  };

  // Modified remove file handler to work with objects
  const handleRemoveFile = (id) => {
    setUploadedFiles((prev) => prev.filter((fileInfo) => fileInfo.id !== id));
  };

  // Modified save handler that works with the new file objects
  const handleSave = async () => {
    setLoader(true);
    // Create a final version of formData with the correct deputed value
    const finalFormData = {
      ...formData,
      deputed: selectedAssignees.join(", "),
    };

    if (!validateForm()) {
      return;
    }

    try {
      const formData2 = new FormData();
      formData2.append("email", email);
      formData2.append("phone", phone);
      formData2.append("formData", JSON.stringify(formData));
      // Use finalFormData with the comma-separated deputed value
      formData2.append("formData", JSON.stringify(finalFormData));
      formData2.append("selectedTitles", JSON.stringify(selectedTitles));
      formData2.append("selectedOptions", JSON.stringify(selectedOptions));
      // Append only the actual File objects from our uploadedFiles array
      uploadedFiles.forEach((fileInfo) => {
        if (fileInfo.uploaded) {
          formData2.append("uploadedFiles", fileInfo.file);
        }
      });

      const response = await fetch(
        "https://api-scdndestates.com/api/set-event-and-reminders/",
        {
          method: "POST",
          body: formData2,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save event/reminder");
      }

      const data = await response.json();
      setSelectedTitles(
        Array.isArray(data.selectedTitles) ? data.selectedTitles : []
      );

      if (data.message === "Success") {
        secureLocalStorage.setItem(
          "admin-calendar",
          JSON.stringify(data.dates)
        );
        onReload(data.dates);
      }
      onClose();

      setFormData({
        title: "",
        date: new Date().toISOString().slice(0, 10),
        deputed: "",
        description: "",
        duration: "",
        budget: "",
      });

      secureLocalStorage.removeItem("eventFormData");
      setLoader(false);
    } catch (error) {
      console.error("Error during save:", error);
      setLoader(false);
    }
  };

  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const dropdownRef = React.useRef(null);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const options = [
    ".pptx",
    ".docx",
    ".py",
    ".json",
    ".txt",
    ".xlsx",
    ".zip",
    ".jsx",
    ".css",
    ".pdf",
    ".xml",
    ".html",
    ".js",
    "img",
    "vid",
    "voice",
  ];

  const [showAll, setShowAll] = useState(false);

  const toggleOption = (option) => {
    setSelectedOptions((prev) =>
      prev.includes(option)
        ? prev.filter((item) => item !== option)
        : [...prev, option]
    );
  };

  const visibleOptions = showAll ? options : options.slice(0, 10);

  // Mobile Dropdown View
  const mobileView = (
    <div className="relative w-48" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex items-center justify-between px-3 py-2 border rounded-lg bg-white"
      >
        <span className="truncate">
          {selectedOptions.length
            ? selectedOptions.join(", ")
            : "Select file types"}
        </span>
      </button>

      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-48 overflow-y-auto">
          {options.map((option) => (
            <div
              key={option}
              className="flex items-center px-3 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => toggleOption(option)}
            >
              <input
                type="checkbox"
                checked={selectedOptions.includes(option)}
                onChange={() => {}}
                className="w-4 h-4 mr-2"
              />
              <span>{option}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  // Tablet and Desktop View
  const desktopView = (
    <div className="w-full">
      <div className="flex flex-wrap max-md:gap-1 md:gap-3">
        {visibleOptions.map((option) => (
          <label
            key={option}
            className={`text-sm md:text-base w-[45px] md:w-16 flex border-[1px] border-[#5ab2ff] relative items-center justify-center cursor-pointer rounded-lg max-md:px-2 md:px-3 py-1 transition-all duration-300 ease-in-out ${
              selectedOptions.includes(option)
                ? "bg-[#5ab2ff] text-white"
                : "hover:bg-blue-50"
            }`}
          >
            <input
              type="checkbox"
              checked={selectedOptions.includes(option)}
              onChange={() => toggleOption(option)}
              className="w-4 h-4 mr-2 opacity-0 absolute cursor-pointer"
            />
            <span>{option}</span>
          </label>
        ))}
        {!showAll && options.length > 10 && (
          <button
            onClick={() => setShowAll(true)}
            className="text-sm py-1 underline underline-offset-2 text-[#5ab2ff] flex items-end rounded-lg hover:text-blue-500 transition-colors duration-300"
          >
            Load More
          </button>
        )}
      </div>
    </div>
  );

  return (
    <div className="w-full h-full  shadow-[0px_0px_0px_1px_rgba(0,0,0,0.06),0px_1px_1px_-0.5px_rgba(0,0,0,0.06),0px_3px_3px_-1.5px_rgba(0,0,0,0.06),_0px_6px_6px_-3px_rgba(0,0,0,0.06),0px_12px_12px_-6px_rgba(0,0,0,0.06),0px_24px_24px_-12px_rgba(0,0,0,0.06)] bg-white rounded-lg overflow-hidden">
      <div className="flex items-center justify-between px-4 py-2 border-b-2 ">
        <div className="text-black font-semibold text-lg">Add Events</div>
        <div onClick={onClose} className=" flex justify-end  rounded-full">
          <button
            className="circleadmin bg-white"
            data-animation="showShadow"
            data-remove="3000"
          ></button>
        </div>
      </div>

      <div className="max-lg:max-h-[80vh] lg:max-h-[75vh] overflow-y-auto">
        <div className="flex items-center px-4 py-2 gap-2 text-[#212121] font-medium text-base mt-2">
          <img className="w-8 h-8" src="/images/title-admin.webp" alt="" />
          <input
            type="text"
            value={formData.title}
            className="w-full border rounded-lg px-3 py-1.5 focus:outline-none focus:ring-2 focus:ring-blue-400"
            placeholder="Add Title"
            name="title"
            onChange={handleInputChange}
          />
        </div>

        <div className="flex items-center justify-center max-md:flex-col md:flex-row-reverse">
          <div className="w-full md:w-1/2 flex items-center px-4 py-2 gap-2 text-[#212121] font-medium text-base">
            <img className="w-8 h-8 " src="/images/calen-admin.webp" alt="" />
            <input
              type="date"
              value={formData.date}
              className="border rounded-lg px-3 py-1.5 w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
              name="date"
              min={new Date().toISOString().split("T")[0]}
              onChange={handleInputChange}
            />
          </div>

          <div className="w-full md:w-1/2 relative flex flex-col px-4 py-2 gap-2 text-[#212121] font-medium text-base">
            <div className="flex items-center gap-2 w-full">
              <img className="w-8 h-8" src="/images/assign-admin.webp" alt="" />
              <div className="relative w-full">
                <input
                  type="text"
                  value={formData.deputed}
                  className="w-full border rounded-lg px-3 py-1.5 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  placeholder={
                    selectedAssignees.length === 0
                      ? "Assigned to"
                      : selectedAssignees.length === 1
                      ? "Add one more assignee"
                      : ""
                  }
                  name="deputed"
                  onChange={(e) => {
                    handleInputChange(e);
                    setShow(true);
                  }}
                  disabled={selectedAssignees.length >= 2}
                />
                {show && formData.deputed.trim() !== "" && (
                  <ul
                    ref={searchRef}
                    className="absolute bg-white w-full top-10 left-0 border rounded-lg px-3 py-1 z-10"
                  >
                    {(() => {
                      const filteredResults = [
                        ...AdminInfoApp,
                        ...EmployeeInfoApp,
                      ].filter(
                        (admin) =>
                          admin.designation
                            .toLowerCase()
                            .includes(formData.deputed.toLowerCase()) ||
                          admin.name
                            .toLowerCase()
                            .includes(formData.deputed.toLowerCase())
                      );

                      return filteredResults.length > 0 ? (
                        filteredResults.map((data) => (
                          <li
                            key={data.email}
                            className="py-1 px-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                              handleAssigneeSelect(data.name);
                            }}
                          >
                            {data.name} - {data.designation}
                          </li>
                        ))
                      ) : (
                        <li className="text-gray-500">No results found</li>
                      );
                    })()}
                  </ul>
                )}
              </div>
            </div>

            {/* Display selected assignees */}
            {selectedAssignees.length > 0 && (
              <div className="absolute right-8 top-1.5 flex flex-wrap gap-2 mt-2">
                {selectedAssignees.map((assignee, index) => (
                  <div
                    key={index}
                    className="bg-blue-100 text-blue-800 rounded-lg px-3 py-1 text-sm flex items-center"
                  >
                    {assignee}
                    <button
                      onClick={() => removeAssignee(index)}
                      className="ml-2 text-blue-600 hover:text-blue-800"
                      type="button"
                    >
                      x
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="flex items-start px-4 py-2 gap-2 text-[#212121] font-medium text-base">
          <img className="w-8 h-8" src="/images/desc-admin.webp" alt="" />

          <textarea
            type="text"
            value={formData.description}
            className="w-full border rounded-lg text-xs md:text-sm xl:text-base max-md:px-2 md:px-3 py-1.5 focus:outline-none focus:ring-2 focus:ring-blue-400 min-h-[150px] text-left align-text-top"
            placeholder="Describe here..."
            name="description"
            onChange={handleInputChange}
            rows={12}
          />
        </div>

        <div className="flex max-md:flex-col md:flex-row items-center justify-center">
          <div className="w-full md:w-1/2 flex items-center px-4 py-2 gap-2 text-[#212121] font-medium text-base">
            <img className="w-8 h-8" src="/images/clock-needles.svg" alt="" />
            <select
              value={formData.duration}
              className="w-full border rounded-lg px-3 py-1.5 focus:outline-none focus:ring-2 focus:ring-blue-400"
              name="duration"
              onChange={handleInputChange}
            >
              <option value="Select">Select</option>
              <option value="Unknown">Unknown</option>
              <option value="2 hours">2 hours</option>
              <option value="4 hours">4 hours</option>
              <option value="1 day">1 day</option>
              <option value="1.5 day">1.5 day</option>
              <option value="2 days">2 days</option>
              <option value="3 days">3 days</option>
              <option value="4 days">4 days</option>
              <option value="1 week">1 week</option>
              <option value="1.5 weeks">1.5 weeks</option>
              <option value="2 weeks">2 weeks</option>
            </select>
          </div>

          <div className="w-full md:w-1/2 flex items-center px-4 py-2 gap-2 text-[#212121] font-medium text-base">
            <img className="w-8 h-8" src="/images/cash.svg" alt="" />

            <div className="relative w-full">
              <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                ₹
              </span>
              <input
                type="number"
                value={formData.budget}
                className="w-full border rounded-lg pl-7 pr-3 py-1.5 focus:outline-none focus:ring-2 focus:ring-blue-400"
                placeholder="Enter Budget..."
                name="budget"
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>

        <div className="flex items-start px-4 py-2 gap-2 text-[#212121] font-medium text-base relative">
          <img className="w-8 h-8" src="/images/assign-admin.webp" alt="" />
          <div className="relative w-full">
            {/* Search Input */}
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Type at least 4 characters to search dependency"
              className="w-full border rounded-lg px-3 py-1.5 focus:outline-none focus:ring-2 focus:ring-blue-400"
            />

            {/* Dropdown search results */}
            {results.length > 0 && (
              <ul className="absolute left-0 z-10 bg-gray-100 border mt-2 w-full  max-h-40 rounded-md overflow-y-auto ">
                {results.map((item) => (
                  <li
                    key={item._id}
                    onClick={() => {
                      handleSelectTitle(
                        item._source.title,
                        item._source.Date.replaceAll("-", "_")
                      );
                    }}
                    className="p-2 border-b flex items-center   overflow-hidden  hover:bg-gray-200 cursor-pointer"
                  >
                    <div className="w-full text-xs md:text-sm flex ">
                      <span className="w-24 font-semibold border-r-2 border-[#5ab2ff]">
                        {" "}
                        {item._source.Date}{" "}
                      </span>
                      <div className="font-bold  truncate pl-2 w-full">
                        {item._source.title}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}

            {/* Selected Tags */}
            <div
              className={`flex w-full max-md:flex-col md:flex-row items-center gap-2 ${
                selectedTitles.length > 0 ? "my-2" : ""
              }`}
            >
              {selectedTitles.map(({ title, date }, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between max-md:max-w-64 w-full md:w-[32%] bg-gray-100 px-3 py-1 rounded-md"
                >
                  <div className="w-[90%] truncate  text-xs md:text-sm">
                    {" "}
                    {title} ({date})
                  </div>
                  <button
                    onClick={() => handleRemoveTag(title, date)}
                    className=" w-[10%] flex justify-end text-red-500"
                  >
                    ✕
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex items-start px-4 py-2 gap-2 text-[#212121] font-medium text-base mb-2">
          <img className="w-8 h-8" src="/images/upload.svg" alt="" />

          {/* Checkbox & Dropdown Section */}
          <div className="hidden ">{mobileView}</div>
          <div className="block ">{desktopView}</div>
        </div>
        {/* File Upload Section */}
        <div className=" flex  max-md:items-start md:items-center px-4 py-2 gap-2 text-[#212121] font-medium text-base">
          <img className="w-8 h-8" src="/images/up-arrow.svg" alt="" />
          <div className="max-md:w-full flex items-center max-md:flex-col max-md:items-center md:flex-row gap-2">
            <div className="text-xs max-md:w-full md:w-40 md:text-sm flex items-center justify-center gap-x-2 group relative overflow-hidden max-md:border-[1px] md:border-2 px-2 py-1 rounded-md border-[#5ab2ff] bg-sky-50 cursor-pointer">
              <div className="cursor-pointer outline-none duration-300">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="md:w-7 w-5 h-5 md:h-7 stroke-blue-400 fill-none group-hover:fill-blue-200 group-active:stroke-blue-500 group-active:fill-blue-200 group-active:duration-0 duration-300 group-hover:rotate-90"
                >
                  <path
                    d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                    strokeWidth="1.5"
                  ></path>
                  <path d="M8 12H16" strokeWidth="1.5"></path>
                  <path d="M12 16V8" strokeWidth="1.5"></path>
                </svg>
              </div>
              Add file
              <input
                type="file"
                multiple
                onChange={handleFileChange}
                className="absolute opacity-0 cursor-pointer w-full"
              />
            </div>

            {/* Display files with status */}
            {uploadedFiles.length > 0 && (
              <ul className="rounded-lg left-0 max-md:w-full flex flex-wrap gap-2 items-center justify-center">
                {uploadedFiles.map((fileInfo) => (
                  <li
                    key={fileInfo.id}
                    className={`relative max-md:w-full md:w-36 lg:w-48 xl:w-72 flex justify-between items-center py-2 border px-2 rounded-md pl-0 ${
                      fileInfo.error
                        ? "border-red-300 bg-red-50"
                        : fileInfo.uploaded
                        ? "border-green-300 bg-green-50"
                        : "border-gray-300"
                    }`}
                  >
                    <div className="flex items-center w-full">
                      {fileInfo.isUploading && (
                        <svg
                          className="animate-spin h-4 w-4 mr-2 text-blue-500"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      )}

                      {fileInfo.uploaded && (
                        <svg
                          className="h-4 w-4 mr-2 text-green-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}

                      {fileInfo.error && (
                        <svg
                          className="h-4 w-4 mr-2 text-red-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}

                      <span className="text-xs lg:text-sm flex-grow overflow-hidden truncate">
                        {fileInfo.name}
                      </span>

                      {fileInfo.error && (
                        <span className="text-xs text-red-500 mr-2 overflow-hidden truncate max-w-[100px]">
                          {fileInfo.error}
                        </span>
                      )}

                      <button
                        onClick={() => handleRemoveFile(fileInfo.id)}
                        className="  text-xs font-bold pl-2 flex-shrink-0 scale-100 hover:scale-95 duration-200 ease-in-out"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          id="Layer_2"
                          data-name="Layer 2"
                          className="w-4 h-4 fill-red-500"
                          viewBox="0 0 24 24"
                        >
                          <path d="M19,7a1,1,0,0,0-1,1V19.191A1.92,1.92,0,0,1,15.99,21H8.01A1.92,1.92,0,0,1,6,19.191V8A1,1,0,0,0,4,8V19.191A3.918,3.918,0,0,0,8.01,23h7.98A3.918,3.918,0,0,0,20,19.191V8A1,1,0,0,0,19,7Z" />
                          <path d="M20,4H16V2a1,1,0,0,0-1-1H9A1,1,0,0,0,8,2V4H4A1,1,0,0,0,4,6H20a1,1,0,0,0,0-2ZM10,4V3h4V4Z" />
                          <path d="M11,17V10a1,1,0,0,0-2,0v7a1,1,0,0,0,2,0Z" />
                          <path d="M15,17V10a1,1,0,0,0-2,0v7a1,1,0,0,0,2,0Z" />
                        </svg>
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        <div className="flex justify-end px-4 py-2 mb-2">
          {!loader ? (
            <button
              className="button-excelsmall w-[80px] py-1.5 animationIcon-shine-blue"
              onClick={() => {
                if (validateForm()) {
                  if (selectedOptions.length === 0) {
                    setShow2(true);
                  } else {
                    handleSave();
                  }
                }
              }}
            >
              <i className="text-base md:text-lg">
                <span>
                  <img
                    src="/images/post.svg"
                    className="icon w-3 h-3 fill-white"
                    alt=""
                  />
                </span>
              </i>
              <p className="font-semibold">Post</p>
            </button>
          ) : (
            <button className="button-excelsmall w-[80px] py-1.5 animationIcon-shine-blue">
              <img
                key="1"
                className="h-6"
                src="/images/btn-transparent.gif"
                alt={""}
                autoPlay
                loop
                muted
              />
            </button>
          )}
        </div>
      </div>
      {show2 && (
        <div className="demo1-container after-header">
          <div className="m-1 p-3 xl:p-4 pop1-content shadow-[inset_0px_0px_7px_0px_#4299e1] w-[95%] lg:w-[25%] flex flex-col justify-center items-center pop-up-animation">
            <div className="flex flex-col items-center ">
              <img className="w-20 h-20" src="/images/nofile.svg" alt="" />
              <div className="text-2xl pt-3 font-bold text-black">
                File type not selected
              </div>
              <p className=" pb-4 pt-2  text-sm text-center font-semibold text-[#212121]">
                You have not selected any file types to be submitted by the
                Assignee upon completion of this task. Are you sure you want to
                proceed?
              </p>
            </div>
            <div className="flex justify-center gap-x-5 w-full">
              <button
                onClick={() => setShow2(false)}
                class="cursor-pointer w-20 text-center shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] bg-[#fff] hover:bg-[#fffdfd]   text-[#343434] px-3 py-1.5 rounded-lg font-semibold text-sm hover:text-[#bf2222] transition-all ease-in duration-200"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setShow2(false);
                  handleSave();
                }}
                class="cursor-pointer w-20 text-center  shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]   text-[#fff] px-3 py-1.5 rounded-lg font-semibold   text-sm bg-[#5ab2ff] hover:bg-[#4ea4fb] transition-all ease-in duration-200"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SchedulePopup;