import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

function TaskBarChart({ rate, employee, ratedData }) {
  const transformBarChartData = (ratedData) => {
    const categories = [
      "Completed",
      "Postponed",
      "Not_Started",
      "Pending",
      "Overdue",
    ];
    const transformedData = categories.map((status) => ({
      name: status,
      data: [0, 0, 0, 0, 0, 0],
    }));

    ratedData
      ?.filter((data) => data.name === employee)
      ?.forEach((person) => {
        if (person?.[rate]) {
          ["P0", "P1", "P2", "P3", "P4", "P5"].forEach((priority, index) => {
            transformedData.forEach((item) => {
              Object.entries(person?.[rate]?.[priority] || {}).forEach(
                ([k, v]) => {
                  const statusKey = item.name?.replace(" ", "_");
                  if (v?.[statusKey]) {
                    item.data[index] += v.Days || 0;
                  }
                }
              );
              item.data[index] = parseFloat(item.data[index]?.toFixed(1));
            });
          });
        }
      });

    return transformedData;
  };

  const mockBarChartData = transformBarChartData(ratedData);

  const [stackedBarChartState, setStackedBarChartState] = useState({
    series: mockBarChartData,
    options: {
      colors: ["#94DAFF", "#52575D", "#FF8787", "#FFD966", "#43217a"],
      chart: {
        type: "bar",
        height: "100",
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "55%",
          barHeight: "80%",
          horizontal: false,
          borderRadius: 2,
          borderRadiusWhenStacked: "top",
          dataLabels: {
            enabled: true, // Enable data labels inside bars
            position: "center", // Position labels in the center of bars
            formatter: function (val) {
              // Return empty string for zero or very small values to avoid cluttering
              return val > 0.1 ? val.toFixed(1) : "";
            },
          },
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "12px",
          fontWeight: "bold",
          colors: ["#ffffff"], // White text for better contrast
        },
        // Only show if the value is significant enough to display
        enabledOnSeries: undefined,
        formatter: function (val) {
          return val > 0.1 ? val.toFixed(1) : "";
        },
        // Responsive font size for mobile
        responsive: [
          {
            breakpoint: 768,
            options: {
              style: {
                fontSize: "10px",
              },
            },
          },
        ],
      },
      tooltip: {
        y: {
          formatter: function (value) {
            if (isNaN(value) || value === undefined || value === null) {
              return "0.0 days";
            }
            const formattedValue = value.toFixed(1);
            return `${formattedValue} ${value <= 1 ? "day" : "days"}`;
          },
        },
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return isNaN(value) || value === undefined || value === null
              ? "0"
              : parseFloat(value.toFixed(1));
          },
          maxWidth: 40,
          minWidth: 40,
          offsetX: -10,
          style: {
            fontSize: "12px",
          },
        },
        axisBorder: {
          width: 2,
        },
      },
      xaxis: {
        categories: ["P0", "P1", "P2", "P3", "P4", "P5"],
        labels: {
          style: {
            fontSize: "12px",
          },
        },
      },
      legend: {
        horizontalAlign: "center",
        markers: {
          width: 10,
          height: 10,
          radius: 12,
        },
        formatter: function (seriesName) {
          return seriesName.replaceAll("_", " ");
        },
        fontSize: "12px",
      },
      responsive: [
        {
          breakpoint: 768,
          options: {
            dataLabels: {
              style: {
                fontSize: "8px",
              },
            },
            yaxis: {
              labels: {
                style: {
                  fontSize: "8px",
                },
              },
            },
            xaxis: {
              labels: {
                style: {
                  fontSize: "8px",
                },
              },
            },
            legend: {
              fontSize: "8px",
              position: "bottom",
              horizontalAlign: "center",
            },
          },
        },
      ],
    },
  });

  useEffect(() => {
    setStackedBarChartState((prev) => ({
      ...prev,
      series: mockBarChartData,
    }));
  }, [JSON.stringify(mockBarChartData), rate]);

  return (
    <>
      <div className="w-full md:w-1/2 flex justify-center">
        <div className=" overflow-hidden w-full max-w-[650px] md:p-4 border-2 border-purple-50 rounded-lg max-md:mt-4  max-md:pt-2">
          <div className="  flex items-center justify-start  bg-purple-50/90 rounded-full  max-md:mx-1.5 max-md:p-1 md:p-2">
            <span class="flex items-center gap-x-1 rounded-full bg-purple-500/20 px-2 max-md:py-0.5 md:py-1 text-xs font-semibold text-purple-600">
              <span class="h-2 w-2 rounded-full bg-purple-600 animate-pulse"></span>
              Live
            </span>
            <div className="px-2 text-sm md:text-base text-center text-black/80 lg:text-xl font-semibold">
              {" "}
              {`${rate.split("")[0].toUpperCase()}${rate
                .replaceAll("_", " ")
                .slice(1)}`}
            </div>
          </div>
          <div className="w-full max-md:pt-2 min-w-[200px]">
            <ReactApexChart
              options={stackedBarChartState.options}
              series={stackedBarChartState.series}
              type="bar"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default TaskBarChart;
