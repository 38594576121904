import React, { useEffect } from "react";
import Content from "../../components/Content/Content";
import Header from "../../components/Header/Header";
import FAQ from "./FAQ";
import { Helmet } from "react-helmet";

function Products() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  // FAQ API

  const partnershipFAQ = [
    {
      id: 0,
      ques: "Which regulatory bodies oversee your Company's operations e.g., RBI, SEBI or MCA?",
      ans: "Prices not Controlled i.e., by SCDND or by any centralized authority/operator. It is decided transparently and independently by the peer-to-peer transactions of the Clients themselves.",
    },
    {
      id: 1,
      ques: "What is the average duration of investor commitments with your Company?",
      ans: "Coupon is reset Higher, if the benchmark is revised higher (in a rising rate environment). But there is downside protection provided to Clients when the RBI published benchmark falls.",
    },
    {
      id: 2,
      ques: "How can I regularly verify that your Company complies with industry regulations to ensure the safety of my money?",
      ans: "Zero Liquidity-risk, as SCDND is the market-maker on both sides for the Client, being the Buyer to any seller and a Seller to any buyer, at all times.",
    },
    {
      id: 3,
      ques: "If the founder or any Key Managerial Personnel wishes to leave the Company, what is your contingency plan to ensure management continuity?",
      ans: "Zero Liquidity-risk, as SCDND is the market-maker on both sides for the Client, being the Buyer to any seller and a Seller to any buyer, at all times.",
    },
    {
      id: 4,
      ques: "How do you ensure that the Company&#39;s investment strategy and performance is not dependent on one individual (CEO, CIO etc.) or a specific team?",
      ans: "Zero Liquidity-risk, as SCDND is the market-maker on both sides for the Client, being the Buyer to any seller and a Seller to any buyer, at all times.",
    },
    {
      id: 5,
      ques: "Do you have any institutional investors currently invested with your Company? This would indicate that experienced market experts have vetted your Company.",
      ans: "Zero Liquidity-risk, as SCDND is the market-maker on both sides for the Client, being the Buyer to any seller and a Seller to any buyer, at all times.",
    },
    {
      id: 6,
      ques: "In the case of default or bankruptcy of your Company, what is the process followed under law for recoveries of liabilities? What are the clients classified as (secured creditors, unsecured creditors etc.)?",
      ans: "Zero Liquidity-risk, as SCDND is the market-maker on both sides for the Client, being the Buyer to any seller and a Seller toany buyer, at all times.",
    },
    {
      id: 7,
      ques: "Who has signing authority within the Company i.e., are the individuals responsible for day- to-day management and the Board of Directors the same group?",
      ans: "Zero Liquidity-risk, as SCDND is the market-maker on both sides for the Client, being the Buyer to any seller and a Seller to any buyer, at all times.",
    },
    {
      id: 8,
      ques: "Can you provide us with contact details of some of your clients so we can inquire about their experiences and comfort levels with your services?",
      ans: "Zero Liquidity-risk, as SCDND is the market-maker on both sides for the Client, being the Buyer to any seller and a Seller to any buyer, at all times.",
    },
  ];
  // JSON-LD
  const schemaData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: partnershipFAQ.map((faq) => ({
      "@type": "Question",
      name: faq.ques,
      acceptedAnswer: {
        "@type": "Answer",
        text: faq.ans,
      },
    })),
  };
  return (
    <div>
      <Helmet>
        <title>Investment types we offer - Products in SCDND Estates</title>
        <meta
          name="description"
          content="WALR is the decentralized version of Fixed Income, and RESIDEX is the decentralized version of Real Estate. Read about the special features of our synthetic ETFs that makes it a huge upgrade over traditional and centralized investments."
        />
        <meta
          name="description"
          content="Discover the future of securities trading with Decentralized Assets. Our synthetic ETFs offer a groundbreaking alternative to traditional, centralized fixed-income investments with WALR, and real estate with RESIDEX."
        />
        <meta
          name="description"
          content="Take an overview of the two distinct types of Decentralized Assets (DeAs) and their historical performances, i.e. WALR and RESIDEX, which are the next-generation embodiments of fixed-income and real estate securities."
        />
        <meta
          name="description"
          content="Experience our innovative investment offerings: WALR for decentralized fixed-income, and RESIDEX for decentralized real estate powered by distributed ledger technology relacing old-school centralized trading infrastructure."
        />
        <link rel="canonical" href="https://scdndestates.com/products/" />

        {/* Open Graph */}
        <meta
          property="og:title"
          content="Investment types we offer - Products"
        />
        <meta
          property="og:description"
          content="Become a marketing professional for Decentralized Assets. Join our team and drive sales of the innovative investment products to your client-base, by leveraging your expertise and existing networks."
        />
        <meta
          property="og:site_name"
          content="scdndestates/products - walr and residex"
        ></meta>
        <meta property="og:url" content="https://scdndestates.com/products/" />
        <meta property="og:type" content="website" />
        {/* twitter Graph */}
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="Investment types we offer - Products"
        />
        <meta name="twitter:domain" content="scdndestates.com" />
        <meta
          name="twitter:description"
          content="Become a marketing professional for Decentralized Assets. Join our team and drive sales of the innovative investment products to your client-base, by leveraging your expertise and existing networks."
        />
        <script type="application/ld+json">
          {JSON.stringify(schemaData, null, 2)}
        </script>
      </Helmet>
      <Header />
      <div className="after-header">
        <div className="container-fluid">
          <div className="row">
            <div className="max-w-[1800px] mx-auto">
              <Content />
              <FAQ />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
