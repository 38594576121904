import React from "react";
import Header from "../../components/Header/Header";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { RiBookmark3Fill } from "react-icons/ri";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Helmet } from "react-helmet";

function PLMutualFund() {
  return (
    <>
      <Helmet>
        <title>Mutual Funds | SCDND Estates</title>
        <meta
          name="description"
          content="The rise of online platforms offering low-cost mutual fund options is drawing customers away from traditional distributors."
        />
        <meta
          name="description"
          content="Distributing mutual funds requires a license from the Association of Mutual Funds in India (AMFI), which can be a barrier for many."
        />
        <link rel="canonical" href="https://scdndestates.com/mutual-fund" />
        {/* Open Graph */}
        <meta property="og:title" content="Mutual Funds - SCDND Estates" />
        <meta
          property="og:description"
          content="The rise of online platforms offering low-cost mutual fund options is drawing customers away from traditional distributors."
        />

        <meta property="og:url" content="https://scdndestates.com/mutual-fund" />
        <meta property="og:type" content="website" />
        {/* twitter Graph */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Mutual Funds - SCDND Estates" />
        <meta
          name="twitter:description"
          content="The rise of online platforms offering low-cost mutual fund options is drawing customers away from traditional distributors."
        />
        <meta name="twitter:domain" content="scdndestates.com" />
      </Helmet>
      <Header />
      <div className="after-header Partnerships-layer flex flex-col items-center max-w-[1800px] mx-auto">
        <p className="w-[90%] mx-auto text-sm lg:text-base ">
          <a href="/" className="hover:underline">
            Home
          </a>{" "}
          {">"}{" "}
          <a href="/partnerships" className="hover:underline">
            Partnership
          </a>{" "}
          {">"}{" "}
          <a href="/mutual-fund">
            <u>Mutual Fund</u>
          </a>
        </p>

        <div className="container-fluid w-full max-w-[90%] mx-auto mt-4  bg-gradient-to-tr from-[#dbedf9] to-[#e7f6ff] pt-5 px-2 lg:p-5 rounded-2xl">
          <div className="flex  flex-wrap gap-y-10 gap-x-4 justify-around">
            {/*       Card - 1     */}

            <div className="w-full lg:max-w-[400px] md:max-w-[360px] h-max  transition duration-500 ease-in-out hover:shadow-[0px_6px_0px_0px_#5af]  rounded-2xl   overflow-hidden cursor-pointer">
              <div className="w-full h-[470px] bg-white  rounded-xl     border-none overflow-hidden">
                <Tabs>
                  <TabPanel className="w-full bg-white rounded-xl  border-none h-[420px]">
                    <div className="   object-cover transition duration-300 ease-in-out hover:opacity-90">
                      <img
                        src="/images/OnlineCom.webp"
                        className=""
                        alt="scdnd estates"
                      />
                    </div>
                    <div className="p-3 pb-2  flex flex-col items-center overflow-x-hidden ">
                      {/* <h2 className=" text-xl font-bold tracking-tight text- uppercase bg-white">Lead Generation Challenges</h2> */}
                      <h3 className="text-gray-800 mb-3 text-xl font-bold tracking-tight  uppercase bg-white text-center">
                        Online Competition
                      </h3>
                      <span className="bg-[#5af]  mb-3 inline-block h-0.5 w-[60%] rounded "></span>

                      <p className="  font-semibold bg-white text-base leading-5 text-gray-600 text-center">
                        The rise of online platforms offering low-cost mutual
                        fund options is drawing customers away from traditional
                        distributors.
                      </p>
                    </div>
                  </TabPanel>

                  <TabPanel className="w-full bg-white rounded-xl  border-none ">
                    <div className="p-4 overflow-scroll bg-white border-none overflow-x-hidden pb-2 flex flex-col items-start">
                      <h2 className="mb-3  text-xl font-bold  text-[#212121] ">
                        First Mover Advantage: Focus on Distributor & Clients
                        Benefits{" "}
                      </h2>

                      <div className="space-y-4 text-gray-500  h-[300px] md:h-[314px]  bg-white ">
                        {[
                          "Partnering with us allows mutual fund distributors to gain a first-mover advantage in the market.",
                          "This differentiates them from competitors and enables them to offer unique products to their clients.",
                        ].map((item, index) => (
                          <li
                            key={index}
                            className="flex  space-x-2 rtl:space-x-reverse items-start border-none bg-white"
                          >
                            <svg
                              className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 leading-tight mt-[3px]"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span className="leading-tight bg-white">
                              {item}
                            </span>
                          </li>
                        ))}
                      </div>
                    </div>
                  </TabPanel>

                  <TabList className="relative left-0 bottom-0 border-t-2 border-blue-400/50  w-full flex justify-evenly  text-sm font-medium text-center text-gray-500  rounded-b-lg bg-gray-400/15 overflow-hidden ">
                    <Tab className=" w-full  hover:bg-blue-400/10 overflow-hidden  rounded-none">
                      <button className=" py-2.5 px-1  w-full h-full rounded-none    font-semibold text-base flex items-center justify-center gap-2">
                        Problem <BsFillQuestionCircleFill size={15} />
                      </button>
                    </Tab>
                    <div className="bg-blue-400/50 w-[5px] z-10"></div>
                    <Tab className=" w-full hover:bg-blue-400/10 overflow-hidden rounded-none">
                      <button className="py-2.5 px-1  btn-shine w-full h-full rounded-none  text-[#414141] font-bold text-base flex items-center justify-center gap-2">
                        Solution <RiBookmark3Fill size={15} />
                      </button>
                    </Tab>
                  </TabList>
                </Tabs>
              </div>
            </div>

            {/*       Card - 2     */}

            <div className="w-full lg:max-w-[400px] md:max-w-[360px] h-max  transition duration-500 ease-in-out hover:shadow-[0px_6px_0px_0px_#5af]  rounded-2xl   overflow-hidden cursor-pointer">
              <div className="w-full h-[470px] bg-white  rounded-xl     border-none overflow-hidden">
                <Tabs>
                  <TabPanel className="w-full bg-white rounded-xl  border-none h-[420px]">
                    <div className="   object-cover transition duration-300 ease-in-out hover:opacity-90">
                      <img
                        src="/images/MarketEnt.webp"
                        className=""
                        alt="scdnd estates"
                      />
                    </div>
                    <div className="p-3 pb-2  flex flex-col items-center overflow-x-hidden ">
                      {/* <h2 className=" text-xl font-bold tracking-tight text- uppercase bg-white">Lead Generation Challenges</h2> */}
                      <h3 className="text-gray-800 mb-3 text-xl font-bold tracking-tight  uppercase bg-white text-center">
                        Intense Market Entry
                      </h3>
                      <span className="bg-[#5af]  mb-3 inline-block h-0.5 w-[60%] rounded "></span>

                      <p className="  font-semibold bg-white text-base leading-5 text-gray-600 text-center">
                        Commercial banks' extensive branch networks in India
                        pose a major challenge for other distributors in
                        attracting clients without access to their large pool of
                        savings and current account holders.
                      </p>
                    </div>
                  </TabPanel>

                  <TabPanel className="w-full bg-white rounded-xl  border-none ">
                    <div className="p-4 overflow-scroll bg-white border-none overflow-x-hidden pb-2">
                      <h2 className="md:mb-3  text-xl font-bold  text-[#212121] ">
                        Portfolio Enhancement: <br /> Enhanced Client Portfolios
                      </h2>

                      <div className="space-y-4 text-gray-500  h-[314px] bg-white ">
                        {[
                          "Adding our product to their offerings allows distributors to create more diverse investment portfolios for their clients.",
                          "This also enhances the potential for creating more lucrative investment opportunities.",
                        ].map((item, index) => (
                          <li
                            key={index}
                            className="flex  space-x-2 rtl:space-x-reverse items-start border-none bg-white"
                          >
                            <svg
                              className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 leading-tight mt-[3px]"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span className="leading-tight bg-white">
                              {item}
                            </span>
                          </li>
                        ))}
                      </div>
                    </div>
                  </TabPanel>

                  <TabList className="relative left-0 bottom-0 border-t-2 border-blue-400/50  w-full flex justify-evenly  text-sm font-medium text-center text-gray-500  rounded-b-lg bg-gray-400/15 overflow-hidden ">
                    <Tab className=" w-full  hover:bg-blue-400/10 overflow-hidden  rounded-none">
                      <button className=" py-2.5 px-1  w-full h-full rounded-none    font-semibold text-base flex items-center justify-center gap-2">
                        Problem <BsFillQuestionCircleFill size={15} />
                      </button>
                    </Tab>
                    <div className="bg-blue-400/50 w-[5px] z-10"></div>
                    <Tab className=" w-full hover:bg-blue-400/10 overflow-hidden rounded-none">
                      <button className="py-2.5 px-1  btn-shine w-full h-full rounded-none   font-bold text-base flex items-center justify-center gap-2">
                        Solution <RiBookmark3Fill size={15} />
                      </button>
                    </Tab>
                  </TabList>
                </Tabs>
              </div>
            </div>

            {/*       Card - 3     */}

            <div className="w-full lg:max-w-[400px] md:max-w-[360px] h-max  transition duration-500 ease-in-out hover:shadow-[0px_6px_0px_0px_#5af]  rounded-2xl   overflow-hidden cursor-pointer">
              <div className="w-full h-[470px] bg-white  rounded-xl     border-none overflow-hidden">
                <Tabs>
                  <TabPanel className="w-full bg-white rounded-xl  border-none h-[420px] overflow-hidden">
                    <div className="   object-cover transition duration-300 ease-in-out hover:opacity-90">
                      <img
                        src="/images/LicenseReq.webp"
                        className=""
                        alt="scdnd estates"
                      />
                    </div>
                    <div className="p-3 pb-2  flex flex-col items-center overflow-x-hidden ">
                      {/* <h2 className=" text-xl font-bold tracking-tight text- uppercase bg-white">Lead Generation Challenges</h2> */}
                      <h3 className="text-gray-800 mb-2 text-xl font-bold tracking-tight  uppercase bg-white text-center">
                        License Requirements
                      </h3>
                      <span className="bg-[#5af]  mb-2 inline-block h-0.5 w-[60%] rounded "></span>

                      <p className="  font-semibold bg-white text-base leading-5 text-gray-600 text-center">
                        Distributing mutual funds requires a license from the
                        Association of Mutual Funds in India (AMFI), which can
                        be a barrier for many.
                      </p>
                    </div>
                  </TabPanel>

                  <TabPanel className="w-full bg-white rounded-xl  border-none ">
                    <div className="p-4 overflow-scroll bg-white border-none overflow-x-hidden pb-2">
                      <h2 className="mb-3  text-xl font-bold  text-[#212121] ">
                        Easy Entry & Reduced <br /> Barrier to Entry
                      </h2>

                      <div className="space-y-4 text-gray-500  h-[314px] bg-white ">
                        {[
                          "Selling our product requires a Portfolio Management Services (PMS) distributor license.",
                          "Obtaining this license is a straightforward process.",
                        ].map((item, index) => (
                          <li
                            key={index}
                            className="flex  space-x-2 rtl:space-x-reverse items-start border-none bg-white"
                          >
                            <svg
                              className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 leading-tight mt-[3px]"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span className="leading-tight bg-white">
                              {item}
                            </span>
                          </li>
                        ))}
                      </div>
                    </div>
                  </TabPanel>

                  <TabList className="relative left-0 bottom-0 border-t-2 border-blue-400/50  w-full flex justify-evenly  text-sm font-medium text-center text-gray-500  rounded-b-lg bg-gray-400/15 overflow-hidden ">
                    <Tab className=" w-full  hover:bg-blue-400/10 overflow-hidden  rounded-none">
                      <button className=" py-2.5 px-1  w-full h-full rounded-none    font-semibold text-base flex items-center justify-center gap-2">
                        Problem <BsFillQuestionCircleFill size={15} />
                      </button>
                    </Tab>
                    <div className="bg-blue-400/50 w-[5px] z-10"></div>
                    <Tab className=" w-full hover:bg-blue-400/10 overflow-hidden rounded-none">
                      <button className="py-2.5 px-1  btn-shine w-full h-full rounded-none   font-bold text-base flex items-center justify-center gap-2">
                        Solution <RiBookmark3Fill size={15} />
                      </button>
                    </Tab>
                  </TabList>
                </Tabs>
              </div>
            </div>

            {/*       Card - 4     */}

            <div className="w-full lg:max-w-[400px] md:max-w-[360px] h-max  transition duration-500 ease-in-out hover:shadow-[0px_6px_0px_0px_#5af]  rounded-2xl   overflow-hidden cursor-pointer">
              <div className="w-full h-[470px] bg-white  rounded-xl     border-none overflow-hidden">
                <Tabs>
                  <TabPanel className="w-full bg-white rounded-xl  border-none h-[420px]">
                    <div className="   object-cover transition duration-300 ease-in-out hover:opacity-90">
                      <img
                        src="/images/CustRetention.webp"
                        className=""
                        alt="scdnd estates"
                      />
                    </div>
                    <div className="p-3 pb-2  flex flex-col items-center overflow-x-hidden ">
                      {/* <h2 className=" text-xl font-bold tracking-tight text- uppercase bg-white">Lead Generation Challenges</h2> */}
                      <h3 className="text-gray-800 mb-3 text-xl font-bold tracking-tight  uppercase bg-white text-center">
                        Customer Retention
                      </h3>
                      <span className="bg-[#5af]  mb-3 inline-block h-0.5 w-[60%] rounded "></span>

                      <p className="  font-semibold bg-white text-base leading-5 text-gray-600 text-center">
                        {" "}
                        Mutual fund distributors need to find innovative ways to
                        retain their investor base and offer competitive,
                        risk-free investment options.
                      </p>
                    </div>
                  </TabPanel>

                  <TabPanel className="w-full bg-white rounded-xl  border-none ">
                    <div className="p-4 overflow-scroll bg-white border-none overflow-x-hidden pb-2">
                      <h2 className="mb-3  text-xl font-bold  text-[#212121] ">
                        Reduced Investor Risk & Enhanced Customer Loyalty
                      </h2>

                      <div className="space-y-4 text-gray-500  h-[300px] md:h-[314px] bg-white ">
                        {[
                          "Our risk-free investment option guarantees that investors feel satisfied and confident in their financial decisions.",
                          "This, in turn, boosts customer loyalty.",
                        ].map((item, index) => (
                          <li
                            key={index}
                            className="flex  space-x-2 rtl:space-x-reverse items-start border-none bg-white"
                          >
                            <svg
                              className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 leading-tight mt-[3px]"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span className="leading-tight bg-white">
                              {item}
                            </span>
                          </li>
                        ))}
                      </div>
                    </div>
                  </TabPanel>

                  <TabList className="relative left-0 bottom-0 border-t-2 border-blue-400/50  w-full flex justify-evenly  text-sm font-medium text-center text-gray-500  rounded-b-lg bg-gray-400/15 overflow-hidden ">
                    <Tab className=" w-full  hover:bg-blue-400/10 overflow-hidden  rounded-none">
                      <button className=" py-2.5 px-1  w-full h-full rounded-none    font-semibold text-base flex items-center justify-center gap-2">
                        Problem <BsFillQuestionCircleFill size={15} />
                      </button>
                    </Tab>
                    <div className="bg-blue-400/50 w-[5px] z-10"></div>
                    <Tab className=" w-full hover:bg-blue-400/10 overflow-hidden rounded-none">
                      <button className="py-2.5 px-1  btn-shine w-full h-full rounded-none   font-bold text-base flex items-center justify-center gap-2">
                        Solution <RiBookmark3Fill size={15} />
                      </button>
                    </Tab>
                  </TabList>
                </Tabs>
              </div>
            </div>
          </div>

          <div className="my-4 text-center pt-3 xl:px-20">
            <p className=" just-content-center px-4 pt-3 leading-7 text-sm md:text-base font-semibold">
              In a world where online competition threatens the traditional
              mutual fund distribution model, we offer a lifeline to
              distributors looking to not just survive but thrive. Our financial
              product is a game-changer, enabling distributors to tap into their
              potential and redefine their role in the market.
            </p>
            <p className="mb-3 just-content-center px-4 pt-1 leading-7 text-sm md:text-base font-semibold">
              Don't let the challenges of today dictate the future of your
              business. Join us in this exciting journey to revolutionize the
              mutual fund distribution industry and secure your place in the
              evolving financial landscape. Together, we can turn the challenges
              of today into the opportunities of tomorrow, ensuring that mutual
              fund distributors remain the trusted guides to financial success
              for their clients.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default PLMutualFund;
