import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import ConfirmPopUp from "../../components/PopUp1/ConfirmPopUp";
import secureLocalStorage from "react-secure-storage";
import AlertRed from "../../components/Alerts/AlertRed.jsx";

function PaymentPage() {
  const { pan, isAuthenticated } = CheckAuth();
  const [show, setShow] = useState(false);
  const [userOtp, setUserOtp] = useState(0);
  const location = useLocation();
  const { withdrawData } = location.state;
  const { withdrawAmount, balance, otp } = withdrawData;
  const amount = parseFloat(withdrawAmount);
  const amount2 = parseFloat(balance);
  const otp2 = parseInt(otp);
  const total = amount2 - amount;
  const totalAmount = total.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
  });
  const storedData = secureLocalStorage.getItem("kycData");
  const kycData = JSON.parse(storedData);
  const [showModal1, setShowModal1] = useState(false);

  const navigate = useNavigate();
  const handleSubmit = () => {
    // e.preventDefault();
    if (!isAuthenticated) {
      navigate("/userLost");
    } else if (otp2 !== parseInt(userOtp)) {
      setShowModal1(true);
      setTimeout(() => {
        setShowModal1(false);
      }, 5000);
    } else {
      setShow(true);
    }
  };

  const formattedAmount = amount.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
  });
  const balanceAmount = amount2.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
  });

  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    // Allow only numeric values
    if (/^\d*$/.test(inputValue)) {
      setUserOtp(inputValue); // Update state only if the input is valid
    }
  };


  return (
    <>
      {/* <Header /> */}
      {show === true ? (
        <ConfirmPopUp
          topUp={amount}
          balance={total}
          activity={"Withdraw Funds"}
        />
      ) : (
        <div className="container max-w-[1300px] after-header flex flex-col justify-start items-center">
          <div className="py-2 w-full flex items-start">
            {/* All Links */}
            <div className=" m-0 p-0 mx-2">
              <a href="/">
                <span className="header-link ">Home</span>
                <span className="text-secondary "> &gt; </span>
              </a>
              <a href="/moneyWallet">
                <span className="header-link ">Money Wallet</span>
                <span className="text-secondary "> &gt; </span>
              </a>
              <a href="#">
                <span className="underline text-black ">Withdraw Money</span>
              </a>
            </div>
          </div>

          {/* Full Div */}
          <div className="bg-zinc-100 rounded-xl flex flex-wrap justify-between ">
            {/* Left Div */}
            <div className="w-full lg:w-2/5">
              <div className="p-4 m-4 bg-light rounded-xl shadow-md min-h-[665px] lg:min-h-[610px] bg-white">
                <h5 className="text-xl font-bold">Order Summary</h5>
                <hr className="" />
                <h6 className="d-flex justify-content-between font-size1 text-dark my-2">
                  <span className="text-sm">Present Balance</span>
                  <span className="text-sm font-semibold">
                    {balanceAmount}/-
                  </span>
                </h6>
                <h6 className="d-flex justify-content-between text-dark my-2">
                  <span className="text-sm">Withdrawal Amount</span>
                  <span className="text-sm font-semibold">
                    {formattedAmount}/-
                  </span>
                </h6>
                <hr className="" />
                <h6 className="d-flex justify-content-between font-size1 text-dark my-3">
                  <span className="text-base font-bold">Remaining Balance</span>
                  <span className="text-base font-bold">{totalAmount}/-</span>
                </h6>

                {/* Account Details */}
                <>
                  <div className="justify-content-center align-items-center d-flex flex-col my-4">
                    <div className="border border-1 p-3 my-4 rounded">
                      <h6 className="text-lg mt-0 mb-3 font-semibold text-gray-800">
                        Your Linked Bank Account:
                      </h6>
                      <h6 className="text-dark my-1 text-base">
                        Name :{" "}
                        <span className="fw-bold text-base">
                          {kycData === null || kycData["fullName"] === null
                            ? ""
                            : kycData["fullName"]}
                        </span>
                      </h6>
                      <h6 className="text-dark my-1 text-base">
                        Account No :{" "}
                        <span className="fw-bold text-base">
                          {kycData === null || kycData["account"] === null
                            ? ""
                            : kycData["account"]}
                        </span>
                      </h6>
                      <h6 className="text-dark my-1 text-base">
                        IFSC :{" "}
                        <span className="fw-bold text-base">
                          {kycData === null || kycData["ifsc"] === null
                            ? ""
                            : kycData["ifsc"].toUpperCase()}
                        </span>
                      </h6>
                      <h6 className="text-dark my-1 text-base">
                        Bank :{" "}
                        <span className="fw-bold text-base">
                          {kycData === null || kycData["bank"] === null
                            ? ""
                            : kycData["bank"]}
                        </span>
                      </h6>
                    </div>
                    <div className="input-group m-0 p-0">
                      <input
                        className="form-control font-size1 py-0 m-0 px-2"
                        type="text"
                        placeholder="Enter OTP"
                        value={userOtp} // Bind the state to the input value
                        onChange={handleInputChange}
                      />
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => handleSubmit()}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </>
              </div>
            </div>

            {/* Right Div */}
            <div className="w-full lg:w-3/5 m-0 p-4">
              <img
                src="/images/withdraw.webp"
                className="rounded-xl shadow-sm w-full h-full object-cover"
                alt=""
              />
            </div>
          </div>
        </div>
      )}
      {/* Modal for showing alert */}
      {showModal1 && (
        <AlertRed
          message="Please enter correct OTP!"
          onClose={() => setShowModal1(false)}
        />
      )}
    </>
  );
}

export default PaymentPage;
