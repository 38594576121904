import React, { useState, useEffect, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./EventScheduler.css";
import CheckAuth from "../components/ServiceFunctions/CheckAuth.jsx";
import AlertYellow from "../components/Alerts/AlertYellow.jsx";
import AlertRed from "../components/Alerts/AlertRed.jsx";
import AlertGreen from "../components/Alerts/AlertGreen.jsx";
import AdminInfoApp from "../API/AdminApi.js";

const TaskSpecks = ({
  adminCalender,
  onReload,
  selectedEvent,
  setSelectedEvent,
  filter,
  employee,
  closePopup2,
  status,
}) => {
  const { phone, email } = CheckAuth();
  const admin = AdminInfoApp.some(
    (admin) => admin?.email?.toLowerCase() === email?.toLowerCase()
  );
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [showPopup2, setShowPopup2] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editedBlocker, setEditedBlocker] = useState(null);
  const [editedDescription, setEditedDescription] = useState(
    selectedEvent?.desc || ""
  );
  const [selectedRate, setSelectedRate] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [changeRate, setChangeRate] = useState(-1);
  const [changeStatus, setChangeStatus] = useState(-1);
  const [time, setTime] = useState("10:30");
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [generatedRatingOtp, setGeneratedRatingOtp] = useState("");
  const [showModal, setShowModal] = useState(-1);
  const [enteredDate, setEnteredDate] = useState(null);
  const [resendRatingTimer, setResendRatingTimer] = useState(90);
  const timerRef = useRef(null);
  const [timer, setTimer] = useState(300);
  const [userOtp, setUserOtp] = useState();
  const [isEditingDesc, setIsEditingDesc] = useState(false);
  const [otp, setOtp] = useState("1");
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    const filesWithStatus = Object.entries(
      selectedEvent?.assigneefiles || []
    )?.map(([key, val]) => ({
      file: val,
      name: key.includes("_")
        ? key.substring(0, key.lastIndexOf("_")) +
          "." +
          key.substring(key.lastIndexOf("_") + 1)
        : key,
      isUploading: false,
      uploaded: true,
      error: null,
    }));

    setUploadedFiles([...filesWithStatus]);
  }, [selectedEvent]);

  const timeOptions = ["10:30", "12:30", "14:30", "16:30"];

  const handleTimeChange = (e) => {
    setTime(e.target.value);
    const selectedTime = e.target.value;
    const selectedDate = new Date(enteredDate);
    const [hours, minutes] = selectedTime.split(":");
    selectedDate.setHours(Number(hours) + 5, Number(minutes) + 30, 0, 0);
    const formattedDate = selectedDate.toISOString().split(".")[0];
    setEnteredDate(formattedDate);
  };

  // First, we need to modify handleFileChange to start the upload immediately
  const handleFileChange = async (event) => {
    const MAX_FILE_SIZE = 10 * 1024 * 1024;
    const files = Array.from(event.target.files);
    if (files.length + uploadedFiles?.length > 3) {
      alert("Please make a Zip folder for uploading more than 3 files.");
      return;
    }
    for (let file of files) {
      if (file.size > MAX_FILE_SIZE) {
        alert(`File "${file.name}" exceeds the 10MB limit.`);
        return;
      }
    }
    const filesWithStatus = files.map((file) => ({
      file: file,
      name: file.name,
      isUploading: true,
      error: null,
    }));
    setUploadedFiles((prev) => [...prev, ...filesWithStatus]);

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("uploadedFiles", file);
    });
    formData.append("selectedTitle", selectedEvent?.title);

    selectedEvent?.validators.forEach((validator) => {
      formData.append("selectedValidators[]", validator);
    });
    formData.append("filter", filter);
    formData.append("email", email);
    formData.append("employee", employee);
    try {
      const response = await fetch(
        "https://api-scdndestates.com/api/upload-assignee-file/",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const data = await response.json();
        onReload(data.dates);
        setSelectedEvent((prevEvents) => ({
          ...prevEvents,
          ["assigneefiles"]: data.files,
        }));
      } else {
        const errorData = await response.json();
        setUploadedFiles((prev) =>
          prev.map((item) =>
            files.some((file) => file.name === item.name)
              ? {
                  ...item,
                  isUploading: false,
                  error: errorData.error || "Upload failed",
                }
              : item
          )
        );
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      setUploadedFiles((prev) =>
        prev.map((item) =>
          files.some((file) => file.name === item.name)
            ? { ...item, isUploading: false, error: "Network error" }
            : item
        )
      );
    }
  };

  // Modified handleRemoveFile to handle objects instead of direct files
  const handleRemoveFile = (name) => {
    fetch(`https://api-scdndestates.com/api/delete-assignee-file/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        name,
        filter,
        employee,
        title: selectedEvent?.title,
        date: selectedEvent?.event_date,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete event/reminder");
        }
        return response.json();
      })
      .then((response) => {
        if (response.message === "success") {
          setUploadedFiles((prev) =>
            prev.filter(
              (file) =>
                file.name.replaceAll(".", "_") !== name.replaceAll(".", "_")
            )
          );
          onReload(response.dates);
        }
      })
      .catch((error) => {
        // console.error("Error during logout:", error);
      });
  };

  const handleEvent = (title, date, key, value) => {
    if (key?.toLowerCase() === "rating") {
      setLoader(true);
    } else if (key?.toLowerCase() === "status") {
      setLoader2(true);
    }
    fetch(`https://api-scdndestates.com/api/edit-event-and-reminders/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title,
        filter,
        employee,
        date,
        key,
        value,
        enteredDate,
        editedBlocker,
        editedDescription,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete event/reminder");
        }
        return response.json();
      })
      .then((response) => {
        setLoader(false);
        setLoader2(false);
        if (response.message !== "Success") {
          alert(response.message);
        } else {
          setSelectedEvent((prevEvents) => ({
            ...prevEvents,
            [key?.toLowerCase()]: value,
          }));
          closePopup2(9);
        }
        onReload(response.dates);
        setEnteredDate(null);
        setOtp();
        setUserOtp();
        setIsEditing(false);
        setIsEdit(false);
        setSelectedRate(null);
        setTimeout(() => {
          setSelectedStatus(null);
          setShowPopup2(null);
          setChangeStatus(-1);
        }, 3000);
      })
      .catch((error) => {
        // console.error("Error during logout:", error);
      });
  };

  const closePopup = () => {
    setEnteredDate(null);
    setOtp();
    setUserOtp();
    setIsEditing(false);
    setIsEdit(false);
    setEditedBlocker(null);
    setEditedDescription(null);
    setSelectedStatus(null);
    setShowPopup2(null);
    setChangeStatus(-1);
    setSelectedEvent(null);
    closePopup2(0);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
    return formattedTime;
  };

  const handleTimer = () => {
    timerRef.current = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(timerRef.current);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  // use effect for filter close
  const filterRef = useRef(null); // Use a ref to reference the filter dropdown

  const handleResendRatingTimer = () => {
    setResendRatingTimer(90);

    const timerInterval = setInterval(() => {
      setResendRatingTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timerInterval);
    }, 90000);
  };

  const sendRatingOtp = () => {
    if (phone.length !== 10) {
      setShowModal(3);
      setTimeout(() => {
        setShowModal(-1);
      }, 5000);
    } else {
      fetch(`https://api-scdndestates.com/api/work-rating-otp/`, {
        method: "POST",
        body: JSON.stringify({
          title: selectedEvent?.title,
          assignee: selectedEvent?.assignee,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to send otp");
          }
          return response.json();
        })
        .then((response) => {
          if (response.error === "Phone number not found") {
            setShowModal(4);
            setTimeout(() => {
              setShowModal(-1);
            }, 5000);
            setSelectedEvent((prevEvents) => ({
              ...prevEvents,
              rating: selectedRate,
            }));
          } else if (response.error === "task not completed") {
            setShowModal(5);
            setTimeout(() => {
              setShowModal(-1);
            }, 5000);
            setSelectedEvent((prevEvents) => ({
              ...prevEvents,
              rating: selectedRate,
            }));
          } else {
            setGeneratedRatingOtp(response.otp);
            handleResendRatingTimer();
          }
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });
    }
  };

  const sendStatusOtp = () => {
    if (phone?.length !== 10) {
      setShowModal(3);
      setTimeout(() => {
        setShowModal(-1);
      }, 5000);
    } else {
      fetch(`https://api-scdndestates.com/api/work-duration-otp/`, {
        method: "POST",
        body: JSON.stringify({
          phone: "91" + phone,
          title: selectedEvent?.title,
          date: selectedEvent?.event_date,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to send otp");
          }
          return response.json();
        })
        .then((response) => {
          if (response.error === "Phone number not found") {
            setShowModal(4);
            setTimeout(() => {
              setShowModal(-1);
            }, 5000);
          } else {
            setOtp(response.otp);
          }
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });
    }
  };

  const verifyGeneratedOtp = (type, userOtp) => {
    const otp = type === "rating" && generatedRatingOtp;
    if (otp === userOtp) {
      handleEvent(
        selectedEvent?.title,
        selectedEvent?.event_date,
        "Rating",
        selectedEvent?.rating
      );
      setChangeRate(1);
      setTimeout(() => {
        setChangeRate(-1);
        setGeneratedRatingOtp("");
      }, 5000);
    } else {
      setShowModal(1);
    }
    setTimeout(() => {
      setShowModal(-1);
    }, 5000);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setIsFilterOpen(false);
      }
    };

    // Attach the event listener
    document.addEventListener("click", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const [options, setOptions] = useState([
    { daysRequired: "", budget: "", reason: "" },
  ]);

  const handleInputChange = (index, e) => {
    const newOptions = [...options];
    const fieldName = e.target.name || e.target.getAttribute("data-field");
    const value = e.target.value;
    newOptions[index][fieldName] = value;
    setOptions(newOptions);
  };

  const handleCheckboxChange = (index, e) => {
    const { checked } = e.target;
    setOptions((prev) =>
      prev.map((opt, i) => (i === index ? { ...opt, selected: checked } : opt))
    );
  };

  const addOption = () => {
    if (options.length < 3) {
      setOptions([...options, { daysRequired: "", budget: "", reason: "" }]);
    }
  };

  const removeOption = (index) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setEditedDescription(selectedEvent?.desc || null);
    setEditedBlocker(selectedEvent?.blocker || null);
    setEnteredDate(selectedEvent?.resumption || null);
  }, [JSON.stringify(selectedEvent), selectedStatus]);

  function checkDeadlineStatus() {
    const deadline = new Date(selectedEvent.deadline);
    const now = new Date();
    const timeDiff = deadline - now;

    const hoursBeforeDeadline = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutesBeforeDeadline = Math.floor(
      (timeDiff % (1000 * 60 * 60)) / (1000 * 60)
    );
    if (now > deadline) {
      if (editedBlocker === null || editedBlocker?.trim() === "")
        return "You have missed the Deadline of this Task as set by the Admin. Please enter the reason in the Blocker.";
    } else {
      return `You have finished this Task ${hoursBeforeDeadline} hours and ${minutesBeforeDeadline} minutes before Deadline.`;
    }
  }

  const verifyStatusOtp = () => {
    if (showPopup2 === "Postponed" || showPopup2 === "Not Started") {
      if (enteredDate !== null) {
        if (
          checkDeadlineStatus() ===
          "You have missed the Deadline of this Task as set by the Admin. Please enter the reason in the Blocker."
        ) {
          setMessage(checkDeadlineStatus());
          setShowModal(6);
          setTimeout(() => {
            setShowModal(-1);
          }, 3000);
        } else {
          setChangeStatus(0);
          sendStatusOtp();
          setTimer(90);
          handleTimer();
        }
      } else {
        setShowModal(8);
        setTimeout(() => {
          setShowModal(-1);
        }, 3000);
      }
    } else {
      if (
        checkDeadlineStatus() ===
        "You have missed the Deadline of this Task as set by the Admin. Please enter the reason in the Blocker."
      ) {
        setShowModal(6);
        setTimeout(() => {
          setShowModal(-1);
        }, 3000);
      } else {
        setChangeStatus(0);
        sendStatusOtp();
        setTimer(90);
        handleTimer();
      }
    }
  };

  return (
    <div>
      {/* Popup */}
      <div className="h-full">
        <div className="flex h-full justify-between px-4 py-2 border-b-2 shadow-md">
          {" "}
          <h2 className="text-base md:text-lg font-semibold text-black flex items-center gap-2">
            <span className="">
              <svg
                id="fi_18401969"
                className="w-6 h-6"
                viewBox="0 0 64 64"
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 1"
              >
                <path d="m2.55 13.33v43.18a5.5 5.5 0 0 0 5.5 5.49h47.9a5.5 5.5 0 0 0 5.5-5.49v-43.18a5.5 5.5 0 0 0 -5.5-5.49h-2.8v-1.47a4.4 4.4 0 0 0 -1.29-3.09 4.363 4.363 0 0 0 -7.45 3.09v1.47h-24.82v-1.47a4.37 4.37 0 1 0 -8.74 0v1.47h-2.8a5.5 5.5 0 0 0 -5.5 5.49zm43.86-6.96a2.375 2.375 0 0 1 2.37-2.37 2.338 2.338 0 0 1 1.66.69 2.372 2.372 0 0 1 .71 1.68v5.97a2.37 2.37 0 1 1 -4.74 0zm-33.56 0a2.369 2.369 0 0 1 4.04-1.68 2.4 2.4 0 0 1 .7 1.68v5.97a2.37 2.37 0 1 1 -4.74 0zm-8.3 17.02h54.9v33.12a3.5 3.5 0 0 1 -3.5 3.49h-47.9a3.5 3.5 0 0 1 -3.5-3.49z"></path>
                <path d="m15.78 34.8a5.15 5.15 0 1 0 -5.16-5.15 5.155 5.155 0 0 0 5.16 5.15zm0-8.3a3.15 3.15 0 1 1 -3.16 3.15 3.156 3.156 0 0 1 3.16-3.15z"></path>
                <path d="m32 34.8a5.15 5.15 0 1 0 -5.15-5.15 5.155 5.155 0 0 0 5.15 5.15zm0-8.3a3.15 3.15 0 1 1 -3.15 3.15 3.15 3.15 0 0 1 3.15-3.15z"></path>
                <path d="m48.22 34.8a5.15 5.15 0 1 0 -5.15-5.15 5.158 5.158 0 0 0 5.15 5.15zm0-8.3a3.15 3.15 0 1 1 -3.15 3.15 3.158 3.158 0 0 1 3.15-3.15z"></path>
                <path d="m15.78 46.62a5.155 5.155 0 1 0 -5.16-5.15 5.155 5.155 0 0 0 5.16 5.15zm0-8.31a3.155 3.155 0 1 1 -3.16 3.16 3.165 3.165 0 0 1 3.16-3.16z"></path>
                <path d="m32 46.62a5.155 5.155 0 1 0 -5.15-5.15 5.155 5.155 0 0 0 5.15 5.15zm0-8.31a3.155 3.155 0 1 1 -3.15 3.16 3.158 3.158 0 0 1 3.15-3.16z"></path>
                <path d="m48.22 46.62a5.155 5.155 0 1 0 -5.15-5.15 5.158 5.158 0 0 0 5.15 5.15zm0-8.31a3.155 3.155 0 1 1 -3.15 3.16 3.167 3.167 0 0 1 3.15-3.16z"></path>
                <path d="m15.78 58.43a5.15 5.15 0 1 0 -5.16-5.15 5.155 5.155 0 0 0 5.16 5.15zm0-8.3a3.15 3.15 0 1 1 -3.16 3.15 3.156 3.156 0 0 1 3.16-3.15z"></path>
                <path d="m32 58.43a5.15 5.15 0 1 0 -5.15-5.15 5.155 5.155 0 0 0 5.15 5.15zm0-8.3a3.15 3.15 0 1 1 -3.15 3.15 3.15 3.15 0 0 1 3.15-3.15z"></path>
              </svg>
            </span>
            Event Details
          </h2>
          <div
            onClick={() => closePopup(false)}
            className=" flex justify-end  p-0 m-0 rounded-full"
          >
            <button
              className="circleadmin bg-white"
              data-animation="showShadow"
              data-remove="3000"
            ></button>
          </div>
        </div>
        <div className="p-3 pt-2 overflow-scroll h-[600px] bg-white text-sm md:text-base rounded-b-lg">
          <div className="flex flex-col mb-2">
            <span className="font-semibold text-black">Title:</span>
            <span className="border rounded p-1 font-medium ">
              {selectedEvent?.title}
            </span>
          </div>
          <div className="mb-2">
            <div className="w-full flex items-center justify-between mb-1">
              <span className="font-semibold text-black">Description:</span>

              {admin && (
                <button
                  className="px-2 py-1 ml-2 bg-sky-400 text-white text-sm rounded hover:bg-sky-500 duration-300"
                  onClick={() => {
                    if (isEditingDesc) {
                      handleEvent(
                        selectedEvent?.title,
                        selectedEvent?.event_date,
                        "Description",
                        editedDescription
                      );
                    }
                    setIsEditingDesc(!isEditingDesc);
                  }}
                >
                  {isEditingDesc ? "Save" : "Edit"}
                </button>
              )}
            </div>
            <div className="flex items-center justify-between">
              <textarea
                className="w-full p-2 border border-gray-300 leading-5 rounded min-h-[80px]"
                value={editedDescription}
                onChange={(e) => setEditedDescription(e.target.value)}
                placeholder="Description text here..."
                rows="15"
              />
            </div>
          </div>
          <div>
            <div className="w-full flex items-center justify-between mb-1">
              <div className="flex font-semibold text-black items-center">
                Blockers (if any) :
              </div>

              <div className="flex justify-end mt-1">
                {isEditing ? (
                  <button
                    className="px-2 py-1 bg-sky-400 text-white text-sm rounded hover:bg-sky-500 duration-300"
                    onClick={(e) => {
                      if (editedBlocker?.trim() !== "") {
                        setIsEditing(false);
                        handleEvent(
                          selectedEvent?.title,
                          selectedEvent?.event_date,
                          "Blocker",
                          editedBlocker
                        );
                      } else {
                        setIsEditing(false);
                      }
                    }}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    className="px-2 py-1 bg-sky-400 text-white font-semibold text-sm rounded hover:bg-sky-500 duration-300"
                    onClick={(e) => {
                      setIsEditing(true);
                      setEditedBlocker(selectedEvent?.blocker || null);
                    }}
                  >
                    Edit
                  </button>
                )}
              </div>
            </div>
            <textarea
              className="w-full p-2 border border-gray-300 rounded"
              placeholder="Why this project could not be finished in time..."
              value={editedBlocker} // Show the editing state value
              onChange={(e) => {
                const newValue = e.target.value;
                if (newValue.trim() !== "") {
                  setEditedBlocker(newValue);
                } else {
                  setEditedBlocker(newValue);
                }
              }}
              rows="3"
              readOnly={!isEditing}
            />
          </div>

          {/* File Upload Section */}
          {selectedEvent?.validators?.length > 0 && (
            <>
              <div
                className={`flex flex-col max-md:items-start md:items-start justify-start py-2 gap-2 text-[#212121] font-medium text-base mt-3`}
              >
                <div className="flex flex-wrap text-sm md:text-base font-medium text-black items-center">
                  Please attach an
                  <span className="text-[#5ab2ff] px-1">
                    {selectedEvent?.validators?.map((file) => `${file}, `)}
                  </span>
                  file as requested by the WTD:
                </div>
                <div className="max-md:w-full flex items-center max-md:flex-col max-md:items-center md:flex-row gap-2">
                  <div className="text-xs max-md:w-full md:w-24 lg:w-28 lg:text-sm flex items-center justify-center gap-x-2 group relative overflow-hidden max-md:border-[1px] md:border-2 px-2 py-1 rounded-md border-[#5ab2ff] bg-sky-50 cursor-pointer">
                    <div className="cursor-pointer outline-none duration-300">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="md:w-6 w-5 h-5 md:h-6 stroke-blue-400 fill-none group-hover:fill-blue-200 group-active:stroke-blue-500 group-active:fill-blue-200 group-active:duration-0 duration-300 group-hover:rotate-90"
                      >
                        <path
                          d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                          strokeWidth="1.5"
                        ></path>
                        <path d="M8 12H16" strokeWidth="1.5"></path>
                        <path d="M12 16V8" strokeWidth="1.5"></path>
                      </svg>
                    </div>
                    Add file
                    <input
                      type="file"
                      // accept={selectedEvent?.validators}
                      multiple
                      onChange={(e) => {
                        handleFileChange(e);
                      }}
                      className="absolute opacity-0 cursor-pointer w-full"
                    />
                  </div>

                  {/* Display Files with Status */}
                  {uploadedFiles?.length > 0 && (
                    <ul className="rounded-lg left-0 max-md:w-full flex flex-wrap gap-2 items-center justify-center">
                      {uploadedFiles?.map((fileInfo, index) => (
                        <li
                          key={index}
                          className={`relative max-md:w-full md:w-36 lg:w-48 xl:w-72 flex justify-between items-center py-2 border px-2 rounded-md pl-0 ${
                            fileInfo.error
                              ? "border-red-300 bg-red-50"
                              : fileInfo.uploaded
                              ? "border-green-300 bg-green-50"
                              : "border-gray-300"
                          }`}
                        >
                          <div className="flex items-center w-full">
                            {fileInfo.isUploading && (
                              <svg
                                className="animate-spin h-4 w-4 mr-2 text-blue-500"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            )}

                            {fileInfo.uploaded && (
                              <svg
                                className="h-4 w-4 mr-2 text-green-500"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            )}

                            {fileInfo.error && (
                              <svg
                                className="h-4 w-4 mr-2 text-red-500"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            )}

                            <span className="text-xs lg:text-sm flex-grow overflow-hidden truncate">
                              {fileInfo.name}
                            </span>

                            {fileInfo.error && (
                              <span className="text-xs text-red-500 mr-2 overflow-hidden truncate max-w-[100px]">
                                {fileInfo.error}
                              </span>
                            )}

                            {fileInfo.uploaded && (
                              <button
                                onClick={() => handleRemoveFile(fileInfo.name)}
                                className="  text-xs font-bold pl-2 flex-shrink-0 scale-100 hover:scale-95 duration-200 ease-in-out"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  id="Layer_2"
                                  data-name="Layer 2"
                                  className="w-4 h-4 fill-red-500"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M19,7a1,1,0,0,0-1,1V19.191A1.92,1.92,0,0,1,15.99,21H8.01A1.92,1.92,0,0,1,6,19.191V8A1,1,0,0,0,4,8V19.191A3.918,3.918,0,0,0,8.01,23h7.98A3.918,3.918,0,0,0,20,19.191V8A1,1,0,0,0,19,7Z" />
                                  <path d="M20,4H16V2a1,1,0,0,0-1-1H9A1,1,0,0,0,8,2V4H4A1,1,0,0,0,4,6H20a1,1,0,0,0,0-2ZM10,4V3h4V4Z" />
                                  <path d="M11,17V10a1,1,0,0,0-2,0v7a1,1,0,0,0,2,0Z" />
                                  <path d="M15,17V10a1,1,0,0,0-2,0v7a1,1,0,0,0,2,0Z" />
                                </svg>
                              </button>
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </>
          )}

          {/* otp */}
          <div
            className={`flex md:flex-row max-md:flex-col justify-start items-start mb-1 mt-2 gap-2  ${
              status === "Postponed" ? "" : "max-md:pb-28"
            }`}
          >
            <div className="w-full md:w-[19%]">
              <span className="font-semibold text-black flex">Deadline: </span>
              <div className="w-full bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none transition ease-in-out duration-300">
                {selectedEvent?.deadline || "No Deadline"} (
                {selectedEvent?.duration})
              </div>
            </div>

            <div className="w-full md:w-[19%]">
              <span className="font-semibold text-black flex">Budget: </span>
              <div className="relative">
                <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-600">
                  ₹
                </span>
                <input
                  type="text"
                  value={selectedEvent?.budget || 0}
                  className="w-full bg-gray-50 p-1.5 pl-7 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none transition ease-in-out duration-300"
                  placeholder="Enter Budget"
                />
              </div>
            </div>

            <div className="w-full md:w-[19%]">
              <div className="font-semibold text-black">Rating:</div>{" "}
              {loader === true ? (
                <div className="w-full  rounded bg-gray-300 animate-pulse text-gray-300 p-1.5 border border-gray-300">
                  <span className="opacity-0">Loading</span>
                </div>
              ) : (
                <select
                  value={selectedEvent?.rating || "Select"}
                  onChange={(e) => {
                    setSelectedRate(selectedEvent?.rating);
                    setSelectedEvent((prevEvents) => ({
                      ...prevEvents,
                      rating: e.target.value,
                    }));
                    setChangeRate(0);
                    sendRatingOtp();
                  }}
                  className="w-full bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none transition ease-in-out duration-300"
                >
                  <option value="Select">Select</option>
                  <option value="Exceeds Expectation">
                    Exceeds Expectation
                  </option>
                  <option value="Meets Expectation">Meets Expectation</option>
                  <option value="Not Meeting Expectation">
                    Not Meeting Expectation
                  </option>
                </select>
              )}
              {generatedRatingOtp !== "" && (
                <div className="w-full mt-1 ">
                  <div className="relative w-full border-b-2 border-blue-400 flex items-center gap-2 justify-between">
                    <input
                      className="w-full p-1.5 font-semibold bg-white tracking-wider ring-0 outline-none"
                      placeholder="Enter OTP"
                      type="number"
                      onChange={(e) => {
                        if (e.target.value.length === 5) {
                          verifyGeneratedOtp("rating", e.target.value);
                        }
                      }}
                      disabled={changeRate === 1}
                      maxLength={5}
                    />
                    {changeRate === 1 ? (
                      <svg
                        width="30px"
                        height="30px"
                        viewBox="-5 -5 93 93"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <circle
                            id="oval"
                            stroke="#43ac6a"
                            strokeWidth="9"
                            fill="transparent"
                            cx="41.5"
                            cy="41.5"
                            r="41.5"
                          ></circle>
                          <polygon
                            id="tick"
                            fill="#43ac6a"
                            points="66 29.01 35.03 59.97 16 40.94 22.92 34.02 35.03 46.13 59.08 22.09"
                          ></polygon>
                        </g>
                      </svg>
                    ) : (
                      <>
                        {resendRatingTimer === 0 ? (
                          <div
                            className="absolute right-2 flex items-end cursor-pointer font-semibold underline underline-offset-2 text-xs md:text-sm text-[#5ab2ff] hover:text-[#3aa0ff]"
                            onClick={() => {
                              sendRatingOtp();
                            }}
                          >
                            Resend
                          </div>
                        ) : (
                          <div className="absolute right-2 font-semibold flex items-end text-sm text-[#5ab2ff] hover:text-[#3aa0ff]">
                            {resendRatingTimer}s
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className="w-full md:w-[19%]">
              <div className="font-semibold text-black">Status:</div>
              {loader2 === true ? (
                <div className="w-full  rounded bg-gray-300 animate-pulse text-gray-300 p-1.5 border border-gray-300">
                  <span className="opacity-0">Loading</span>
                </div>
              ) : (
                <select
                  value={selectedEvent?.status || "Not Started"}
                  onChange={(e) => {
                    if (e.target.value === "Completed") {
                      if (
                        Object.keys(selectedEvent?.assigneefiles || {})
                          ?.length <= 0 &&
                        selectedEvent?.validators?.length > 0
                      ) {
                        alert(
                          "Please upload the file before complete the task."
                        );
                      } else {
                        setChangeStatus(1);
                      }
                    } else {
                      handleEvent(
                        selectedEvent?.title,
                        selectedEvent?.event_date,
                        "Status",
                        e.target.value
                      );
                    }
                    setShowPopup2(selectedEvent?.status);
                    setSelectedStatus(e.target.value);
                  }}
                  className="w-full overflow-hidden whitespace-nowrap bg-gray-50 p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none transition ease-in-out duration-300"
                >
                  {selectedEvent?.status === "Completed" ? (
                    <>
                      <option>Completed</option>
                      <option>Defective</option>
                    </>
                  ) : (
                    // Otherwise show all options
                    <>
                      <option disabled>Not Started</option>
                      <option>In Progress</option>
                      <option>Defective</option>
                      <option disabled>Pending</option>
                      <option>Postponed</option>
                      <option>Completed</option>
                      <option disabled>Overdue</option>
                    </>
                  )}
                </select>
              )}

              {changeStatus !== -1 &&
                selectedStatus === "Completed" &&
                (changeStatus === 0 ? (
                  <div className="w-full mt-1 ">
                    <div className="relative w-full border-b-2 border-blue-400 flex items-center gap-2 justify-between">
                      <input
                        className="w-full p-1.5 font-semibold bg-white tracking-wider ring-0 outline-none"
                        placeholder="Enter OTP"
                        type="number"
                        onChange={(e) => {
                          // Only allow up to 5 digits
                          const value = e.target.value
                            .replace(/\D/g, "")
                            .substring(0, 5);
                          setUserOtp(value); // Update state instead of input value directly

                          if (e.target.value.length === 5) {
                            setUserOtp(e.target.value);
                            if (otp === e.target.value) {
                              handleEvent(
                                selectedEvent?.title,
                                selectedEvent?.event_date,
                                "Status",
                                selectedStatus
                              );
                            } else {
                              setShowModal(1);
                              setTimeout(() => {
                                setShowModal(-1);
                              }, 3000);
                            }
                          }
                        }}
                        disabled={otp === userOtp}
                        maxLength={5}
                      />
                      {otp === userOtp ? (
                        <svg
                          width="30px"
                          height="30px"
                          viewBox="-5 -5 93 93"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <circle
                              id="oval"
                              stroke="#43ac6a"
                              strokeWidth="9"
                              fill="transparent"
                              cx="41.5"
                              cy="41.5"
                              r="41.5"
                            ></circle>
                            <polygon
                              id="tick"
                              fill="#43ac6a"
                              points="66 29.01 35.03 59.97 16 40.94 22.92 34.02 35.03 46.13 59.08 22.09"
                            ></polygon>
                          </g>
                        </svg>
                      ) : (
                        <>
                          {timer === 0 ? (
                            <div
                              className="absolute right-2 flex items-end cursor-pointer font-semibold underline underline-offset-2 text-xs md:text-sm text-[#5ab2ff] hover:text-[#3aa0ff]"
                              onClick={() => {
                                setChangeStatus(0);
                                sendStatusOtp();
                                setTimer(90);
                                handleTimer();
                              }}
                            >
                              Resend
                            </div>
                          ) : (
                            <div className="absolute right-2 font-semibold flex items-end text-sm text-[#5ab2ff] hover:text-[#3aa0ff]">
                              {formatTime(timer)}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    <button
                      className="w-28 mt-2 px-2 py-1.5 bg-sky-400 text-white font-semibold text-xs md:text-sm rounded hover:bg-sky-500 duration-300"
                      onClick={() => {
                        verifyStatusOtp();
                        setMessage(checkDeadlineStatus());
                      }}
                    >
                      Send OTP
                    </button>
                  </>
                ))}
            </div>
            {(showPopup2 === "Postponed" || showPopup2 === "Not Started") &&
              selectedStatus === "Completed" && (
                <div className="w-full md:w-[19%]">
                  <div className="font-semibold text-black">
                    {showPopup2 === "Postponed" ? "Resumption" : "Start"} date:
                  </div>
                  <div className="flex gap-2">
                    <input
                      className="w-full bg-gray-50 p-1.5 pl-7 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none transition ease-in-out duration-300"
                      onChange={(e) => {
                        const selectedDate = new Date(e.target.value);
                        if (!isNaN(selectedDate.getTime())) {
                          selectedDate.setHours(16, 0, 0, 0);
                          const formattedDate = selectedDate
                            .toISOString()
                            .split(".")[0];
                          setEnteredDate(formattedDate);
                          setTime("10:30");
                        }
                      }}
                      max={new Date().toISOString().split("T")[0]}
                      min={
                        showPopup2 === "Postponed"
                          ? selectedEvent?.interrupted
                            ? new Date(selectedEvent?.interrupted)
                                .toISOString()
                                .split("T")[0]
                            : ""
                          : selectedEvent?.event_date
                          ? new Date(selectedEvent?.event_date)
                              .toISOString()
                              .split("T")[0]
                          : ""
                      }
                      type="date"
                    />

                    <select onChange={handleTimeChange} value={time}>
                      {timeOptions.map((time) => (
                        <option key={time} value={time}>
                          {time}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
          </div>
          <div className="w-full ">
            {/* Toggle Button */}
            {/* <div className="flex items-center justify-start gap-x-3 mt-4 mb-4">
                <label className="text-base font-semibold text-[#212121]">
                  Submit Findings
                </label>
                <button
                  className={`w-12 h-6 flex items-center rounded-full p-1 transition-colors ${
                    requestQuote ? "bg-blue-500" : "bg-gray-300"
                  }`}
                  onClick={() => setRequestQuote(!requestQuote)}
                >
                  <div
                    className={`w-4 h-4 bg-white rounded-full shadow-md transform transition-transform ${
                      requestQuote ? "translate-x-6" : ""
                    }`}
                  ></div>
                </button>
              </div> */}

            {/* Quote Form (Only visible when requestQuote is true) */}

            {selectedEvent?.status === "For Review" && (
              <div className="rounded-xl bg-[#f8fcfd] mt-4 p-2">
                <div className="font-semibold w-full flex items-center justify-center  max-md:py-1.5 md:py-3">
                  {" "}
                  <div className="flex  w-max  items-center justify-center py-1 text-sm md:text-base gap-x-2 max-md:px-2 md:px-3 border-b-2 border-[#5ab2ff]">
                    <img
                      className="md:w-5 md:h-5 w-4 h-4"
                      src="/images/magnifying-glass.svg"
                      alt=""
                    />
                    Submit Findings
                  </div>
                </div>
                {options.map((option, index) => (
                  <div
                    key={index}
                    className={`flex md:flex-row max-md:flex-col max-md:gap-2 md:gap-4 items-start mb-3 transition-colors duration-300 ease-in-out
                      ${option.selected ? "text-blue-400" : "text-gray-700"}`}
                  >
                    <div className="flex flex-col items-center justify-center ">
                      {/* Add/Remove Button section */}
                      <div className="flex gap-2 pt-4">
                        {index === 0 ? (
                          <button
                            title="Add New"
                            class={`group max-md:w-36 max-md:px-2 max-md:py-1 rounded-md flex cursor-pointer outline-none  duration-300 ${
                              options.length >= 3
                                ? "max-md:bg-red-50 "
                                : "max-md:border-sky-500 max-md:border-[1px] max-md:bg-white"
                            }`}
                            onClick={addOption}
                            disabled={options.length >= 3}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              class={`md:w-8 w-5 h-5 md:h-8  group-active:duration-0 transition-all ease-in-out duration-300 group-hover:rotate-90 ${
                                options.length >= 3
                                  ? "stroke-red-400 fill-none group-hover:fill-red-200 group-active:stroke-red-500 group-active:fill-red-200 cursor-not-allowed"
                                  : "stroke-blue-400 fill-none group-hover:fill-blue-200 group-active:stroke-blue-500 group-active:fill-blue-200"
                              }`}
                            >
                              <path
                                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                stroke-width="1.5"
                              ></path>
                              <path d="M8 12H16" stroke-width="1.5"></path>
                              <path d="M12 16V8" stroke-width="1.5"></path>
                            </svg>
                            <p className="pl-2 font-semibold md:hidden">
                              {options.length >= 3 ? "Max Limit" : "Add Task"}
                            </p>
                          </button>
                        ) : (
                          <button
                            title="Add New"
                            class="group max-md:w-36 max-md:bg-red-50 max-md:px-2 max-md:py-1 rounded-md flex cursor-pointer outline-none hover:rotate-90 duration-300"
                            onClick={() => removeOption(index)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              class="md:w-8 w-5 h-5 md:h-8 stroke-red-400 fill-none group-hover:fill-red-200 group-active:stroke-red-500 group-active:fill-red-200 group-active:duration-0 duration-300 group-hover:rotate-90"
                            >
                              <path
                                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                stroke-width="1.5"
                              ></path>
                              <path d="M8 12H16" stroke-width="1.5"></path>
                            </svg>
                            <p className="pl-2 font-semibold md:hidden">
                              Remove Task
                            </p>
                          </button>
                        )}
                      </div>
                    </div>

                    <div className="flex flex-col items-center justify-center w-full md:w-[33%]">
                      {/* No. of Days Required */}
                      <div className="w-full">
                        <span className="font-semibold  flex">
                          Work Duration:{" "}
                        </span>
                        <select
                          value={option.daysRequired}
                          data-field="daysRequired"
                          onChange={(e) => handleInputChange(index, e)}
                          className="w-full bg-white  text-black p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none transition ease-in-out duration-300"
                        >
                          <option value="Select">Select</option>
                          <option value="2 hours">2 hours</option>
                          <option value="4 hours">4 hours</option>
                          <option value="1 day">1 day</option>
                          <option value="1.5 day">1.5 day</option>
                          <option value="2 days">2 days</option>
                          <option value="3 days">3 days</option>
                          <option value="4 days">4 days</option>
                          <option value="1 week">1 week</option>
                          <option value="2 weeks">2 weeks</option>
                        </select>
                      </div>

                      {/* Budget */}
                      <div className="w-full mt-2">
                        <span className="font-semibold  flex">Budget: </span>
                        <div className="relative">
                          <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-600">
                            ₹
                          </span>
                          <input
                            type="text"
                            name="budget"
                            className="w-full bg-white p-1.5 pl-7 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none transition ease-in-out duration-300"
                            placeholder="Enter Budget"
                            value={option.budget}
                            onChange={(e) => handleInputChange(index, e)}
                          />
                        </div>
                      </div>
                    </div>

                    {/* Reason */}
                    <div className="flex-1 max-md:w-full">
                      <label className="block font-semibold">Reason</label>
                      <textarea
                        type="text"
                        name="reason"
                        value={option.reason}
                        onChange={(e) => handleInputChange(index, e)}
                        placeholder="Enter Reason"
                        rows={4}
                        className="w-full bg-white  p-1.5 border border-gray-300 rounded focus:ring-2 focus:ring-[#5ab2ff] focus:outline-none transition ease-in-out duration-300"
                      />
                    </div>
                  </div>
                ))}

                {/* Submit Button */}
                <div className="flex items-center justify-end mt-4 max-md:pb-20 md:pb-4">
                  <button className="w-40 bg-[#5ab2ff] text-sm font-semibold text-white py-2 rounded-lg hover:bg-[#3c94e2] transition">
                    Submit Request
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* </div>
                              )} */}

      {showModal === 1 ? (
        <AlertYellow
          message="Incorrect OTP!"
          onClose={() => setShowModal(-1)}
        />
      ) : showModal === 2 ? (
        <AlertGreen
          message="OTP is verified."
          onClose={() => setShowModal(-1)}
        />
      ) : showModal === 3 ? (
        <AlertYellow
          message="Mobile number must be of 10 digits"
          onClose={() => setShowModal(-1)}
        />
      ) : showModal === 4 ? (
        <AlertRed
          message="Mobile number provided is not Registered"
          onClose={() => setShowModal(-1)}
        />
      ) : showModal === 5 ? (
        <AlertRed
          message="Your task is not completed yet."
          onClose={() => setShowModal(-1)}
        />
      ) : showModal === 6 ? (
        <AlertRed message={message} onClose={() => setShowModal(-1)} />
      ) : (
        showModal === 8 && (
          <AlertRed
            message="Date is Compulsory."
            onClose={() => setShowModal(-1)}
          />
        )
      )}
    </div>
  );
};

export default TaskSpecks;
