import React from "react";
import Header from "../../components/Header/Header";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { RiBookmark3Fill } from "react-icons/ri";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Helmet } from "react-helmet";

function PLInsuaranceAgent() {
  return (
    <>
      <Helmet>
        <title>Insurance Agent | SCDND Estates</title>
        <meta
          name="description"
          content="Insurance payouts, based on premiums rather than the sum assured, are generally low and spread out over the coverage period, unlike decentralized assets."
        />
        <meta
          name="description"
          content="The market is fiercely competitive with shrinking margins due to digital platforms, banks/NBFCs, vehicle insurance dealerships, and direct-selling by insurers."
        />
        <link rel="canonical" href="https://scdndestates.com/insurance-agent" />
        {/* Open Graph */}
        <meta property="og:title" content="Insurance Agent - SCDND Estates" />
        <meta
          property="og:description"
          content="Insurance payouts, based on premiums rather than the sum assured, are generally low and spread out over the coverage period, unlike decentralized assets."
        />

        <meta
          property="og:url"
          content="https://scdndestates.com/insurance-agent"
        />
        <meta property="og:type" content="website" />
        {/* twitter Graph */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Insurance Agent - SCDND Estates" />
        <meta
          name="twitter:description"
          content="Insurance payouts, based on premiums rather than the sum assured, are generally low and spread out over the coverage period, unlike decentralized assets."
        />
        <meta name="twitter:domain" content="scdndestates.com" />
      </Helmet>
      <Header />
      <div className="after-header Partnerships-layer flex flex-col items-center max-w-[1800px] mx-auto">
        <p className="w-[90%] mx-auto text-sm lg:text-base">
          <a href="/" className="hover:underline">
            Home
          </a>{" "}
          {">"}{" "}
          <a href="/partnerships" className="hover:underline">
            Partnership
          </a>{" "}
          {">"}{" "}
          <a href="/insurance-agent">
            <u>Insurance Agent</u>
          </a>
        </p>
        <div className="container-fluid w-full max-w-[90%] mx-auto mt-4  bg-gradient-to-tr from-[#dbedf9] to-[#e7f6ff] pt-5 px-2 lg:p-5 rounded-2xl">
          <div className="flex  flex-wrap gap-y-10 gap-x-4 justify-around ">
            {/*       Card - 1     */}

            <div className=" w-full lg:max-w-[400px] md:max-w-[360px] h-max  transition duration-500 ease-in-out hover:shadow-[0px_6px_0px_0px_#5af]  rounded-2xl   overflow-hidden cursor-pointer">
              <div className="w-full h-[470px] bg-white  rounded-xl     border-none overflow-hidden">
                <Tabs>
                  <TabPanel className="w-full bg-white rounded-xl  border-none h-[420px]">
                    <div className="   object-cover transition duration-300 ease-in-out hover:opacity-90">
                      <img
                        src="/images/CommSales.webp"
                        className=""
                        alt="scdnd estates"
                      />
                    </div>
                    <div className="p-3 pb-2  flex flex-col items-center overflow-x-hidden ">
                      {/* <h2 className=" text-xl font-bold tracking-tight text- uppercase bg-white">Lead Generation Challenges</h2> */}
                      <h3 className="text-gray-800 mb-3 text-xl font-bold tracking-tight  uppercase bg-white text-center">
                        Razor-thin Commissions on Sales
                      </h3>
                      <span className="bg-[#5af]  mb-3 inline-block h-0.5 w-[60%] rounded "></span>

                      <p className="  font-semibold bg-white text-base leading-5 text-gray-600 text-center">
                        Insurance payouts, based on premiums rather than the sum
                        assured, are generally low and spread out over the
                        coverage period, unlike decentralized assets.
                      </p>
                    </div>
                  </TabPanel>

                  <TabPanel className="w-full bg-white rounded-xl  border-none ">
                    <div className="p-4 overflow-scroll bg-white border-none overflow-x-hidden pb-2">
                      <h2 className="mb-3  text-xl font-bold  text-[#212121] ">
                        Unlocking Earnings <br /> Potential
                      </h2>

                      <ul className="space-y-4 text-gray-500  h-[314px] bg-white ">
                        {[
                          "Payouts are significantly higher when selling financial products of the same relationship value as compared to the sum assured.",
                        ].map((item, index) => (
                          <li
                            key={index}
                            className="flex  space-x-2 rtl:space-x-reverse items-start border-none bg-white"
                          >
                            <svg
                              className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 leading-tight mt-[3px]"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span className="leading-tight bg-white">
                              {item}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </TabPanel>

                  <TabList className="relative left-0 bottom-0 border-t-2 border-blue-400/50  w-full flex justify-evenly  text-sm font-medium text-center text-gray-500  rounded-b-lg bg-gray-400/15 overflow-hidden ">
                    <Tab className=" w-full  hover:bg-blue-400/10 overflow-hidden  rounded-none">
                      <button className=" py-2.5 px-1  w-full h-full rounded-none    font-semibold text-base flex items-center justify-center gap-2">
                        Problem <BsFillQuestionCircleFill size={15} />
                      </button>
                    </Tab>
                    <div className="bg-blue-400/50 w-[5px] z-10"></div>
                    <Tab className=" w-full hover:bg-blue-400/10 overflow-hidden rounded-none">
                      <button className="py-2.5 px-1  btn-shine w-full h-full rounded-none  text-[#414141] font-bold text-base flex items-center justify-center gap-2">
                        Solution <RiBookmark3Fill size={15} />
                      </button>
                    </Tab>
                  </TabList>
                </Tabs>
              </div>
            </div>

            {/*       Card - 2     */}

            <div className=" w-full lg:max-w-[400px] md:max-w-[360px] h-max  transition duration-500 ease-in-out hover:shadow-[0px_6px_0px_0px_#5af]  rounded-2xl   overflow-hidden cursor-pointer">
              <div className="w-full h-[470px] bg-white  rounded-xl     border-none overflow-hidden">
                <Tabs>
                  <TabPanel className="w-full bg-white rounded-xl  border-none h-[420px]">
                    <div className="   object-cover transition duration-300 ease-in-out hover:opacity-90">
                      <img
                        src="/images/DigitalPL.webp"
                        className=""
                        alt="scdnd estates"
                      />
                    </div>
                    <div className="p-3 pb-2  flex flex-col items-center overflow-x-hidden ">
                      {/* <h2 className=" text-xl font-bold tracking-tight text- uppercase bg-white">Lead Generation Challenges</h2> */}
                      <h3 className="text-gray-800 mb-2 text-xl font-bold tracking-tight  uppercase bg-white text-center">
                        Competitive Landscape <br />
                        in Digital Age
                      </h3>
                      <span className="bg-[#5af]  mb-2 inline-block h-0.5 w-[60%] rounded "></span>

                      <p className="  font-semibold bg-white text-base leading-5 text-gray-600 text-center">
                        The market is fiercely competitive with shrinking
                        margins due to digital platforms, banks/NBFCs, vehicle
                        insurance dealerships, and direct-selling by insurers.
                      </p>
                    </div>
                  </TabPanel>

                  <TabPanel className="w-full bg-white rounded-xl  border-none ">
                    <div className="p-4 overflow-scroll bg-white border-none overflow-x-hidden pb-2">
                      <h2 className="mb-3  text-xl font-bold  text-[#212121] ">
                        Empowering Insurance Experts: <br /> Unlocking Potential
                      </h2>

                      <ul className="space-y-4 text-gray-500  h-[300px] md:h-[314px] bg-white ">
                        {[
                          "This is an innovative business that operates in the high-growth fintech market with minimal competition among distributors.",
                          "As a result, new entrants can potentially enjoy a significant first-mover advantage, leading to higher margins and increased market share.",
                        ].map((item, index) => (
                          <li
                            key={index}
                            className="flex  space-x-2 rtl:space-x-reverse items-start border-none bg-white"
                          >
                            <svg
                              className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 leading-tight mt-[3px]"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span className="leading-tight bg-white">
                              {item}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </TabPanel>

                  <TabList className="relative left-0 bottom-0 border-t-2 border-blue-400/50  w-full flex justify-evenly  text-sm font-medium text-center text-gray-500  rounded-b-lg bg-gray-400/15 overflow-hidden ">
                    <Tab className=" w-full  hover:bg-blue-400/10 overflow-hidden  rounded-none">
                      <button className=" py-2.5 px-1  w-full h-full rounded-none    font-semibold text-base flex items-center justify-center gap-2">
                        Problem <BsFillQuestionCircleFill size={15} />
                      </button>
                    </Tab>
                    <div className="bg-blue-400/50 w-[5px] z-10"></div>
                    <Tab className=" w-full hover:bg-blue-400/10 overflow-hidden rounded-none">
                      <button className="py-2.5 px-1  btn-shine w-full h-full rounded-none   font-bold text-base flex items-center justify-center gap-2">
                        Solution <RiBookmark3Fill size={15} />
                      </button>
                    </Tab>
                  </TabList>
                </Tabs>
              </div>
            </div>

            {/*       Card - 3     */}

            <div className=" w-full lg:max-w-[400px] md:max-w-[360px] h-max  transition duration-500 ease-in-out hover:shadow-[0px_6px_0px_0px_#5af]  rounded-2xl   overflow-hidden cursor-pointer">
              <div className="w-full h-[470px] bg-white  rounded-xl     border-none overflow-hidden">
                <Tabs>
                  <TabPanel className="w-full bg-white rounded-xl  border-none h-[420px] overflow-hidden">
                    <div className="   object-cover transition duration-300 ease-in-out hover:opacity-90">
                      <img
                        src="/images/ClientRetn.webp"
                        className=""
                        alt="scdnd estates"
                      />
                    </div>
                    <div className="p-3 pb-2  flex flex-col items-center overflow-x-hidden ">
                      {/* <h2 className=" text-xl font-bold tracking-tight text- uppercase bg-white">Lead Generation Challenges</h2> */}
                      <h3 className="text-gray-800 mb-2 text-xl font-bold tracking-tight  uppercase bg-white text-center">
                        Challenge of Client Retention
                      </h3>
                      <span className="bg-[#5af]  mb-2 inline-block h-0.5 w-[60%] rounded "></span>

                      <p className="  font-semibold bg-white text-base leading-5 text-gray-600 text-center">
                        Insurance contracts (excluding Life/Annuity) are renewed
                        annually, leaving agents vulnerable to potential
                        discontinuation of client relationships and the
                        associated income stream.
                      </p>
                    </div>
                  </TabPanel>

                  <TabPanel className="w-full bg-white rounded-xl  border-none ">
                    <div className="p-4 overflow-scroll bg-white border-none overflow-x-hidden pb-2">
                      <h2 className="mb-3  text-xl font-bold  text-[#212121] ">
                        Leveraging Expertise in Exclusive Financial Products
                      </h2>

                      <ul className="space-y-4 text-gray-500   h-[300px] md:h-[314px] bg-white ">
                        {[
                          "Assets are highly liquid, allowing for fund redemption or relationship termination at any time, but there is no set annual renewal date.",
                          "Due to limited competition and the exclusivity and superiority of these financial products, the likelihood of clients switching to competing assets or fund managers is very low.",
                        ].map((item, index) => (
                          <li
                            key={index}
                            className="flex  space-x-2 rtl:space-x-reverse items-start border-none bg-white"
                          >
                            <svg
                              className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 leading-tight mt-[3px]"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span className="leading-tight bg-white">
                              {item}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </TabPanel>

                  <TabList className="relative left-0 bottom-0 border-t-2 border-blue-400/50  w-full flex justify-evenly  text-sm font-medium text-center text-gray-500  rounded-b-lg bg-gray-400/15 overflow-hidden ">
                    <Tab className=" w-full  hover:bg-blue-400/10 overflow-hidden  rounded-none">
                      <button className=" py-2.5 px-1  w-full h-full rounded-none    font-semibold text-base flex items-center justify-center gap-2">
                        Problem <BsFillQuestionCircleFill size={15} />
                      </button>
                    </Tab>
                    <div className="bg-blue-400/50 w-[5px] z-10"></div>
                    <Tab className=" w-full hover:bg-blue-400/10 overflow-hidden rounded-none">
                      <button className="py-2.5 px-1  btn-shine w-full h-full rounded-none   font-bold text-base flex items-center justify-center gap-2">
                        Solution <RiBookmark3Fill size={15} />
                      </button>
                    </Tab>
                  </TabList>
                </Tabs>
              </div>
            </div>

            {/*       Card - 4     */}

            <div className=" w-full lg:max-w-[400px] md:max-w-[360px] h-max  transition duration-500 ease-in-out hover:shadow-[0px_6px_0px_0px_#5af]  rounded-2xl   overflow-hidden cursor-pointer">
              <div className="w-full h-[470px] bg-white  rounded-xl     border-none overflow-hidden">
                <Tabs>
                  <TabPanel className="w-full bg-white rounded-xl  border-none h-[420px]">
                    <div className="   object-cover transition duration-300 ease-in-out hover:opacity-90">
                      <img
                        src="/images/ClaimDis.webp"
                        className=""
                        alt="scdnd estates"
                      />
                    </div>
                    <div className="p-3 pb-2  flex flex-col items-center overflow-x-hidden ">
                      {/* <h2 className=" text-xl font-bold tracking-tight text- uppercase bg-white">Lead Generation Challenges</h2> */}
                      <h3 className="text-gray-800 mb-3 text-xl font-bold tracking-tight  uppercase bg-white text-center">
                        Disputes during Claim
                      </h3>
                      <span className="bg-[#5af]  mb-3 inline-block h-0.5 w-[60%] rounded "></span>

                      <p className="  font-semibold bg-white text-base leading-5 text-gray-600 text-center">
                        Non-life insurance contracts (excluding Life/Annuity)
                        are complex, leading to conflicts during claims
                        processing and potential client-agent relationship
                        issues affecting payouts.
                      </p>
                    </div>
                  </TabPanel>

                  <TabPanel className="w-full bg-white rounded-xl  border-none ">
                    <div className="p-4 overflow-scroll bg-white border-none overflow-x-hidden pb-2">
                      <h2 className="mb-3  text-xl font-bold  text-[#212121] ">
                        The Boundless Market of Decentralized Real Estate Assets
                      </h2>

                      <ul className="space-y-4 text-gray-500   h-[300px] md:h-[314px] bg-white ">
                        {[
                          "Decentralized Assets are simple and fully transparent financial contracts with only three parameters—risk, return, and liquidity—that need to be explained and compared with existing investments.",
                          "This clarity eliminates potential conflicts and simplifies client retention.",
                        ].map((item, index) => (
                          <li
                            key={index}
                            className="flex  space-x-2 rtl:space-x-reverse items-start border-none bg-white"
                          >
                            <svg
                              className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 leading-tight mt-[3px]"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span className="leading-tight bg-white">
                              {item}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </TabPanel>

                  <TabList className="relative left-0 bottom-0 border-t-2 border-blue-400/50  w-full flex justify-evenly  text-sm font-medium text-center text-gray-500  rounded-b-lg bg-gray-400/15 overflow-hidden ">
                    <Tab className=" w-full  hover:bg-blue-400/10 overflow-hidden  rounded-none">
                      <button className=" py-2.5 px-1  w-full h-full rounded-none    font-semibold text-base flex items-center justify-center gap-2">
                        Problem <BsFillQuestionCircleFill size={15} />
                      </button>
                    </Tab>
                    <div className="bg-blue-400/50 w-[5px] z-10"></div>
                    <Tab className=" w-full hover:bg-blue-400/10 overflow-hidden rounded-none">
                      <button className="py-2.5 px-1  btn-shine w-full h-full rounded-none   font-bold text-base flex items-center justify-center gap-2">
                        Solution <RiBookmark3Fill size={15} />
                      </button>
                    </Tab>
                  </TabList>
                </Tabs>
              </div>
            </div>

            {/*       Card - 5     */}

            <div className=" w-full lg:max-w-[400px] md:max-w-[360px] h-max  transition duration-500 ease-in-out hover:shadow-[0px_6px_0px_0px_#5af]  rounded-2xl   overflow-hidden cursor-pointer">
              <div className="w-full h-[470px] bg-white  rounded-xl     border-none overflow-hidden">
                <Tabs>
                  <TabPanel className="w-full bg-white rounded-xl  border-none h-[420px]">
                    <div className="   object-cover transition duration-300 ease-in-out hover:opacity-90">
                      <img
                        src="/images/InsurancePL.webp"
                        className=""
                        alt="scdnd estates"
                      />
                    </div>
                    <div className="p-3 pb-2  flex flex-col items-center overflow-x-hidden ">
                      {/* <h2 className=" text-xl font-bold tracking-tight text- uppercase bg-white">Lead Generation Challenges</h2> */}
                      <h3 className="text-gray-800 mb-3 text-xl font-bold tracking-tight  uppercase bg-white text-center">
                        Changing Insurance Needs
                      </h3>
                      <span className="bg-[#5af]  mb-3 inline-block h-0.5 w-[60%] rounded "></span>

                      <p className="  font-semibold bg-white text-base leading-5 text-gray-600 text-center">
                        As clients age and accumulate wealth, changing insurance
                        needs and high premiums for life coverage limit new
                        investment opportunities and account rollovers within
                        the industry.
                      </p>
                    </div>
                  </TabPanel>

                  <TabPanel className="w-full bg-white rounded-xl  border-none ">
                    <div className="p-4 overflow-scroll bg-white border-none overflow-x-hidden pb-2">
                      <h2 className="mb-3  text-xl font-bold  text-[#212121] ">
                        Maximizing Value for Maturing Policies
                      </h2>

                      <ul className="space-y-4 text-gray-500  h-[314px] bg-white ">
                        {[
                          "For clients with matured or maturing insurance policies, our financial products provide a seamless transition for their surplus funds.",
                          "These products create new investment opportunities suitable for all economic environments, regardless of the client's age or personal health complications",
                        ].map((item, index) => (
                          <li
                            key={index}
                            className="flex  space-x-2 rtl:space-x-reverse items-start border-none bg-white"
                          >
                            <svg
                              className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 leading-tight mt-[3px]"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            <span className="leading-tight bg-white">
                              {item}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </TabPanel>

                  <TabList className="relative left-0 bottom-0 border-t-2 border-blue-400/50  w-full flex justify-evenly  text-sm font-medium text-center text-gray-500  rounded-b-lg bg-gray-400/15 overflow-hidden ">
                    <Tab className=" w-full  hover:bg-blue-400/10 overflow-hidden  rounded-none">
                      <button className=" py-2.5 px-1  w-full h-full rounded-none    font-semibold text-base flex items-center justify-center gap-2">
                        Problem <BsFillQuestionCircleFill size={15} />
                      </button>
                    </Tab>
                    <div className="bg-blue-400/50 w-[5px] z-10"></div>
                    <Tab className=" w-full hover:bg-blue-400/10 overflow-hidden rounded-none">
                      <button className="py-2.5 px-1  btn-shine w-full h-full rounded-none   font-bold text-base flex items-center justify-center gap-2">
                        Solution <RiBookmark3Fill size={15} />
                      </button>
                    </Tab>
                  </TabList>
                </Tabs>
              </div>
            </div>
          </div>

          <div className="my-4 text-center pt-3 xl:px-20">
            <p className=" just-content-center px-4 pt-3 leading-7 text-sm md:text-base font-semibold">
              In a world where online competition threatens traditional
              insurance sales and client retention, we offer insurance agents
              the opportunity to evolve their role and remain at the forefront
              of the industry. Our financial product is a game-changer, enabling
              agents to provide their clients with innovative, risk-free
              investment options.
            </p>
            <p className="mb-3 just-content-center px-4 pt-1 leading-7 text-sm md:text-base font-semibold">
              Don't let the challenges of low margins, complex products, and
              competition hold you back. Join us in this exciting journey to
              revolutionize the financial industry and secure your position as a
              trusted advisor in the digital age. Together, we can turn the
              challenges of today into the opportunities of tomorrow, ensuring
              that insurance agents continue to be the go-to source for
              financial protection and financial growth for their valued
              clients.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default PLInsuaranceAgent;
