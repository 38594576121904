import React, { useState, useEffect } from "react";
import "./admin.css";
import { TiTick } from "react-icons/ti";
import { MdCloudUpload } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { FaPlus } from "react-icons/fa";
import AlertGreen from "../../src/components/Alerts/AlertGreen.jsx";
import AlertRed from "../../src/components/Alerts/AlertRed.jsx";
import AlertYellow from "../../src/components/Alerts/AlertYellow.jsx";
import Loader from "../components/Loaders/Loader1.jsx";
import CheckAuth from "../components/ServiceFunctions/CheckAuth.jsx";
import { button } from "@material-tailwind/react";

function UserDocuments() {
  const { label } = CheckAuth();
  const [userEnquiry, setUserEnquiry] = useState([]);
  const [open, setOpen] = useState(-1);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [time, setTime] = useState("");
  const [message, setMessage] = useState("");
  const [respond, setRespond] = useState("");
  const [sessions, setSessions] = useState([]);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [showModal5, setShowModal5] = useState(false);
  const [title, setTitle] = useState("");
  const [image, setImage] = useState(null);
  const [content, setContent] = useState("");
  const [author, setAuthor] = useState("");
  const [loader, setLoader] = useState(true);
  const [blogLoader, setblogLoader] = useState(-1);

  const sendEnquiryMail = () => {
    if (respond === "") {
      setShowModal4(true);
      setShowModal2(false);
      setShowModal3(false);
      setShowModal1(false);
      setTimeout(() => {
        setShowModal4(false);
      }, 3000);
      setblogLoader(-1);
    } else {
      fetch(`https://api-scdndestates.com/api/send-enquiry-mail/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, respond, time }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to delete event/reminder");
          }
          return response.json();
        })
        .then((response) => {
          if (response.data === "Success") {
            setShowModal1(true);
            setShowModal2(false);
            setShowModal3(false);
            setShowModal4(false);
            setTimeout(() => {
              setShowModal1(false);
            }, 5000);
            setOpen(-1);
            setPhone("");
            setEmail("");
            setMessage("");
            setRespond("");
            setTime("");
            setblogLoader(-1);
          }
        })
        .catch((error) => {
          // console.error("Error during logout:", error);
        });
    }
    setblogLoader(-1);
  };

  const handleDeleteEnquiry = (email, date) => {
    fetch(`https://api-scdndestates.com/api/delete-enquiry/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, date }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete event/reminder");
        }
        return response.json();
      })
      .then((response) => {
        if (response.message === "Success") {
          setShowModal2(true);
          setShowModal1(false);
          setShowModal3(false);
          setShowModal4(false);
          setTimeout(() => {
            setShowModal2(false);
          }, 5000);
          setUserEnquiry(response.data);
        }
      })
      .catch((error) => {
        // console.error("Error during logout:", error);
      });
  };

  useEffect(() => {
    fetch(`https://api-scdndestates.com/api/logged_in_users/`, {
      method: "POST",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to get the data");
        }
        return response.json();
      })
      .then((response) => {
        setSessions(response.active_sessions);
      })
      .catch((error) => {
        // console.error("Error:", error);
      });
  }, []);

  // Code for Blogs addition
  const [inputValue, setInputValue] = useState("");
  const [tags, setTags] = useState([]);

  const handleAddTag = () => {
    const newTags = inputValue
      .split(",")
      .map((tag) => tag.trim())
      .filter((tag) => tag !== "");
    if (newTags.length > 0) {
      setTags([...tags, ...newTags]);
      setInputValue("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddTag();
    }
  };

  const handleRemoveTag = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
  };

  const handlePostSubmit = () => {
    setblogLoader(1);
    if (image && title && author && tags.length > 0 && content) {
      const formData = new FormData();
      formData.append("image", image);
      formData.append("title", title);
      formData.append("author", author);
      formData.append("keyword", JSON.stringify(tags));
      formData.append("content", content);
      fetch(`https://api-scdndestates.com/api/create_blog_post/`, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to create blog post");
          }
          return response.json();
        })
        .then((response) => {
          setblogLoader(-1);
          setShowModal3(true);
          setShowModal2(false);
          setShowModal1(false);
          setShowModal4(false);
          setTimeout(() => {
            setShowModal3(false);
          }, 5000);
          setTitle("");
          setImage(null);
          setContent("");
          setTags([]);
          setAuthor("");
        })
        .catch((error) => {
          // console.error("Error:", error);
        });
    } else {
      setShowModal2(false);
      setShowModal1(false);
      setShowModal3(false);
      setShowModal4(false);
      setShowModal5(true);
      setTimeout(() => {
        setShowModal5(false);
      }, 5000);
      setblogLoader(-1);
    }
  };

  // Set Date Format
  const formatDate = (dateString) => {
    const date = new Date(dateString); // Convert to Date object
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = date.getDate().toString().padStart(2, "0");
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().slice(-2);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${day}-${month}-${year} (${hours}:${minutes})`;
  };

  const calculateKYCSum = (KYC_status) => {
    return Object.values(KYC_status).reduce((sum, value) => sum + value, 0);
  };

  return (
    <>
      <div className="flex flex-col-reverse md:flex-row justify-between mt-3">
        {/* Left Section */}
        <div className="md:w-[49%] max-md:mt-12">
          <div className="border bg-light shadow-md holiday-list rounded-lg bg-red-100 min-h-[310px] max-h-[310px] md:min-h-[350px] md:max-h-[350px] max-md:mt-4 md:mt-0 overflow-hidden">
            <div className="bg-[#4E83D9] text-white  px-2.5 py-2 rounded-t-lg text-base md:text-lg font-bold flex items-center justify-between">
              <span>Active Users</span>
              <span className="font-semibold text-lg text-black bg-[#DBEDF9] py-1 px-3 rounded-md">
                {sessions.length}{" "}
                <span className="text-black text-base md:text-lg font-bold">
                  / 50
                </span>
              </span>
            </div>
            <div className="min-h-[290px] max-h-[290px] md:h-[350px] h-full overflow-scroll flex flex-col gap-2 md:py-2 pl-2  max-md:pt-2 max-md:pb-10">
              {sessions.map((session, index) => (
                <div className="flex justify-between items-center border rounded-md pl-2">
                  <div key={index} className="flex flex-col">
                    <span className="font-bold text-black text-sm md:text-base">
                      {session.fullName}
                    </span>
                    <p className="text-xs md:text-sm font-semibold text-gray-700">
                      Login Time:{" "}
                      <span className="font-medium">
                        {session.login_time.toLocaleString()}
                      </span>
                    </p>
                  </div>
                  <div className="bg-[#DBEDF9] p-1 flex flex-col items-center justify-center rounded-md text-black">
                    <span className="text-lg md:text-xl font-bold text-center">
                      {calculateKYCSum(session.KYC_status)}%
                    </span>
                    <span className="text-xs font-semibold text-center">
                      Completed
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* User Enquire Details */}
          <div className="relative border p-3 rounded-lg bg-white flex flex-col gap-3 shadow-md mt-4">
            <h2 className="absolute text-sm xl:text-base -top-3 bg-white border px-4 py-1 rounded-lg font-bold text-black w-60">
              <marquee behavior="" direction="">
                Blogs Addition & Edit
              </marquee>
            </h2>
            {/* Title */}
            <div className="mt-4 flex flex-col justify-between gap-3">
              <div className="flex items-center gap-2">
                <label className="block mb-2 text-sm xl:text-base font-bold w-[100px] text-black">
                  Title:
                </label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="w-full px-1 py-1 border rounded-lg"
                />
              </div>
              {/* Image */}
              <div className="text-sm xl:text-base flex  justify-between gap-2">
                <span className="rounded-lg flex items-center gap-2 xl:gap-3 w-full">
                  <label className="block mb-2 text-sm xl:text-base font-bold w-[100px] text-black">
                    Image:
                  </label>
                  <input
                    id="image-profile"
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e)}
                    className="w-full px-1 py-0.5 border rounded-lg"
                  />
                </span>
              </div>
            </div>

            {/* Content */}
            <div className="md:flex gap-2">
              <label className="block mb-2 text-sm xl:text-base font-bold w-[100px] text-black">
                Content:
              </label>
              <textarea
                value={content}
                onChange={(e) => setContent(e.target.value)}
                className="w-full p-1 border rounded-lg h-[250px] md:h-[192px]"
              />
            </div>

            {/* Keywords */}
            <div className="flex flex-col justify-between gap-1">
              <div className="md:flex items-center gap-2 w-full">
                <label className="block mb-2 text-sm xl:text-base font-bold w-[100px] text-black">
                  Keywords:
                </label>
                <div className="flex items-center justify-between w-full">
                  <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className="w-[calc(100%-5.4rem)] md:w-[calc(100%-6rem)] px-2 py-1 border rounded-lg"
                  />
                  <button
                    onClick={handleAddTag}
                    className="ml-2 text-sm p-2.5 bg-blue-500 font-semibold text-white rounded-full"
                  >
                    <FaPlus />
                  </button>
                </div>
              </div>
              <div className="mt-2 flex flex-wrap">
                {tags.map((tag, index) => (
                  <div
                    key={index}
                    className="flex items-center bg-gray-200 rounded-full px-3 py-1 m-1"
                  >
                    <span className="mr-2 text-sm">{tag}</span>
                    <button
                      onClick={() => handleRemoveTag(index)}
                      className="text-red-500"
                    >
                      <IoClose />
                    </button>
                  </div>
                ))}
              </div>
            </div>

            {/* Author */}
            <div className="flex gap-2 items-end justify-between mb-3 w-full">
              <div className="md:flex gap-2 items-center w-full">
                <label className="block mb-2 text-sm xl:text-base font-bold w-[100px] text-black">
                  Author:
                </label>
                <input
                  type="text"
                  value={author}
                  onChange={(e) => setAuthor(e.target.value)}
                  className="w-full px-2 py-1 md:py-2 border rounded-lg md:ml-2 xl:ml-0 "
                />
              </div>
              {blogLoader === 1 ? (
                <button className="button-excelsmall w-[100px] py-1 animationIcon-shine-blue h-8">
                  <img
                    key="1"
                    className="h-6"
                    src="/images/btn-transparent.gif"
                    alt={""}
                    autoPlay
                    loop
                    muted
                  />
                </button>
              ) : (
                <button
                  className="button-excelsmall w-[100px] py-1 animationIcon-shine-blue h-8"
                  onClick={() => handlePostSubmit()}
                >
                  <i className="text-base md:text-lg">
                    <span>
                      <svg
                        className="icon w-3.5 h-3.5 fill-white"
                        version="1.1"
                        id="fi_724954"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsHlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 448.011 448.011"
                        xmlSpace="preserve"
                      >
                        <g>
                          <g>
                            <path
                              d="M438.731,209.463l-416-192c-6.624-3.008-14.528-1.216-19.136,4.48c-4.64,5.696-4.8,13.792-0.384,19.648l136.8,182.4
			l-136.8,182.4c-4.416,5.856-4.256,13.984,0.352,19.648c3.104,3.872,7.744,5.952,12.448,5.952c2.272,0,4.544-0.48,6.688-1.472
			l416-192c5.696-2.624,9.312-8.288,9.312-14.528S444.395,212.087,438.731,209.463z"
                            ></path>
                          </g>
                        </g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                      </svg>
                    </span>
                  </i>
                  <p className="font-semibold">Post</p>
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Right Section */}

        {/* {label === "Admin" && ( */}
        <div className="md:w-[49%]  border rounded-lg relative p-3 bg-white shadow-md">
          <p className="absolute -top-3 bg-white px-4 py-1 rounded-lg font-bold border text-black text-sm xl:text-base w-60">
            <marquee behavior="" direction="">
              User Enquiry Details
            </marquee>
          </p>
          <div className="mt-4 overflow-scroll ">
            <div className="min-h-[610px] max-h-[610px] min-w-[650px]">
              <table className="table-borderless table-sm w-full h-full">
                <thead className="text-sm">
                  <tr className=" bg-[#4E83D9] text-white">
                    <th scope="col" className="fw-bolder text-center">
                      Enquiry Date
                    </th>
                    <th scope="col" className="fw-bolder text-center">
                      Name
                    </th>
                    <th scope="col" className="fw-bolder text-center">
                      Topic
                    </th>
                    <th scope="col" className="fw-bolder text-center">
                      Action
                    </th>
                    <th scope="col" className="fw-bolder text-center">
                      Solved
                    </th>
                  </tr>
                </thead>
                {loader ? (
                  <tbody className="min-h-[610px] mt-5 max-h-[610px] min-w-[650px]">
                    {[...Array(7)].map((_, index) => (
                      <tr className=" rounded-lg">
                        <td className="text-center p-1">
                          <div className="rounded-md h-7 bg-gray-200  animate-pulse"></div>
                        </td>
                        <td className="text-center p-1">
                          <div className="rounded-md h-7 bg-gray-200  animate-pulse"></div>
                        </td>
                        <td className="text-center p-1">
                          <div className="rounded-md h-7 bg-gray-200  animate-pulse"></div>
                        </td>
                        <td className="text-center p-1">
                          <div className="rounded-md h-7 bg-gray-200  animate-pulse"></div>
                        </td>
                        <td className="text-center p-1">
                          <div className="rounded-md h-7 bg-gray-200  animate-pulse"></div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <>
                    {userEnquiry > "" ? (
                      userEnquiry?.map((data, index) => (
                        <tbody className="text-sm">
                          <tr className="border hover:bg-zinc-100 duration-200 text-black font-semibold">
                            <td className="text-center">
                              {formatDate(data.date)}
                            </td>
                            <td className="text-center">{data.Sender}</td>
                            <td className="text-center">{data.subject}</td>
                            <td className="text-center">
                              <span
                                className="btn btn-secondary btn-sm font-bold"
                                onClick={() => {
                                  setOpen(index);
                                  setPhone(data.phone);
                                  setEmail(data.email);
                                  setTime(data.date);
                                  setMessage(data.message);
                                }}
                              >
                                View
                              </span>
                            </td>
                            <td className="text-center flex justify-center cursor-pointer">
                              <span
                                className="text-success"
                                onClick={() => {
                                  handleDeleteEnquiry(data.email, data.date);
                                }}
                              >
                                <TiTick size={30} />
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      ))
                    ) : (
                      <tbody className="absolute -translate-x-[50%] -translate-y-[50%] top-[50%] left-[50%] ">
                        No Data Found...
                      </tbody>
                    )}
                  </>
                )}
              </table>
            </div>
          </div>
          {open !== -1 && (
            <div className="demo2-container ">
              <div className="w-[95%] md:w-[60%] lg:w-[50%] xl:w-[36%] 2xl:w-[25%] pop-up-animation ">
                <div className="row  m-0 p-2 text-black bg-white border-b-2 rounded-t-lg">
                  <div className="flex items-center justify-between py-1">
                    <span className="font-bold text-sm md:text-base flex gap-2 items-center ">
                      <span className="">
                        <svg
                          className="w-6 h-6"
                          version="1.1"
                          id="fi_542638"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsHlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 512 512"
                          xmlSpace="preserve"
                        >
                          <g>
                            <g>
                              <path
                                d="M467,76H45C20.238,76,0,96.149,0,121v270c0,24.86,20.251,45,45,45h422c24.762,0,45-20.149,45-45V121
			C512,96.143,491.752,76,467,76z M460.09,106c-14.549,14.597-185.445,186.05-192.466,193.094c-5.864,5.882-17.381,5.886-23.248,0
			L51.91,106H460.09z M30,385.485v-258.97L159.065,256L30,385.485z M51.91,406l128.334-128.752l42.885,43.025
			c17.574,17.631,48.175,17.624,65.743,0l42.885-43.024L460.09,406H51.91z M482,385.485L352.935,256L482,126.515V385.485z"
                              ></path>
                            </g>
                          </g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                        </svg>
                      </span>
                      Message
                    </span>
                    <span className="text-white cursor-pointer">
                      <div
                        onClick={() => {
                          setOpen(-1);
                          setPhone("");
                          setEmail("");
                          setMessage("");
                          setRespond("");
                        }}
                        className=" flex justify-end  rounded-full "
                      >
                        <button
                          className="circleadmin bg-white"
                          data-animation="showShadow"
                          data-remove="3000"
                        ></button>
                      </div>
                    </span>
                  </div>
                </div>
                <div className="bg-light p-3 text-black rounded-b-lg text-sm md:text-base">
                  <p className=" flex items-start">
                    <span className="w-[100px] font-semibold">Email ID : </span>
                    <span className=" text-black w-[80%]">{email}</span>
                  </p>
                  <p className=" flex items-start">
                    <span className="w-[100px] font-semibold">Phone No : </span>
                    <span className=" text-black w-[80%]">{phone}</span>
                  </p>
                  <p className=" flex flex-col items-start">
                    <span className="w-[100px] font-semibold">Message: </span>
                    <span className=" text-black leading-[20px] border rounded-md p-1.5 w-full mt-2">
                      {message}
                    </span>
                  </p>
                  <p className="font-semibold mt-2">Admin Respond : </p>
                  <textarea
                    name=""
                    id=""
                    value={respond}
                    rows={5}
                    placeholder="Enter your text here..."
                    className="w-100 p-2 border rounded-lg mt-2"
                    required
                    onChange={(e) => {
                      setRespond(e.target.value);
                    }}
                  ></textarea>
                  <div className="row m-0 p-0 pt-3 border-top mt-2">
                    <div className="d-flex justify-content-end">
                      {blogLoader === 2 ? (
                        <button className="button-excelsmall w-[80px] py-1.5 animationIcon-shine-blue">
                          <img
                            key="1"
                            className="h-6"
                            src="/images/btn-transparent.gif"
                            alt={""}
                            autoPlay
                            loop
                            muted
                          />
                        </button>
                      ) : (
                        <button
                          className="button-excelsmall w-[80px] py-1.5 animationIcon-shine-blue"
                          onClick={() => {
                            sendEnquiryMail();
                            setblogLoader(2);
                          }}
                        >
                          <i className="text-base md:text-lg">
                            <span>
                              <svg
                                className="icon w-3 h-3 fill-white"
                                version="1.1"
                                id="fi_724954"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsHlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                viewBox="0 0 448.011 448.011"
                                xmlSpace="preserve"
                              >
                                <g>
                                  <g>
                                    <path
                                      d="M438.731,209.463l-416-192c-6.624-3.008-14.528-1.216-19.136,4.48c-4.64,5.696-4.8,13.792-0.384,19.648l136.8,182.4
			l-136.8,182.4c-4.416,5.856-4.256,13.984,0.352,19.648c3.104,3.872,7.744,5.952,12.448,5.952c2.272,0,4.544-0.48,6.688-1.472
			l416-192c5.696-2.624,9.312-8.288,9.312-14.528S444.395,212.087,438.731,209.463z"
                                    ></path>
                                  </g>
                                </g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                              </svg>
                            </span>
                          </i>
                          <p className="font-semibold">Post</p>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* )} */}
      </div>
      {showModal1 && (
        <AlertGreen
          message="Message sent successfully"
          onClose={() => setShowModal1(false)}
        />
      )}
      {showModal2 && (
        <AlertGreen
          message="Enquiry deleted successfully"
          onClose={() => setShowModal2(false)}
        />
      )}
      {showModal3 && (
        <AlertGreen
          message="Blog post created. Check the formatting on Frontend"
          onClose={() => setShowModal3(false)}
        />
      )}
      {showModal4 && (
        <AlertYellow
          message="Write a message to send"
          onClose={() => setShowModal4(false)}
        />
      )}
      {showModal5 && (
        <AlertYellow
          message="All inputs are compulsory"
          onClose={() => setShowModal5(false)}
        />
      )}
    </>
  );
}

export default UserDocuments;
