const PartnershipApi = [
  {
    id: 1,
    heading: "REAL ESTATE BROKER",
    image: "/images/REbroker.webp",
    route: "/real-state-broker",
    info: "In the rapidly changing technology of online advertisement and buyer-seller matching algorithms, traditional agents who spent years nurturing relationships with investors, builders and retail clients are facing unprecedented challenges.",
  },
  {
    id: 2,
    heading: "STOCK BROKER",
    image: "/images/Stockbroker.webp",
    route: "/stock-broker",
    info: "The emergence of discount brokerages offering fast App-based trading have disrupted the commission model of traditional brokers but also shifted the dynamics of customer engagement away from research recommendations and investor education.",
  },

  {
    id: 3,
    heading: "MUTUAL FUND DISTRIBUTOR",
    image: "/images/Mutualfunds.webp",
    route: "/mutual-fund",
    info: "The mutual fund distribution industry is being disrupted by new online players offering direct plans and increased choice to customers. Individuals depending on trust-based relationships and deep understanding of client's needs are facing a challenge in promoting regular plans.",
  },

  {
    id: 4,
    heading: "FINANCIAL INFLUENCER",
    image: "/images/Influence-logo.webp",
    route: "/financial-inf",
    info: "Investment Advisors play a vital role in educating and guiding their audiences through the complex world of finance. However, they face their own set of challenges in keeping their content fresh and engaging if the underlying universe of products remains the same!",
  },
  {
    id: 5,
    heading: "INSURANCE AGENT",
    image: "/images/InfAgent.webp",
    route: "/insurance-agent",
    info: "Digital platforms have disrupted the industry by offering the widest variety of insurance products at razor-thin commissions. Furthermore, intricacies of insurance policies, multiple sum assured options, and lengthy claim processes can make selling insurance a daunting task.",
  },
  {
    id: 6,
    heading: "FOREIGN PORTFOLIO",
    image: "/images/VentureCap.webp",
    route: "/foreign-portfolio",
    info: "FPIs and FII are being more favourable towards a giant emerging economy like India as an investment destination, but our capital market infrastructure, contractual dispute resolution, speed of bankruptcy resolution and innovation in general have lagged behind.",
  },
];

export default PartnershipApi;
