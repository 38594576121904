const EmployeeInfoApp = [
  {
    id: 0,
    name: "Chandan Kumar",
    designation: "Frontend Developer",
    email: "info.chandan16@gmail.com",
  },
  {
    id: 1,
    name: "Nitish Pradhan",
    designation: "Frontend Developer",
    email: "frontend.scdnd@gmail.com",
  },
  {
    id: 2,
    name: "Reshma Ranjan",
    designation: "Web Developer",
    email: "webinfra.scdnd@gmail.com",
  },
  {
    id: 3,
    name: "Nitesh Mishra",
    designation: "Fullstack Developer",
    email: "digital.scdnd@gmail.com",
  },
  {
    id: 4,
    name: "Jyoti",
    designation: "Compliance Officer",
    email: "compliance.scdnd@gmail.com",
  },
  {
    id: 5,
    name: "Saurabh Kumar",
    designation: "Quality & Statistics",
    email: "statistics09.scdnd@gmail.com",
  },
  {
    id: 6,
    name: "Rohit Kashyap",
    designation: "Marketing Director",
    email: "buwch.sales@gmail.com",
  },
];
export default EmployeeInfoApp;
