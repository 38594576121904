import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home/Home";
import Products from "../pages/Products/Products";
import Partnerships from "../pages/Partnerships/Partnerships";
import Login from "../pages/Login/Login.jsx";
import ContactUs from "../pages/ContactUs/ContactUs.jsx";
import Register from "../pages/Register/Register.jsx";
import CalculateRisk from "../pages/RiskSensor/CalculateRisk.jsx";
import RiskSensor from "../pages/RiskSensor/RiskSensor.jsx";
import PLRealStateBroker from "../pages/Partnerships/PLRealStateBroker.jsx";
import PLInsuranceAgent from "../pages/Partnerships/PLInsuranceAgent.jsx";
import PLFinancialInf from "../pages/Partnerships/PLFinancialInf.jsx";
import PLMutualFund from "../pages/Partnerships/PLMutualFund.jsx";
import PLStockBroker from "../pages/Partnerships/PLStockBroker.jsx";
import PLForeignPortfolio from "../pages/Partnerships/PLForeignPortfolio.jsx";
import WALROverview from "../pages/WALRBonds/WALROverview.jsx";
import WALRPerformance from "../pages/WALRBonds/WALRPerformance.jsx";
import RESIDEXOverview from "../pages/RESIDEXBonds/RESIDEXOverview.jsx";
import RESIDEXPerformance from "../pages/RESIDEXBonds/RESIDEXPerformance.jsx";
import TestPage from "../pages/PartnershipTest/TestPage.jsx";
import CustodianAC from "../pages/Custodian/Custodian.jsx";
import Ownership from "../pages/Ownership/Ownership.jsx";
import WALRHoldings from "../pages/Ownership/WALRHoldings.jsx";
import RESIDEXHoldings from "../pages/Ownership/RESIDEXHoldings.jsx";
import OurTeam from "./OurTeam/OurTeam.jsx";
import Pricing from "../pages/Pricing/Pricing.jsx";
import MoneyWallet from "../pages/MoneyWallet/MoneyWallet.jsx";
import PrimaryMarket from "../pages/PrimaryMarket/PrimaryMarket.jsx";
import TradingPortal from "../pages/TradingPortal/TradingPortal.jsx";
import UserKYCForm from "../pages/UserKYCForm/UserKYCForm.jsx";
import BlogsPage from "../pages/Blogs/Blogs.jsx";
import Blogs2 from "../pages/Blogs/Blogs2.jsx";
import Career from "../pages/LegalDocument/Career.jsx";
import KeyRatio from "../pages/CompanyInfo/KeyRatio.jsx";
import Shareholdings from "../pages/CompanyInfo/Shareholdings.jsx";
import AnnualReports from "../pages/CompanyInfo/AnnualReports.jsx";
import Coming from "../pages/Extras/Coming.jsx";
import Profile from "../pages/Profile/Profile.jsx";
import FinancialStatements from "../pages/CompanyInfo/FinancialStatements.jsx";
import ChangePassword from "../pages/ChangePassword/ChangePassword.jsx";
import ForgetPassword from "../pages/ChangePassword/ForgetPassword.jsx";
import PaymentPage from "../pages/MoneyWallet/PaymentPage.jsx";
import WithdrawPage from "../pages/MoneyWallet/WithdrawPage.jsx";
import StatementPage from "../pages/MoneyWallet/StatementPage.jsx";
import Page404 from "../pages/Extras/Page404.jsx";
import Page401 from "../pages/Extras/Page401.jsx";
import AdminDashboard from "../Admin/Admin.jsx";
import Error404 from "../pages/Extras/Error404.jsx";
import MarketAnimation from "../pages/Home/MarketAnimation.jsx";
import PitchDeck from "../pages/PitchDeck.jsx/PitchDeck.jsx";
import CapitalGain from "../pages/CapitalGain/CapitalGain.jsx";
import Disclosures from "../pages/LegalDocument/Disclosures.jsx";
import TermsConditions from "../pages/LegalDocument/TermsConditions.jsx";
import AssetManagement from "../pages/LegalDocument/AssetManagement.jsx";
import PrivacyPolicy from "../pages/LegalDocument/PrivacyPolicy.jsx";

function AllRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<Products />} />
        <Route path="/partnerships" element={<Partnerships />} />
        <Route path="/risksensor" element={<RiskSensor />} />
        <Route path="/calculaterisk" element={<CalculateRisk />} />
        <Route path="/real-state-broker" element={<PLRealStateBroker />} />
        <Route path="/stock-broker" element={<PLStockBroker />} />
        <Route path="/mutual-fund" element={<PLMutualFund />} />
        <Route path="/financial-inf" element={<PLFinancialInf />} />
        <Route path="/insurance-agent" element={<PLInsuranceAgent />} />
        <Route path="/foreign-portfolio" element={<PLForeignPortfolio />} />
        <Route path="/walrbonds/overview" element={<WALROverview />} />
        <Route path="/walrbonds/performance" element={<WALRPerformance />} />
        <Route path="/residexbonds/overview" element={<RESIDEXOverview />} />
        <Route
          path="/residexbonds/performance"
          element={<RESIDEXPerformance />}
        />
        <Route path="/disclosures" element={<Disclosures />} />
        <Route path="/terms & conditions" element={<TermsConditions />} />
        <Route path="/asset management risks" element={<AssetManagement />} />
        <Route path="/privacy policy" element={<PrivacyPolicy />} />
        <Route path="/career" element={<Career />} />
        <Route path="/ourteam" element={<OurTeam />} />
        <Route path="/blogs" element={<Blogs2 />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/blogsPage/:title/" element={<BlogsPage />} />
        <Route path="/:title/taketest" element={<TestPage />} />
        <Route path="/custodianAC/:page" element={<CustodianAC />} />
        <Route path="/PrimaryMarket/:page" element={<PrimaryMarket />} />
        <Route path="/pricing/:page" element={<Pricing />} />
        <Route path="/Ownership" element={<Ownership />} />
        <Route path="/WALR-holdings" element={<WALRHoldings />} />
        <Route path="/RESIDEX-holdings" element={<RESIDEXHoldings />} />
        <Route path="/MoneyWallet" element={<MoneyWallet />} />
        <Route path="/TradingPortal" element={<Error404 />} />
        <Route path="/investment_advisory" element={<Error404 />} />
        <Route path="/fund_management" element={<Error404 />} />
        <Route path="/merchant_banking" element={<Error404 />} />
        <Route path="/about_us" element={<Error404 />} />
        <Route path="/UserKycForm" element={<UserKYCForm />} />
        <Route path="/paymentPage" element={<PaymentPage />} />
        <Route path="/withdrawPage" element={<WithdrawPage />} />
        <Route path="/statementPage" element={<StatementPage />} />
        <Route path="/changePassword" element={<ChangePassword />} />
        <Route path="/keyRatios" element={<KeyRatio />} />
        <Route path="/shareholdings" element={<Shareholdings />} />
        <Route path="/annualReports" element={<AnnualReports />} />
        <Route path="/financialStatements" element={<FinancialStatements />} />
        <Route path="/accessDenied" element={<Page401 />} />
        <Route path="/userLost" element={<Page404 />} />
        <Route path="/comingsoon" element={<Coming />} />
        <Route path="/adminDashboard" element={<AdminDashboard />} />
        <Route path="/agentDashboard" element={<Error404 />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgetPassword" element={<ForgetPassword />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/Error404" element={<Error404 />} />
        <Route path="/MarketAnimation" element={<MarketAnimation />} />
        <Route path="/pitchdeck" element={<PitchDeck />} />
        <Route path="/CapitalGain" element={<CapitalGain />} />
      </Routes>
    </>
  );
}

export default AllRoutes;
