import React, { useState } from "react";
import Header from "../../components/Header/Header";
import { IoIosMail } from "react-icons/io";
import { BiSolidPhoneCall } from "react-icons/bi";
import "./Contactus1.css";
import { RiAdminFill } from "react-icons/ri";
import { Helmet } from "react-helmet";

function ContactUs() {
  // whatsapp api
  const WhatsApp_API = [
    {
      value: "Coupon payout to bank account",
      whatsappMessage:
        "I want to know more about Coupon payout to bank account",
    },
    {
      value: "KYC Completion or update",
      whatsappMessage: "I want to discuss KYC Completion or update",
    },
    {
      value: "Transaction failure or reversal",
      whatsappMessage:
        "Can you provide information about Transaction failure or reversal",
    },
    {
      value: "Adding funds from money wallet",
      whatsappMessage:
        "Can you provide information about Adding funds from money wallet",
    },
    {
      value: "Features of decentralized assets",
      whatsappMessage:
        "I am interested in learning about Features of decentralized assets",
    },
    {
      value: "Understanding our products",
      whatsappMessage:
        "I am interested in learning about Understanding our products",
    },
    {
      value: "Others, Please specify below.",
      whatsappMessage: "I have some Others Queries",
    },
  ];

  const [selectedValue, setSelectedValue] = useState("");
  const phoneNumber = "1234567890"; // Replace with your WhatsApp number

  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;
    const selectedItem = WhatsApp_API.find(
      (item) => item.value === selectedOption
    );

    if (selectedItem) {
      const encodedMessage = encodeURIComponent(selectedItem.whatsappMessage);
      const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
      window.location.href = whatsappURL;
    }
  };

  // Location API
  const location = [
    {
      id: 1,
      right: "32%",
      top: "45%",
      address: "Registered Office",
      description: "Flat 1/1A Clubtown Greens 167 Jessore Road Kolkata- 700055",
    },
    {
      id: 2,
      left: "45%",
      top: "35%",
      address: "Corporate Office",
      description:
        "C-267 Ground Floor 5th Cross Street Swarn Nagri Greater Noida Uttar Pradesh- 201310",
    },
  ];

  const [activeLocation, setActiveLocation] = useState(2);

  const handleLocationClick = (locationId) => {
    setActiveLocation(activeLocation === locationId ? null : locationId);
  };
  return (
    <>
      <Helmet>
        <title>Customer Care or Helpdesk - Contact us | SCDND Estates</title>
        <meta
          name="description"
          content="Send us a message. Our representatives will contact you shortly. Coupon payout to Bank Account, KYC completion or update, Transaction failure or reversal, Adding of funds from Money Wallet."
        />
        <meta
          name="description"
          content="Users can lodge complaints, ask for clarifications and leave general enquiries on any variety of subjects related to portal use or decentralized assets in general, by leaving your Email and Mobile and describing your query."
        />

        <link rel="canonical" href="https://scdndestates.com/contactus" />
        {/* Open Graph */}
        <meta
          property="og:title"
          content="Customer Care or Helpdesk - Contact us"
        />
        <meta
          property="og:description"
          content="Send us a message. Our representatives will contact you shortly. Coupon payout to Bank Account, KYC completion or update, Transaction failure or reversal, Adding of funds from Money Wallet."
        />

        <meta property="og:url" content="https://scdndestates.com/contactus" />
        <meta property="og:type" content="website" />
        {/* twitter Graph */}
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="Customer Care or Helpdesk - Contact us"
        />
        <meta
          name="twitter:description"
          content="Send us a message. Our representatives will contact you shortly. Coupon payout to Bank Account, KYC completion or update, Transaction failure or reversal, Adding of funds from Money Wallet."
        />
        <meta name="twitter:domain" content="scdndestates.com" />
      </Helmet>
      <Header />
      <div className="after-header max-w-[1800px] mx-auto ">
        <div className="w-[90%] xl:w-[80%] mx-auto text-black rounded-lg flex flex-wrap justify-between shadow-md mt-4 ">
          {/* Left container */}
          <div className="w-full bg-zinc-50 p-[5%] xl:p-[3%] rounded-lg border">
            <h2 className="text-xl md:text-2xl sm:text-3xl font-bold">
              Get in touch
            </h2>
            <p className="font-semibold text-gray-700 text-xs sm:text-sm mt-2">
              If you have any queries kindly take a moment to fill up this form,
              Our representatives will contact you shortly.
            </p>
            <div className="lg:flex items-center justify-between gap-2">
              <div className="flex lg:w-[40%] flex-col justify-between gap-2 mt-4">
                {/* whats app start */}
                <div className="w-full">
                  <div className="flex flex-col bg-[#ffffff] justify-center items-center relative border p-3 rounded-lg">
                    <span>
                      <svg
                        className="w-14 h-14"
                        viewBox="0 0 176 176"
                        xmlns="http://www.w3.org/2000/svg"
                        id="fi_3670051"
                      >
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="_09.whatsapp" data-name="09.whatsapp">
                            <circle
                              id="background"
                              cx="88"
                              cy="88"
                              fill="#29a71a"
                              r="88"
                            ></circle>
                            <g id="icon" fill="#fff">
                              <path d="m126.8 49.2a54.57 54.57 0 0 0 -87.42 63.13l-5.79 28.11a2.08 2.08 0 0 0 .33 1.63 2.11 2.11 0 0 0 2.24.87l27.55-6.53a54.56 54.56 0 0 0 63.09-87.21zm-8.59 68.56a42.74 42.74 0 0 1 -49.22 8l-3.84-1.9-16.89 4 .05-.21 3.5-17-1.88-3.71a42.72 42.72 0 0 1 7.86-49.59 42.73 42.73 0 0 1 60.42 0 2.28 2.28 0 0 0 .22.22 42.72 42.72 0 0 1 -.22 60.19z"></path>
                              <path d="m116.71 105.29c-2.07 3.26-5.34 7.25-9.45 8.24-7.2 1.74-18.25.06-32-12.76l-.17-.15c-12.09-11.21-15.23-20.54-14.47-27.94.42-4.2 3.92-8 6.87-10.48a3.93 3.93 0 0 1 6.15 1.41l4.45 10a3.91 3.91 0 0 1 -.49 4l-2.25 2.92a3.87 3.87 0 0 0 -.35 4.32c1.26 2.21 4.28 5.46 7.63 8.47 3.76 3.4 7.93 6.51 10.57 7.57a3.82 3.82 0 0 0 4.19-.88l2.61-2.63a4 4 0 0 1 3.9-1l10.57 3a4 4 0 0 1 2.24 5.91z"></path>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </span>
                    <h2 className="text-base sm:text-lg font-bold mt-2 text-black">
                      Chat on WhatsApp
                    </h2>
                    <p className="rounded-full flex items-center bg-zinc-100">
                      <span className="bg-[#5AB2FF] p-1 h-full text-base sm:text-xl rounded-full text-white">
                        <BiSolidPhoneCall />
                      </span>
                      <span className="py-1 pl-2 pr-3 font-bold text-xs sm:text-sm">
                        (+91) - 9163213191
                      </span>
                    </p>
                    <p className="rounded-full flex items-center bg-zinc-100 mt-2 w-[60%] md:w-[50%] lg:w-[60%]">
                      <span className="bg-[#5AB2FF] h-full p-1 md:p-1.5 text-base sm:text-xl rounded-full text-white">
                        <span>
                        <svg className="w-[18px] h-[18px]" enable-background="new 0 0 24 24" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg" id="fi_3601885"><circle cx="12" cy="12" r="5"></circle><path d="m23 11h-1.051c-.47-4.717-4.232-8.479-8.949-8.949v-1.051c0-.552-.447-1-1-1s-1 .448-1 1v1.051c-4.717.47-8.479 4.232-8.949 8.949h-1.051c-.553 0-1 .448-1 1s.447 1 1 1h1.051c.471 4.717 4.232 8.479 8.949 8.949v1.051c0 .552.447 1 1 1s1-.448 1-1v-1.051c4.717-.471 8.479-4.232 8.949-8.949h1.051c.553 0 1-.448 1-1s-.447-1-1-1zm-10.014 8.933c-.036-.519-.457-.933-.986-.933s-.95.414-.986.933c-3.622-.448-6.498-3.324-6.946-6.946.519-.037.932-.459.932-.987s-.413-.95-.933-.986c.448-3.622 3.324-6.498 6.946-6.946.037.518.458.932.987.932s.95-.414.986-.933c3.622.448 6.498 3.324 6.946 6.946-.519.037-.932.459-.932.987s.413.95.933.986c-.449 3.623-3.324 6.498-6.947 6.947z"></path></svg>
                          
                          
                        </span>
                      </span>
                      <div className="px-0.5">
                        <select
                          value={selectedValue}
                          onChange={handleSelectChange}
                          className=" font-bold text-xs md:text-sm outline-none bg-zinc-100 w-full"
                        >
                          <option value="">Select an option</option>
                          {WhatsApp_API.map((item) => (
                            <option
                              key={item.value}
                              value={item.value}
                              className="word-wrap"
                            >
                              {item.value.replace(/-/g, " ").toUpperCase()}
                            </option>
                          ))}
                        </select>
                      </div>
                    </p>
                  </div>
                </div>
                {/* Whatsapp end */}
                <div className="w-full ">
                  <div className="flex flex-col justify-center items-center relative bg-white border p-3 rounded-lg">
                    <img
                      src="/images/customer-care.webp"
                      alt=""
                      className="w-[70px]"
                    />
                    <h2 className="text-base sm:text-lg font-bold mt-2 text-black">
                      Customer Care
                    </h2>
                    <div className="flex flex-col items-center text-xs sm:text-base font-bold mt-2 gap-2">
                      <p className="rounded-full flex items-center bg-zinc-100">
                        <span className="bg-[#5AB2FF] p-1 h-full text-base sm:text-xl rounded-full text-white">
                          <BiSolidPhoneCall />
                        </span>
                        <span className="py-1 pl-2 pr-3 text-xs sm:text-sm">
                          (+91) - 1203180111
                        </span>
                      </p>
                      <p className="rounded-full flex items-center bg-zinc-100">
                        <span className="bg-[#5AB2FF] h-full p-1 text-base sm:text-xl rounded-full text-white">
                          <IoIosMail />
                        </span>
                        <span className="py-1 pl-3 pr-4 text-xs sm:text-sm break-all">
                          helpdesk@scdndestates.com
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="w-full ">
                  <div className="flex flex-col justify-center items-center relative bg-white border p-3 rounded-lg">
                    <img
                      src="/images/compliance.webp"
                      alt=""
                      className="w-[70px]"
                    />
                    <h2 className="text-base sm:text-lg font-bold mt-2 text-black">
                      Compliance
                    </h2>
                    <div className="flex flex-col items-center text-xs sm:text-base font-bold mt-2 gap-2">
                      <p className="rounded-full flex items-center bg-zinc-100">
                        <span className="bg-[#5AB2FF] p-1 h-full text-base sm:text-xl rounded-full text-white">
                          <BiSolidPhoneCall />
                        </span>
                        <span className="py-1 pl-2 pr-3 text-xs sm:text-sm">
                          (+91) - 9953140287
                        </span>
                      </p>
                      <p className="rounded-full flex items-center bg-zinc-100">
                        <span className="bg-[#5AB2FF] p-1 h-full text-base sm:text-xl rounded-full text-white">
                          <RiAdminFill />
                        </span>
                        <span className="py-1 pl-2 pr-3 text-xs sm:text-sm">
                          Officer-in-charge - Jyoti
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:w-[57%] flex items-center justify-center relative mt-5 lg:mt-0">
                <img
                  src="./images/indiamap.svg"
                  className="w-full md:w-[92%]"
                  alt="India Map"
                />

                {location.map((location) => (
                  <div
                    key={location.id}
                    className="absolute group"
                    style={{
                      right: location.right,
                      left: location.left,
                      top: location.top,
                    }}
                  >
                    <div
                      onClick={() => handleLocationClick(location.id)}
                      className="cursor-pointer relative"
                    >
                      <img
                        src="./images/location.svg"
                        className={`w-5 h-5 ${
                          activeLocation === location.id ? "animate-bounce" : ""
                        }`}
                        alt="Location Marker"
                      />

                      {activeLocation === location.id && (
                        <div className="absolute z-10 top-full left-1/2 transform -translate-x-1/2 mt-2 bg-white shadow-lg rounded-lg p-2.5 border border-gray-200 min-w-[200px] animate-fade-in-up">
                          <h3 className="font-bold text-sm mb-1">
                            {location.address}
                          </h3>
                          <p className="text-xs text-gray-600">
                            {location.description}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
