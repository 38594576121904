import React, { useState, useEffect, useRef } from "react";
import AgentInfoApp from "../API/AgentInfoApi";
import { IoMdArrowDropdownCircle } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { IoMdAddCircle } from "react-icons/io";
import AlertGreen from "../../src/components/Alerts/AlertGreen.jsx";
import Loader from "../components/Loaders/Loader1.jsx";
import { FileText, ChevronDown, ChevronUp, Calendar, Lock } from "lucide-react";

function AgentsCommissions() {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [combinedData, setCombinedData] = useState([]);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data_193, setData_193] = useState([]);
  const [data_195, setData_195] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [show, setShow] = useState(-1);
  const [show2, setShow2] = useState("");
  const [show3, setShow3] = useState(false);
  const [payDate, setPayDate] = useState("");
  const [invoice, setInvoice] = useState("");
  const [utrNo, setUtrNo] = useState("");
  const [payAmount, setPayAmount] = useState(0);
  const [selectedTab, setSelectedTab] = useState("section_193");
  const [showModal1, setShowModal1] = useState(false);
  const [loader, setLoader] = useState(true);

  const handleChange = (event) => {
    setSelectedTab(event.target.id);
  };

  function date_to_quarter(date) {
    const year = new Date(date).getFullYear();
    const month = new Date(date).getMonth() + 1; // Get month (1-12)

    // Adjust month for fiscal quarters starting in April
    let fiscalYear = year;
    let quarter;

    if (month >= 4 && month <= 6) {
      quarter = 1; // April to June
    } else if (month >= 7 && month <= 9) {
      quarter = 2; // July to September
    } else if (month >= 10 && month <= 12) {
      quarter = 3; // October to December
    } else {
      quarter = 4; // January to March
      fiscalYear -= 1; // Since it's Q4, we subtract 1 from the fiscal year
    }

    return `Q${quarter}FY${fiscalYear}-${(fiscalYear + 1) % 100}`;
  }

  function date_to_quarter2(date) {
    const year = new Date(date).getFullYear();
    const month = new Date(date).getMonth() + 1; // Get month (1-12)

    // Adjust month for fiscal quarters starting in April
    let fiscalYear = year;
    let quarter;

    if (month >= 4 && month <= 6) {
      quarter = 1; // April to June
    } else if (month >= 7 && month <= 9) {
      quarter = 2; // July to September
    } else if (month >= 10 && month <= 12) {
      quarter = 3; // October to December
    } else {
      quarter = 4; // January to March
      fiscalYear -= 1; // Since it's Q4, we subtract 1 from the fiscal year
    }

    return `Quarter ${quarter}`;
  }

  useEffect(() => {
    fetch(`https://api-scdndestates.com/api/get-admin-management-fee/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to save management fee");
        }
        return response.json();
      })
      .then((response) => {
        setData3(response.data3);
        setData(response.data);
        setData2(response.data2);

        const sorted_data_193 = Object.entries(response.data_193)
          .sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA))
          .reduce((acc, [date, entries]) => {
            acc[date] = entries;
            return acc;
          }, {});

        const sorted_data_195 = Object.entries(response.data_195)
          .sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA))
          .reduce((acc, [date, entries]) => {
            acc[date] = entries;
            return acc;
          }, {});
        setData_193(sorted_data_193);
        setData_195(sorted_data_195);
        setDataFetched(true);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error during fetch:", error);
      });

    const combinedData = AgentInfoApp.map((agent) => {
      const agentCode = agent.AgentCode;

      // Find corresponding entry in onboardingFee
      const feeData = data2?.find((fee) => {
        const feeKey = Object.keys(fee)[0];
        return fee[feeKey].Code === agentCode.toString();
      });

      // total paid amount to a agent

      let totalAmount = 0;

      const feeData2 = data3
        .filter((item) => item.AgentCode === agentCode)
        .flatMap((item) =>
          Object.values(item)
            .filter((ledgerItem) => typeof ledgerItem === "object") // Ensure to filter out non-object values like AgentCode and StarRating
            .map((ledgerItem) => parseInt(ledgerItem.Pay_amount))
        );

      totalAmount = feeData2.reduce((acc, amount) => acc + amount, 0);

      let amt = 0;
      // Find all corresponding fee in AgentPaymentApp
      data2?.filter((fee) => {
        const feeKey = Object.keys(fee)[0];
        if (parseInt(fee[feeKey].Code) === agentCode) {
          amt = amt + fee[feeKey].Amount;
        }
      });

      // Construct combined data object
      return {
        AgentCode: agentCode,
        AgentName: agent.AgentName,
        Amount: amt,
        TotalPayAmount: totalAmount,
        ClientName: feeData ? Object.keys(feeData)[0] : "Direct",
      };
    });

    setCombinedData(combinedData);
  }, [dataFetched]);

  const transformData = (data) => {
    const transformed = {};
    for (const [date, entries] of Object.entries(data)) {
      const result = date_to_quarter(date);

      if (!transformed[result]) {
        transformed[result] = {
          total: 0,
          tableData: [],
        };
      }

      const totalForDate = entries.reduce(
        (sum, entry) => sum + entry.amount,
        0
      );
      transformed[result].total += totalForDate;

      for (const entry of entries) {
        transformed[result].tableData.push({
          name: entry.name || "N/A",
          pan: entry.pan,
          rate: entry.amount && entry.amount !== 0 ? "10%" : "0%",
          dateOfDeduction: date,
          tdsAmount: entry.amount,
          amountPaid: entry.paid,
        });
      }
    }

    return Object.entries(transformed).map(([fiscalYear, data]) => ({
      fiscalYear,
      total: data.total,
      tableData: data.tableData,
    }));
  };

  const transformedData = transformData(data_193);

  const transformData2 = (data) => {
    const transformed = {};

    for (const [date, entries] of Object.entries(data)) {
      const result = date_to_quarter(date);
      if (!transformed[result]) {
        transformed[result] = {
          total: 0,
          tableData: [],
        };
      }

      const totalForDate = entries.reduce(
        (sum, entry) => sum + entry.amount,
        0
      );
      transformed[result].total += totalForDate;

      for (const entry of entries) {
        transformed[result].tableData.push({
          name: entry.name || "N/A",
          pan: entry.pan,
          rate: entry.amount && entry.amount !== 0 ? "31.2%" : "0%",
          dateOfDeduction: date,
          tdsAmount: entry.amount,
          amountPaid: entry.paid,
        });
      }
    }

    return Object.entries(transformed).map(([fiscalYear, data]) => ({
      fiscalYear,
      total: data.total,
      tableData: data.tableData,
    }));
  };

  const getAgentname = (code) => {
    const agent = AgentInfoApp.find(
      (data) => data.AgentCode === parseInt(code)
    );
    return agent ? agent.AgentName : "No Manager";
  };

  const transformedData2 = transformData2(data_195);

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // January is 0
  const initialYear = currentMonth >= 4 ? currentYear : currentYear - 1; // If after April, start financial year in current year
  const lastYear = new Date().getFullYear() - 1;
  const lastMonth = new Date().getMonth() + 1; // January is 0
  const FinalYear = lastMonth >= 4 ? lastYear : lastYear - 1; // If after April, start financial year in current year

  const [financialYear, setFinancialYear] = useState(initialYear);

  // Calculate the financial year string for display, e.g. "2023-24"
  const getFinancialYearString = (year) =>
    `${year}-${(year + 1).toString().slice(2)}`;

  // Function to filter dates for the current financial year
  const filterDatesByFinancialYear = (dates, year) => {
    const startDate = new Date(`${year}-04-01`);
    const endDate = new Date(`${year + 1}-03-31`);
    return dates.filter((date) => {
      const currentDate = new Date(date);
      return currentDate >= startDate && currentDate <= endDate;
    });
  };

  // Paginate between financial years
  const prevPage = () => {
    setFinancialYear(financialYear - 1);
  };

  const nextPage = () => {
    setFinancialYear(financialYear + 1);
  };

  const dates = Object.keys(data);
  const filteredDates = filterDatesByFinancialYear(dates, financialYear).sort(
    (a, b) => new Date(b) - new Date(a)
  );

  const handleClientTable = (val) => {
    setShow(val);
  };

  const handleInvoiceTable = (val) => {
    setShow2(val);
  };

  const UploadNewInvoice = () => {
    fetch(`https://api-scdndestates.com/api/upload-new-invoice/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        payDate: payDate,
        invoice: invoice,
        utrNo: utrNo,
        payAmount: payAmount,
        agentCode: show2,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to upload invoice");
        }
        return response.json();
      })
      .then((response) => {
        if (response.message === "Success") {
          setShowModal1(true);
          setTimeout(() => {
            setShowModal1(false);
          }, 5000);
          setPayDate("");
          setInvoice("");
          setUtrNo("");
          setPayAmount("");
          setShow3(false);
          setShow2("");
          setData2(response.data);
        }
      })
      .catch((error) => {
        console.error("Error during fetch:", error);
      });
  };

  const [activeCard, setActiveCard] = useState(null);

  const handleCardClick = (date) => {
    setActiveCard(date);
  };
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  // Salary Slip

  const [currentUser, setCurrentUser] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState("Mar 25");
  const [showMonthSelector, setShowMonthSelector] = useState(false);

  // Available months for selection
  const availableMonths = [
    "Mar 25",
    "Feb 25",
    "Jan 25",
    "Dec 24",
    "Nov 24",
    "Oct 24",
  ];

  // Sample employee data
  const employees = [
    {
      id: "EMP001",
      fullName: "Chandan Kumar",
      basic: { privilege: 45000, sick: 2000 },
      allowances: { housing: 15000, travel: 5000 },
      bonus: { performance: 8000, profitLinked: 12000 },
      deductions: { tds: 7500, epf: 3600, nps: 2000, health: 1500 },
    },
    {
      id: "EMP002",
      fullName: "Nitesh Mishra",
      basic: { privilege: 42000, sick: 1800 },
      allowances: { housing: 14000, travel: 4500 },
      bonus: { performance: 7500, profitLinked: 10000 },
      deductions: { tds: 7000, epf: 3400, nps: 1800, health: 1400 },
    },
    {
      id: "EMP003",
      fullName: "Jyoti",
      basic: { privilege: 48000, sick: 2200 },
      allowances: { housing: 16000, travel: 5500 },
      bonus: { performance: 9000, profitLinked: 14000 },
      deductions: { tds: 8000, epf: 3800, nps: 2200, health: 1600 },
    },
    {
      id: "EMP004",
      fullName: "Reshma Ranjan",
      basic: { privilege: 46000, sick: 2100 },
      allowances: { housing: 15500, travel: 5200 },
      bonus: { performance: 8500, profitLinked: 13000 },
      deductions: { tds: 7800, epf: 3700, nps: 2100, health: 1550 },
    },
  ];

  // Simulate JWT authentication
  useEffect(() => {
    // In a real app, this would decode the JWT from localStorage or cookies
    // For this demo, we'll simulate having a JWT with Chandan Kumar's details
    const simulatedJwtPayload = {
      sub: "EMP001",
      fullName: "Chandan Kumar",
      role: "employee",
    };

    setCurrentUser(simulatedJwtPayload);
  }, []);

  const handleDownloadPDF = (employeeId) => {
    // In a real application, this would trigger a PDF download for the selected month
    console.log(
      `Downloading PDF for employee ${employeeId} for ${selectedMonth}`
    );
    alert(`PDF download started for employee ${employeeId} - ${selectedMonth}`);
  };

  const hasAccess = (employeeFullName) => {
    return currentUser && currentUser.fullName === employeeFullName;
  };

  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Replace your current state:
  const [expandedRows, setExpandedRows] = useState({});

  // With this single expandedRowId state:
  const [expandedRowId, setExpandedRowId] = useState(null);

  // Then replace your toggleRowExpansion function with this:
  const toggleRowExpansion = (employeeId) => {
    setExpandedRowId((prevId) => (prevId === employeeId ? null : employeeId));
  };

  return (
    <>
      <div className="max-md:flex-col md:flex gap-2.5">
        {/* Left section */}
        <div className="h-full md:max-w-[50%] md:min-w-[50%] 2xl:max-w-[55%] 2xl:min-w-[55%]">
          <div className="w-full  border shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] rounded-lg  bg-white">
            <div className="bg-sky-500 w-full flex justify-between items-center p-2 text-white rounded-t-md">
              <p className="text-base font-bold">TDS Deduction</p>
              <div className="flex items-center gap-4 font-bold">
                {/* Buttons */}
                <div className="flex justify-center">
                  <div className="w-full md:w-fit flex justify-center">
                    <div className="segmented-control relative flex items-center rounded-lg w-52 h-7 px-1">
                      <input
                        type="radio"
                        name="radio2"
                        value="3"
                        id="section_193"
                        className="hidden"
                        checked={selectedTab === "section_193"}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="section_193"
                        className="segmented-control__1  py-2 flex-1 flex justify-center items-center transition-all duration-500 cursor-pointer"
                        style={{
                          width: "6rem",
                        }}
                      >
                        <p className="m-0 font-bold text-sm text-white py-2">
                          Section 193
                        </p>
                      </label>

                      <input
                        type="radio"
                        name="radio2"
                        value="4"
                        id="section_195"
                        className="hidden"
                        checked={selectedTab === "section_195"}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="section_195"
                        className="segmented-control__2 py-2 flex-1 flex justify-center items-center transition-all duration-500 cursor-pointer z-1"
                        style={{
                          width: "7.6rem",
                        }}
                      >
                        <p className="m-0 font-bold text-sm text-white py-2">
                          Section 195
                        </p>
                      </label>

                      <div
                        className="absolute h-8 z-0 rounded-md bg-[#ffffff80]  border-[1px] border-sky-600 shadow-inner pointer-events-none transition-transform duration-300 ease-in-out py-2 "
                        style={{
                          transform:
                            selectedTab === "section_193"
                              ? "translateX(0)"
                              : selectedTab === "section_195"
                              ? "translateX(6.2rem)"
                              : "translateX(13.8rem)",
                          width:
                            selectedTab === "section_193"
                              ? "6.2rem"
                              : selectedTab === "section_195"
                              ? "6.2rem"
                              : "0rem",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {loader ? (
              <div className="m-2 space-y-2 overflow-y-scroll h-[345px]">
                {[...Array(7)].map((_, index) => (
                  <div
                    key={index}
                    className="w-full h-10 rounded-lg bg-gray-200 animate-pulse"
                  ></div>
                ))}
              </div>
            ) : (
              <>
                {selectedTab === "section_193" ? (
                  <div className="m-2 space-y-2 overflow-y-scroll h-[345px]">
                    {transformedData.length > 0 ? (
                      transformedData.map((item, index) => (
                        <div
                          key={index}
                          className="group flex flex-col gap-2 rounded-lg border-2 text-black cursor-pointer transition ease-in-out"
                        >
                          <div className="flex flex-col text-sm">
                            <div
                              className="flex items-center justify-between bg-[#E0F2FE] p-1.5"
                              onClick={() =>
                                setExpandedIndex(
                                  expandedIndex === index ? null : index
                                )
                              }
                            >
                              <span className="font-bold flex items-center gap-2">
                                <span
                                  className={`transition-all duration-500 ${
                                    expandedIndex === index ? "-rotate-180" : ""
                                  }`}
                                >
                                  <IoMdArrowDropdownCircle size={18} />
                                </span>
                                <span>{item.fiscalYear}</span>
                              </span>
                              <div className="bg-white text-black border text-sm font-semibold rounded-md min-w-[140px] py-1 px-2 flex items-center justify-center gap-2">
                                <p>Total:</p>
                                <p>
                                  ₹{" "}
                                  {item.total.toLocaleString("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </p>
                              </div>
                            </div>

                            <div
                              className={`transition-all duration-700 ease-in-out overflow-hidden ${
                                expandedIndex === index
                                  ? "max-h-screen opacity-100 "
                                  : "max-h-0 opacity-0"
                              }`}
                            >
                              <div className="min-w-full overflow-x-auto shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]">
                                {/* Header Row */}
                                <div className="bg-[#0284C7] text-white min-w-[700px] max-md:hidden">
                                  <div className="flex justify-start items-center gap-2 py-1 px-4 text-center text-sm font-semibold">
                                    <p className="w-[20%]  text-left px-2 py-1">
                                      Full Name
                                    </p>
                                    <p className="w-[18%]   text-left px-1 py-1">
                                      PAN
                                    </p>
                                    <p className="w-[10%]     text-left px-1 py-1">
                                      Rate
                                    </p>
                                    <p className="w-[16%]   text-left px-1 py-1">
                                      Date
                                    </p>
                                    <p className="w-[16%]   text-left px-1 py-1">
                                      TDS Amount
                                    </p>
                                    <p className="w-[16%]   text-left px-1 py-1">
                                      Amount Paid
                                    </p>
                                  </div>
                                </div>

                                {/* Table Data with Scroll */}
                                <div className="bg-white max-h-[185px] min-w-[700px] overflow-y-auto overflow-x-auto rounded-b-lg">
                                  <div className="flex flex-col gap-1 px-2 py-2">
                                    {item.tableData.map((row, rowIndex) => (
                                      <div
                                        key={rowIndex}
                                        className="flex w-full justify-start items-center bg-white hover:bg-gray-100 rounded-lg shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] transition-all duration-300 mt-1"
                                      >
                                        <div
                                          className="relative w-[20%]  overflow-hidden pl-4  py-1 text-left font-semibold text-[#313131] text-sm"
                                          onMouseEnter={() =>
                                            setHoveredRowIndex(rowIndex)
                                          }
                                          onMouseLeave={() =>
                                            setHoveredRowIndex(null)
                                          }
                                        >
                                          {hoveredRowIndex !== rowIndex ? (
                                            // Display truncated name when not hovered
                                            <span className="truncate">
                                              {row?.name.length > 16
                                                ? row?.name.substring(0, 16) +
                                                  "..."
                                                : row?.name}
                                            </span>
                                          ) : (
                                            // Display marquee animation when hovered
                                            <div className=" w-full h-full flex items-center">
                                              <div className="scrolling-text">
                                                {row?.name}
                                              </div>
                                            </div>
                                          )}
                                        </div>

                                        <div className="w-[18%] py-1 text-center font-semibold text-[#313131] text-sm">
                                          {row?.pan}
                                        </div>
                                        <div className="w-[10%] py-1 text-center font-semibold text-[#313131] text-sm">
                                          {row?.rate}
                                        </div>
                                        <div className="truncate w-[16%] px-1 py-1 text-center font-semibold text-[#313131] text-sm">
                                          {row?.dateOfDeduction}
                                        </div>
                                        <div className=" py-1 w-[16%] text-center font-semibold text-[#313131] text-sm">
                                          {row?.tdsAmount.toLocaleString(
                                            "en-IN",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                        </div>
                                        <div className=" py-1 w-[16%] text-center font-semibold text-[#313131] text-sm">
                                          {row?.amountPaid.toLocaleString(
                                            "en-IN",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <>
                        <div className="animate-pulse flex flex-col justify-center items-center px-2 gap-y-4 overflow-hidden ">
                          <img
                            className="w-32 h-32 "
                            src="/images/no-info.svg"
                            alt=""
                          />
                          <span className="text-center text-xs md:text-sm   font-semibold">
                            {" "}
                            You don't have any Coupon Statements.
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div className="m-2 space-y-2 overflow-y-scroll h-[345px]">
                    {transformedData2.length > 0 ? (
                      transformedData2.map((item, index) => (
                        <div
                          key={index}
                          className="group flex flex-col gap-2 rounded-lg border-2 text-black cursor-pointer transition ease-in-out"
                        >
                          <div className="flex flex-col text-sm">
                            <div
                              className="flex items-center justify-between bg-[#E0F2FE] p-1.5"
                              onClick={() =>
                                setExpandedIndex(
                                  expandedIndex === index ? null : index
                                )
                              }
                            >
                              <span className="font-bold flex items-center gap-2">
                                <span
                                  className={`transition-all duration-500 ${
                                    expandedIndex === index ? "-rotate-180" : ""
                                  }`}
                                >
                                  <IoMdArrowDropdownCircle size={18} />
                                </span>
                                <span>{item.fiscalYear}</span>
                              </span>
                              <div className="bg-white text-black border text-sm font-semibold rounded-md py-1 px-2 flex items-center gap-2">
                                <p>Total</p>
                                <p>{item.total}</p>
                              </div>
                            </div>

                            <div
                              className={`transition-all duration-1000 ease-in-out overflow-hidden ${
                                expandedIndex === index
                                  ? "max-h-screen opacity-100 m-3"
                                  : "max-h-0 opacity-0"
                              }`}
                            >
                              <div className="min-w-full border-collapse overflow-x-scroll">
                                <div className="bg-[#0284C7] text-white rounded-t-lg min-w-[700px]">
                                  <div className="grid grid-cols-5 gap-2 p-1 text-center text-sm md:text-sm font-bold">
                                    <p className="px-2 py-1 md:px-4 md:py-2">
                                      Name
                                    </p>
                                    <p className="px-2 py-1 md:px-4 md:py-2">
                                      PAN
                                    </p>
                                    <p className="px-2 py-1 md:px-4 md:py-2">
                                      Rate
                                    </p>
                                    <p className="px-2 py-1 md:px-4 md:py-2">
                                      Date
                                    </p>
                                    <p className="px-2 py-1 md:px-4 md:py-2">
                                      TDS Amt
                                    </p>
                                  </div>
                                </div>
                                <div className="bg-white rounded-b-lg overflow-y-scroll max-h-[185px] min-w-[700px]">
                                  <div className="min-w-full border-x border-b rounded-b-lg border-gray-200 flex flex-col gap-2 p-2">
                                    {item.tableData.map((row, rowIndex) => (
                                      <div
                                        key={rowIndex}
                                        className="flex items-center bg-white border hover:bg-[#1b4b6c] rounded-md"
                                      >
                                        <p className="flex-1 px-2 py-1 md:px-4 md:py-2 text-center">
                                          {row.name}
                                        </p>
                                        <p className="flex-1 px-2 py-1 md:px-4 md:py-2 text-center">
                                          {row.pan}
                                        </p>
                                        <p className="flex-1 px-2 py-1 md:px-4 md:py-2 text-center">
                                          {row.rate}
                                        </p>
                                        <p className="flex-1 px-2 py-1 md:px-4 md:py-2 text-center">
                                          {row.dateOfDeduction}
                                        </p>
                                        <p className="flex-1 px-2 py-1 md:px-4 md:py-2 text-center">
                                          {row.tdsAmount.toLocaleString(
                                            "en-IN",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <>
                        <div className="animate-pulse flex flex-col justify-center items-center px-2 gap-y-4 overflow-hidden ">
                          <img
                            className="w-32 h-32 "
                            src="/images/no-info.svg"
                            alt=""
                          />
                          <span className="text-center text-xs md:text-sm   font-semibold">
                            {" "}
                            You don't have any Coupon Statements.
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          <div className="p-2  w-full mt-10 border shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] rounded-lg  bg-white">
            <div className="bg-sky-500 w-full flex justify-between items-center p-2 text-white rounded-t-md">
              <p className="text-base font-bold">Salary Slips</p>

              <div className=" w-32  relative" ref={dropdownRef}>
                {/* Selected Item */}
                <button
                  className={`w-full relative flex items-center justify-between   px-2 py-1  group font-semibold text-sm  max-md:border-[1.5px] bg-blue-100  overflow-hidden  transition-all duration-200 ease-linear text-black/70  text-[10px] ${
                    isOpen ? "rounded-lg   " : "rounded-lg  "
                  }`}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  {selectedMonth || "Select Month"}
                  <img
                    class={`w-4 h-4   stroke-black/70   transition-all duration-500 ease-in-out ${
                      isOpen ? "-rotate-90" : "rotate-90"
                    }`}
                    src="/images/drop1.svg"
                  />
                </button>

                <ul
                  className={`absolute no-scrollbar left-0 top-[25px] overflow-y-auto  w-full bg-blue-100  z-10  duration-700 transition-all ease-in-out ${
                    isOpen ? "h-32 rounded-b-lg  " : "h-0  rounded-lg "
                  }`}
                >
                  {availableMonths?.map((month, index) => (
                    <li
                      key={index}
                      className={`px-2 py-1 text-xs  text-black flex justify-between items-center cursor-pointer hover:bg-blue-300 ${
                        selectedMonth === month
                          ? "bg-blue-300 font-semibold"
                          : ""
                      }`}
                      onClick={() => {
                        setSelectedMonth(month);
                        setIsOpen(false);
                      }}
                    >
                      {month}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="overflow-x-auto h-[345px] overflow-y-scroll bg-white rounded-lg">
              <table className="min-w-full">
                <thead>
                  <tr className="bg-gray-100 text-sm">
                    <th className="py-3 px-2 border-b text-left  w-[22%] ">
                      Employee
                    </th>
                    <th className="py-3 px-2 border-b text-left  w-[17%]">
                      Basic
                    </th>
                    <th className="py-3 px-2 border-b text-left  w-[17%]">
                      Allowances
                    </th>
                    <th className="py-3 px-2 border-b text-left  w-[17%]">
                      Bonus
                    </th>
                    <th className="py-3 px-2 border-b text-left  w-[17%]">
                      Deductions
                    </th>
                    <th className="py-3 px-2 border-b text-center w-[10%]"></th>
                  </tr>
                </thead>
                <tbody>
                  {employees.map((employee) => {
                    const canViewDetails = hasAccess(employee.fullName);
                    const isExpanded = expandedRowId === employee.id;

                    return (
                      <React.Fragment key={employee.id}>
                        <tr
                          className={` cursor-pointer text-sm duration-300 ease-in-out ${
                            canViewDetails
                              ? "bg-sky-50 hover:bg-sky-100"
                              : "hover:bg-gray-50"
                          }`}
                          onClick={() => toggleRowExpansion(employee.id)}
                        >
                          <td className="py-3 px-2 border-b">
                            <div className="flex items-start">
                              <div className="w-full">
                                <div className="font-medium w-full text-sm flex items-center justify-between truncate">
                                  <div>{employee.fullName}</div>
                                  <div className="pl-2">
                                    {isExpanded ? (
                                      <ChevronUp size={12} strokeWidth={3} />
                                    ) : (
                                      <ChevronDown size={12} strokeWidth={3} />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="py-3 px-2 border-b ">
                            ₹
                            {(
                              employee.basic.privilege + employee.basic.sick
                            ).toLocaleString()}
                          </td>
                          <td className="py-3 px-2 border-b">
                            ₹
                            {(
                              employee.allowances.housing +
                              employee.allowances.travel
                            ).toLocaleString()}
                          </td>
                          <td className="py-3 px-2 border-b">
                            ₹
                            {(
                              employee.bonus.performance +
                              employee.bonus.profitLinked
                            ).toLocaleString()}
                          </td>
                          <td className="py-3 px-2 border-b">
                            ₹
                            {(
                              employee.deductions.tds +
                              employee.deductions.epf +
                              employee.deductions.nps +
                              employee.deductions.health
                            ).toLocaleString()}
                          </td>
                          <td className="py-3 px-2 border-b text-center">
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDownloadPDF(employee.id);
                              }}
                              className="text-blue-600 hover:text-blue-800"
                              title="Download PDF"
                            >
                              <FileText size={20} />
                            </button>
                          </td>
                        </tr>

                        <tr className="bg-gray-50">
                          <td colSpan="6" className="p-0 ">
                            <div className={`bg-gray-50 `}>
                              {canViewDetails ? (
                                <div
                                  className={`transition-all bg-sky-50  duration-1000 overflow-hidden ease-in-out flex justify-start text-sm font-semibold border-b ${
                                    isExpanded
                                      ? "h-[115px] opacity-100"
                                      : "h-[0px] opacity-0"
                                  }`}
                                >
                                  <div className="max-sm:p-0.5 sm:p-2 border-r border-gray-200 w-[26%] sm:w-[24%]"></div>
                                  <div className="max-sm:p-0.5 sm:p-2 border-r border-gray-200 w-[15%] sm:w-[17%]">
                                    <ul className="space-y-1.5 text-sm">
                                      <li className="flex items-start flex-col ">
                                        <span className="text-xs text-gray-600">
                                          Privilege:
                                        </span>{" "}
                                        ₹
                                        {employee.basic.privilege.toLocaleString()}
                                      </li>
                                      <li className="flex items-start flex-col ">
                                        <span className="text-gray-600">
                                          Sick:
                                        </span>{" "}
                                        ₹{employee.basic.sick.toLocaleString()}
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="max-sm:p-0.5 sm:p-2 border-r border-gray-200 w-[15%] sm:w-[17%]">
                                    <ul className="space-y-1.5 text-sm">
                                      <li className="flex items-start flex-col ">
                                        <span className="text-xs text-gray-600">
                                          Housing:
                                        </span>{" "}
                                        ₹
                                        {employee.allowances.housing.toLocaleString()}
                                      </li>
                                      <li className="flex items-start flex-col ">
                                        <span className="text-xs text-gray-600">
                                          Travel:
                                        </span>{" "}
                                        ₹
                                        {employee.allowances.travel.toLocaleString()}
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="max-sm:p-0.5 sm:p-2 border-r border-gray-200 w-[20%]">
                                    <ul className="space-y-1.5 text-sm">
                                      <li className="flex items-start flex-col ">
                                        <span className="text-xs text-gray-600">
                                          Performance:
                                        </span>{" "}
                                        ₹
                                        {employee.bonus.performance.toLocaleString()}
                                      </li>
                                      <li className="flex items-start flex-col ">
                                        <span className="text-xs text-gray-600">
                                          Profit-linked:
                                        </span>{" "}
                                        ₹
                                        {employee.bonus.profitLinked.toLocaleString()}
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="max-sm:p-0.5 sm:p-2 w-[24%]">
                                    <ul className="space-y-1.5 text-sm w-full">
                                      <li className="flex justify-start items-center gap-x-1.5 w-full">
                                        <span className="text-xs w-[45%] text-gray-600">
                                          TDS:
                                        </span>{" "}
                                        ₹
                                        {employee.deductions.tds.toLocaleString()}
                                      </li>
                                      <li className="flex justify-start items-center gap-x-1.5 w-full">
                                        <span className="text-xs w-[45%] text-gray-600">
                                          EPF:
                                        </span>{" "}
                                        ₹
                                        {employee.deductions.epf.toLocaleString()}
                                      </li>
                                      <li className="flex justify-start items-center gap-x-1.5 w-full">
                                        <span className="text-xs w-[45%] text-gray-600">
                                          NPS:
                                        </span>{" "}
                                        ₹
                                        {employee.deductions.nps.toLocaleString()}
                                      </li>
                                      <li className="flex justify-start items-center gap-x-1.5 w-full">
                                        <span className="text-xs w-[45%] text-gray-600">
                                          Health:
                                        </span>{" "}
                                        ₹
                                        {employee.deductions.health.toLocaleString()}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className={`transition-all duration-1000 overflow-hidden ease-in-out flex justify-center items-center text-sm font-semibold border-b  ${
                                    isExpanded
                                      ? "h-[55px] opacity-100"
                                      : "h-[0px] opacity-0"
                                  }`}
                                >
                                  <img
                                    className="text-gray-400 mr-2 md:w-7 md:h-7 w-5 h-5"
                                    src="/images/padlock.svg"
                                    alt=""
                                  />
                                  <p className="text-gray-500 text-xs md:text-sm">
                                    Detailed information is only available for
                                    your own salary slip
                                  </p>
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="md:w-[50%] max-md:mt-8 2xl:w-[45%] p-2 relative bg-white h-fit">
          <div className="w-full px-2 pt-2  border rounded-lg relative bg-white shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]">
            <h3 className="text-base font-bold text-black absolute -top-3 bg-white px-2 py-1 rounded-lg border">
              Onboarding Fees
            </h3>
            <div className="table-responsive border-dark mt-4 w-full ov">
              <div className="table table-borderless table-sm w-full min-w-[500px]">
                <div className="w-full rounded-full">
                  <div className="w-full bg-[#4E83D9] text-white rounded-t-lg flex justify-around">
                    <p
                      scope="col"
                      className="text-sm font-bold text-center py-2 w-[40%]"
                    >
                      Name
                    </p>
                    <p
                      scope="col"
                      className="text-sm font-bold text-center py-2 w-[30%]"
                    >
                      Amount Payable
                    </p>
                    <p
                      scope="col"
                      className="text-sm font-bold text-center py-2 w-[30%]"
                    >
                      Amount Paid
                    </p>
                  </div>
                </div>
                <div className="border rounded-b-lg shadow-sm w-full">
                  {combinedData.length > 0 ? (
                    combinedData.map((item, index) => (
                      <p
                        key={index}
                        className="text-black my-1.5 border-b font-semibold bg-white hover:bg-zinc-200 flex items-center justify-around bg-light"
                      >
                        <p className="text-center text-sm px-2 py-2 w-[40%]">
                          {item.AgentName}
                        </p>
                        <div className="text-center cursor-default text-sm px-2 py-2 min-w-[30%]">
                          <div class="relative inline-flex items-center justify-end w-[100px] py-1 overflow-hidden font-bold transition-all duration-75 ease-in ">
                            <span className="text-black">
                              ₹{" "}
                              {item.Amount.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </span>
                            <span
                              onClick={() => handleClientTable(item.AgentCode)}
                              className="w-5 h-5 ml-2 cursor-pointer animate-pulse fill-red-700"
                            >
                              <svg
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                id="fi_6996532"
                              >
                                <g id="Glyph">
                                  <path d="m22.41 7h-5.41v-5.41zm.59 2v9a5 5 0 0 1 -5 5h-9a8 8 0 0 1 -4-14.92v-2.08a5 5 0 0 1 5-5h5v6a2 2 0 0 0 2 2zm-14 12a6 6 0 1 0 -6-6 6 6 0 0 0 6 6zm1.5-8.5a1.5 1.5 0 1 0 -1.5 1.5 1.5 1.5 0 0 0 1.5-1.5zm-.5 5.5v-2a1 1 0 0 0 -2 0v2a1 1 0 0 0 2 0z"></path>
                                </g>
                              </svg>
                            </span>
                          </div>
                        </div>
                        <p className="text-center text-sm px-2 py-2 min-w-[30%] ">
                          <div class="relative cursor-default inline-flex items-center justify-end w-[100px] py-1 overflow-hidden font-bold transition-all duration-75 ease-in focus:outline-none">
                            <span className="text-black">
                              ₹{" "}
                              {item.TotalPayAmount.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </span>
                            <span
                              onClick={() => handleInvoiceTable(item.AgentCode)}
                              className="w-5 h-5 ml-2 cursor-pointer animate-pulse fill-green-700"
                            >
                              <svg
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                id="fi_6996532"
                              >
                                <g id="Glyph">
                                  <path d="m22.41 7h-5.41v-5.41zm.59 2v9a5 5 0 0 1 -5 5h-9a8 8 0 0 1 -4-14.92v-2.08a5 5 0 0 1 5-5h5v6a2 2 0 0 0 2 2zm-14 12a6 6 0 1 0 -6-6 6 6 0 0 0 6 6zm1.5-8.5a1.5 1.5 0 1 0 -1.5 1.5 1.5 1.5 0 0 0 1.5-1.5zm-.5 5.5v-2a1 1 0 0 0 -2 0v2a1 1 0 0 0 2 0z"></path>
                                </g>
                              </svg>
                            </span>
                          </div>
                        </p>
                      </p>
                    ))
                  ) : (
                    <div className="border-top">
                      <p
                        colSpan={3}
                        className="text-center bg-light text-sm py-4"
                      >
                        <div className="animate-pulse flex flex-col justify-center items-center px-2 gap-y-4 overflow-hidden ">
                          <img
                            className="w-16 h-16 "
                            src="/images/no-info.svg"
                            alt=""
                          />
                          <span className="text-center text-xs md:text-sm   font-semibold">
                            {" "}
                            No Onboarding Fee Yet.
                          </span>
                        </div>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {show !== -1 && (
              <>
                <div className="demo2-container">
                  <div className="bg-light rounded border-0 m-4 px-3 py-2.5 overflow-hidden pop-up-animation w-[650px]">
                    <div
                      onClick={() => setShow(-1)}
                      className=" flex justify-end  rounded-full mb-2"
                    >
                      <button
                        className="circleadmin bg-white"
                        data-animation="showShadow"
                        data-remove="3000"
                      ></button>
                    </div>

                    <table className="table-responsive table-borderless table-sm border-dark w-full min-h-[200px]">
                      <thead className="bg-[#386BC0] text-white font-semibold rounded-t-lg">
                        <tr className="flex justify-around">
                          <th
                            scope="col"
                            className="text-sm font-semibold w-1/3 text-center"
                          >
                            Clients
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-semibold w-1/3 text-center"
                          >
                            Relationship Date
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-semibold w-1/3 text-center"
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody className="max-h-[160px] overflow-y-scroll border rounded-b-lg p-1 flex flex-col gap-1.5">
                        {data2.length > 0 ? (
                          data2
                            .filter(
                              (item) =>
                                parseInt(item[Object.keys(item)[0]].Code) ===
                                show
                            )
                            .map((entry, index) => {
                              const key = Object.keys(entry)[0];
                              const item = entry[key];
                              return (
                                <tr
                                  key={index}
                                  className="hover:bg-[#DBEAFE] text-black font-semibold border shadow-sm rounded-lg  cursor-pointer flex justify-around"
                                >
                                  <td className="text-sm text-center break-words w-1/3">
                                    {item.Name}
                                  </td>
                                  <td className="text-sm text-center w-1/3">
                                    {item.Date}
                                  </td>
                                  <td className="text-sm text-center w-1/3">
                                    ₹ {item.Amount.toLocaleString("en-IN")}
                                  </td>
                                </tr>
                              );
                            })
                        ) : (
                          <tr className="flex justify-center items-center">
                            <td
                              colSpan={3}
                              className="text-center bg-light text-sm flex items-center gap-2"
                            >
                              <div className="animate-pulse flex flex-col justify-center items-center px-2 gap-y-4 overflow-hidden ">
                                <img
                                  className="w-16 h-16 "
                                  src="/images/no-info.svg"
                                  alt=""
                                />
                                <span className="text-center text-xs md:text-sm   font-semibold">
                                  {" "}
                                  No Onboarding Fee Yet.
                                </span>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
            {show2 !== "" && (
              <>
                <div className="demo2-container ">
                  <div className="bg-light rounded border-0 p-3 overflow-hidden pop-up-animation w-[95%] md:w-[600px]">
                    <div className="flex justify-between mb-2">
                      <button
                        className="button-excelsmall w-[80px] py-1.5 animationIcon-shine-blue"
                        onClick={() => setShow3(!show3)}
                      >
                        <i className="text-base md:text-lg">
                          <IoMdAddCircle className="icon" />
                        </i>
                        <p className="font-semibold">Add</p>
                      </button>

                      <div
                        onClick={() => setShow2("")}
                        className=" flex justify-end  rounded-full mb-2"
                      >
                        <button
                          className="circleadmin bg-white"
                          data-animation="showShadow"
                          data-remove="3000"
                        ></button>
                      </div>
                    </div>
                    <div className=" table-borderless table-sm border-dark overflow-x-scroll">
                      <div className="rounded min-w-[410px] ">
                        <div className="font-semibold bg-[#386BC0] text-white flex justify-around rounded-t-lg">
                          <p
                            scope="col"
                            className="text-sm font-semibold text-center flex-1"
                          >
                            Invoice
                          </p>
                          <p
                            scope="col"
                            className="text-sm font-semibold text-center flex-1"
                          >
                            Date
                          </p>
                          <p
                            scope="col"
                            className="text-sm font-semibold text-center flex-[2] min-w-[120px]" // Adjusted width for UTR No
                          >
                            UTR No
                          </p>
                          <p
                            scope="col"
                            className="text-sm font-semibold text-center flex-1"
                          >
                            Amount
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col gap-1.5 border rounded-b-lg p-1.5 max-h-[130px] min-w-[410px]">
                        {!data3.length > 0 ? (
                          data3
                            .filter((item) => item.AgentCode === show2)
                            .flatMap((item) =>
                              Object.keys(item)
                                .filter(
                                  (key) =>
                                    key !== "AgentCode" && key !== "StarRating"
                                )
                                .map((key, index) => (
                                  <div
                                    key={index}
                                    className="shadow-sm flex items-center justify-around bg-[#ffffff] hover:bg-[#DBEAFE]  text-black font-semibold rounded-lg border hover:scale-100 duration-100 cursor-pointer"
                                  >
                                    <p className="text-sm text-center flex-1">
                                      {key}
                                    </p>
                                    <p className="text-sm text-center flex-1">
                                      {new Date(
                                        item[key].Pay_date
                                      ).toLocaleDateString()}
                                    </p>
                                    <p className="text-sm text-center flex-[2] min-w-[120px]">
                                      {" "}
                                      {/* Adjusted width for UTR No */}
                                      {item[key].Utr_no}
                                    </p>
                                    <p className="text-sm text-center flex-1">
                                      ₹
                                      {parseInt(
                                        item[key].Pay_amount
                                      ).toLocaleString("en-IN")}
                                    </p>
                                  </div>
                                ))
                            )
                        ) : (
                          <div className="border-top">
                            <div
                              colSpan={4}
                              className="text-center bg-light text-sm"
                            >
                              <div className="flex justify-center items-center">
                                <div className="animate-pulse flex flex-col justify-center items-center px-2 gap-y-4 overflow-hidden ">
                                  <img
                                    className="w-16 h-16 "
                                    src="/images/no-info.svg"
                                    alt=""
                                  />
                                  <span className="text-center text-xs md:text-sm   font-semibold">
                                    {" "}
                                    No Onboarding Fee Yet.
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="overflow-x-scroll">
                      {show3 && (
                        <>
                          <div className="alert-animation border shadow-sm rounded-lg p-3 bg-white text-black min-w-[410px]">
                            <h3 className="font-bold flex items-center gap-1">
                              <img
                                src="/images/bill.webp"
                                className="w-7 h-7 remove-white-bg"
                                alt=""
                              />
                              Add New Invoice
                            </h3>
                            <hr className="my-1 py-0" />
                            <div className="row m-0 p-0">
                              <div className="col-6 m-0 p-0 row">
                                <div className="col-6 p-0 m-0 my-1">
                                  <label className="text-xs md:text-sm font-semibold">
                                    Payment Date:
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="col-6 p-0 m-0 my-1">
                                  <input
                                    className="form-control col-10 text-xs md:text-sm font-semibold pl-1.5"
                                    type="date"
                                    value={payDate}
                                    onChange={(e) => setPayDate(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-6 m-0 p-0 ps-3 row">
                                <div className="col-6 p-0 m-0 my-1">
                                  <label className="text-xs md:text-sm font-semibold">
                                    Invoice No:
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="col-6 p-0 m-0 my-1">
                                  <input
                                    className="form-control col-10 text-xs md:text-sm font-semibold"
                                    type="text"
                                    value={invoice}
                                    onChange={(e) => setInvoice(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-6 m-0 p-0 row">
                                <div className="col-6 p-0 m-0 my-1">
                                  <label className="text-xs md:text-sm font-semibold">
                                    UTR No:
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="col-6 p-0 m-0 my-1">
                                  <input
                                    className="form-control col-10 text-xs md:text-sm font-semibold"
                                    type="text"
                                    value={utrNo}
                                    onChange={(e) => setUtrNo(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-6 m-0 p-0 ps-3 row">
                                <div className="col-6 p-0 m-0 my-1">
                                  <label className="text-xs md:text-sm font-semibold">
                                    Paid Amount:
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="col-6 p-0 m-0 my-1">
                                  <input
                                    className="form-control col-10 text-xs md:text-sm font-semibold"
                                    type="number"
                                    value={payAmount}
                                    onChange={(e) =>
                                      setPayAmount(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <hr className="my-2" />
                              <div className="flex justify-end p-0">
                                <button
                                  className="button-excelsmall w-[80px] py-1 animationIcon-shine-blue h-8"
                                  onClick={() => {
                                    UploadNewInvoice();
                                  }}
                                >
                                  <i className="text-base md:text-lg">
                                    <span>
                                      <svg
                                        className="icon w-3.5 h-3.5 fill-white"
                                        version="1.1"
                                        id="fi_724954"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsHlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 448.011 448.011"
                                        xmlSpace="preserve"
                                      >
                                        <g>
                                          <g>
                                            <path
                                              d="M438.731,209.463l-416-192c-6.624-3.008-14.528-1.216-19.136,4.48c-4.64,5.696-4.8,13.792-0.384,19.648l136.8,182.4
			l-136.8,182.4c-4.416,5.856-4.256,13.984,0.352,19.648c3.104,3.872,7.744,5.952,12.448,5.952c2.272,0,4.544-0.48,6.688-1.472
			l416-192c5.696-2.624,9.312-8.288,9.312-14.528S444.395,212.087,438.731,209.463z"
                                            ></path>
                                          </g>
                                        </g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                      </svg>
                                    </span>
                                  </i>
                                  <p className="font-semibold">Post</p>
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          {/* Bottom */}
          <div className="w-full  p-2 pt-2 mt-12 border rounded-lg bg-white shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]">
            <div className="text-base font-bold text-black relative md:-top-5 max-md:-top-8 flex justify-between">
              <p className="rounded-lg border bg-white px-2 py-1 w-max text-[15px] md:text-base">
                Management Fees
              </p>
              <div className="flex gap-2 rounded-lg  bg-white px-2 w-max items-center">
                <button
                  className="px-2  disabled:opacity-50 disabled:cursor-not-allowed"
                  onClick={prevPage}
                  disabled={financialYear === FinalYear} // Disable if too far back
                >
                  <img
                    className="w-6 h-6 max-md:w-4 max-md:h-4 rotate-180 remove-white-bg"
                    src="/images/nexticon.webp"
                    alt=""
                  />
                </button>
                <div className="max-md:text-sm">
                  {getFinancialYearString(financialYear)}
                </div>
                <button
                  className="px-2  disabled:opacity-50 disabled:cursor-not-allowed"
                  onClick={nextPage}
                  disabled={financialYear === initialYear} // Disable if at current financial year
                >
                  <img
                    className="w-6 h-6 max-md:w-4 max-md:h-4 remove-white-bg"
                    src="/images/nexticon.webp"
                    alt=""
                  />
                </button>
              </div>
            </div>

            <div className="h-[345px] overflow-y-auto">
              {/* Card Content */}
              <div className="w-full md:flex max-md:flex-col flex-wrap justify-between gap-y-5 mt-3">
                {loader ? (
                  <div className="w-full h-full flex  justify-between items-center ">
                    {[...Array(2)].map((_, index) => (
                      <div
                        key={index}
                        className="w-full lg:w-[49%] h-24 rounded-lg bg-gray-200 animate-pulse"
                      ></div>
                    ))}
                  </div>
                ) : (
                  <>
                    {filteredDates.length > 0 ? (
                      filteredDates.map((date, index) => (
                        <div
                          key={index}
                          onClick={() => handleCardClick(date)}
                          className={`cookieCard xl:w-[49%] md:w-full max-md:mb-4 max-md:w-full h-24 p-2 rounded-lg cursor-pointer text-[#313131] border-2 ${
                            activeCard === date
                              ? "border-sky-300 bg-gradient-to-b from-sky-200 to-sky-100"
                              : "border-gray-300 bg-gradient-to-b from-slate-200 to-slate-100"
                          }`}
                        >
                          <div className="cookieHeading w-full text-lg font-semibold text-center flex items-center justify-center">
                            <div
                              className={`rounded-md p-1 px-2 border-2 text-sm absolute -top-3 ${
                                activeCard === date
                                  ? "border-sky-400 bg-gradient-to-tr from-sky-200 to-sky-100 z-0"
                                  : "border-slate-400 bg-gradient-to-tr from-slate-200 to-slate-100 z-0"
                              }`}
                            >
                              {date_to_quarter2(date)}
                            </div>
                          </div>
                          <div className="flex items-center justify-center w-full font-bold gap-2 mt-3">
                            <div className="text-sm flex flex-col items-center justify-between w-full bg-white rounded-md py-2">
                              <div className="font-bold text-xs text-sky-800">
                                WALR
                              </div>
                              ₹
                              {data[date].walr.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </div>
                            <div className="text-sm flex flex-col items-center justify-between w-full bg-white rounded-md py-2">
                              <div className="font-bold text-xs text-sky-800">
                                RESIDEX
                              </div>
                              ₹
                              {data[date].residex.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </div>
                            <div className="text-sm flex flex-col items-center justify-between w-full bg-white rounded-md py-2">
                              <div className="font-bold text-xs text-sky-800">
                                GSTR-1
                              </div>
                              ₹
                              {(
                                (data[date].walr + data[date].residex) *
                                0.18
                              ).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <>
                        <div className="animate-pulse flex flex-col justify-center items-center px-2 gap-y-4 overflow-hidden ">
                          <img
                            className="w-32 h-32 "
                            src="/images/no-info.svg"
                            alt=""
                          />
                          <span className="text-center text-xs md:text-sm   font-semibold">
                            {" "}
                            You don't have any Coupon Statements.
                          </span>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>

              {/* Example agent data below */}
              <div className="transition-all duration-1000 ease-in-out overflow-hidden  opacity-100 my-2.5 ">
                <div className="min-w-full border-collapse">
                  {filteredDates.length > 0 ? (
                    <div className="bg-sky-300 text-[#212121] rounded-t-lg">
                      <div className="flex justify-around p-1 text-center text-sm md:text-sm font-bold">
                        <div className="py-1 md:py-2 w-1/3 text-center">
                          Agent Name
                        </div>
                        <div className="py-1 md:py-2 w-1/3 text-center">
                          Agent Code
                        </div>
                        <div className="py-1 md:py-2 w-1/3 text-center pr-4">
                          Amount
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="bg-white rounded-b-lg  overflow-y-scroll max-h-[110px] min-h-[110px]">
                    {data[activeCard]?.agents &&
                      Object.entries(data[activeCard].agents).map(
                        ([key, val], index) => (
                          <div
                            key={index}
                            className="min-w-full rounded-b-lg border-gray-200 font-semibold text-[#212121] flex flex-col gap-y-1 p-2"
                          >
                            <div className="flex items-center justify-center text-sm w-full bg-white border hover:bg-[#1b4b6c] rounded-md">
                              <p className="py-1 md:py-2 text-center w-1/3">
                                {getAgentname(key)}
                              </p>
                              <p className="py-1 md:py-2 text-center w-1/3">
                                {key}
                              </p>
                              <p className="py-1 md:py-2 text-center w-1/3">
                                ₹{" "}
                                {val.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </p>
                            </div>
                            {/* Additional agent data goes here */}
                          </div>
                        )
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal1 && (
        <AlertGreen
          message="Uploaded Successfully"
          onClose={() => setShowModal1(false)}
        />
      )}
    </>
  );
}

export default AgentsCommissions;
