import React, { useState, useEffect, useRef } from "react";
import ReactApexChart from "react-apexcharts";
import AdminCalender from "./DashboardComponents/AdminCalender";
import EventScheduler from "./EventSchedule";
import SchedulePopup from "./DashboardComponents/SchedulePopup";
import TaskBarChart from "./DashboardComponents/TaskBarChart";
import AdminInfoApp from "../API/AdminApi";
import EmployeeInfoApp from "../API/Employee";
import CheckAuth from "../components/ServiceFunctions/CheckAuth";

// React Component
const ApexChart = () => {
  const [adminCalender, setAdminCalender] = useState([]);
  const [events, setEvents] = useState([]);
  const [show, setShow] = useState(false);
  const { email } = CheckAuth();
  const user = [...AdminInfoApp, ...EmployeeInfoApp].find(
    (user) => user?.email?.toLowerCase() === email?.toLowerCase()
  );
  const admin = AdminInfoApp.some(
    (admin) => admin?.email?.toLowerCase() === email?.toLowerCase()
  );
  const name = user ? user.name : null;
  const [chartVisible, setChartVisible] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState("Not Started");
  const [selectedRate, setselectedRate] = useState("unrated");
  const [pieChartData, setPieChartData] = useState([]);
  const [loading2, setLoading2] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [ratedData, setRatedData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownRef2 = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState(name);
  const dur = `${new Date().toLocaleString("en-US", {
    month: "short",
  })} ${new Date().getFullYear()}`;
  const [filter, setFilter] = useState(dur);

  const months = ["Nov 2024", "Dec 2024", "Jan 2025", "Feb 2025", "Mar 2025","Apr 2025"];

  // ,"May 2025","Jun 2025",

  const assignees = [...AdminInfoApp, ...EmployeeInfoApp].map(
    (user) => user.name
  );

  useEffect(() => {
    setLoading2(true);
    fetch(`https://api-scdndestates.com/api/get-event-and-reminders/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ employee: selectedEmployee, filter: filter }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to save event/reminder");
        }
        return response.json();
      })
      .then((response) => {
        setAdminCalender(response.dates);
        setEvents(response.events);
        setLoading2(false);
      })
      .catch((error) => {
        // console.error("Error during logout:", error);
      });
  }, [selectedEmployee, filter]);

  useEffect(() => {
    setSelectedEmployee(name);
  }, [name]);

  const statuses = [
    "Not Started",
    "In Progress",
    "Defective",
    "Pending",
    "Overdue",
    "Postponed",
    "Completed",
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    const handleClickOutside2 = (event) => {
      if (
        dropdownRef2.current &&
        !dropdownRef2.current.contains(event.target)
      ) {
        setIsOpen2(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside2);
    return () => document.removeEventListener("mousedown", handleClickOutside2);
  }, []);

  const transformPieChartData = (data) => {
    const ratings = [
      "unrated",
      "exceeds_expectation",
      "meets_expectation",
      "not_meeting_expectation",
    ];
    let transformedData = [];
    ratedData
      ?.filter((data) => data.name === selectedEmployee)
      ?.forEach((person) => {
        let temp = [0, 0, 0, 0];
        let name = selectedEmployee;
        ratings.map((rate, index) =>
          ["P0", "P1", "P2", "P3", "P4", "P5"].forEach((priority) => {
            Object.values(person?.[rate]?.[priority] || {}).forEach((days) => {
              temp[index] += days.Days;
            });
          })
        );

        transformedData[name] = { series: temp };
      });
    return transformedData;
  };

  // Example Usage

  const mockRatingsData = transformPieChartData(ratedData); // Match object keys

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const fetchRatings = async (filter = "Entire") => {
    await delay(500); // Simulate network delay
    const data = mockRatingsData[selectedEmployee] || { series: [0, 0, 0, 0] };
    return { ...data, filter };
  };

  useEffect(() => {
    const eventsOnDate = events
      .map((event) => ({
        event_date: event.Date,
        title: event.title,
        assignee: event.Assignee,
        desc: event.Description,
        blocker: event.Blocker,
        rating: event.Rating,
        priority: event.Priority,
        status: event.Status,
        budget: event.Budget,
        duration: event.Duration,
        dependency: event.Dependency,
        validators: event.file_validator,
        interrupted: event.Interrupted,
        resumption: event.Resumption,
      }))
      .sort((a, b) => new Date(a.event_date) - new Date(b.event_date));

    let monthlyDataTemp = [];

    assignees?.forEach((assignee) => {
      let assigneeData = {
        name: assignee,
        not_started: 0,
        in_progress: 0,
        defective: 0,
        pending: 0,
        overdue: 0,
        postponed: 0,
        completed: 0,
      };

      statuses?.forEach((status) => {
        let count = Object.values(eventsOnDate).filter((event) => {
          if (filter === "Entire") {
            return (
              event.assignee?.toLowerCase() === assignee?.toLowerCase() &&
              event.status?.toLowerCase() === status?.toLowerCase()
            );
          }
          return (
            new Date(event.event_date).getMonth() ===
              new Date(filter).getMonth() &&
            new Date(event.event_date).getFullYear() ===
              new Date(filter).getFullYear() &&
            event.assignee?.toLowerCase() === assignee?.toLowerCase() &&
            event.status?.toLowerCase() === status?.toLowerCase()
          );
        }).length;
        assigneeData[status?.replace(" ", "_")?.toLowerCase()] = count;
      });

      monthlyDataTemp?.push(assigneeData);
    });

    setFilteredData(monthlyDataTemp);
    setRatedData(convertEventsToData(eventsOnDate, filter));
  }, [
    JSON.stringify(assignees),
    JSON.stringify(events),
    chartVisible,
    filter,
    selectedEmployee,
  ]);

  function convertEventsToData(eventsOnDate, filter) {
    let data = [];
    eventsOnDate
      .filter((event) => {
        if (filter === "Entire") {
          return true;
        }
        return (
          new Date(event.event_date).getMonth() ===
            new Date(filter).getMonth() &&
          new Date(event.event_date).getFullYear() ===
            new Date(filter).getFullYear()
        );
      })
      .forEach((event) => {
        // Handle multiple assignees by splitting them
        let assigneesArr = event.assignee
          ? event.assignee
              .split(",")
              .map((a) => a.trim())
              .filter((a) => a)
          : [""];
        let numAssignees = Math.min(assigneesArr.length, 2); // Limit to max of 2 assignees as specified
        let rating = event.rating?.toLowerCase()?.replace(/\s+/g, "_"); // Convert rating to snake_case
        let priority = event.priority;
        let status = event.status?.replace(/\s+/g, "_");

        let days = event?.duration || "0 days";

        function convertToDays(duration) {
          if (duration?.includes("hour")) {
            return parseFloat(duration) / 8;
          } else if (duration?.includes("day")) {
            return parseFloat(duration);
          } else if (duration?.includes("week")) {
            return parseFloat(duration) * 5;
          }
          return 0; // Default case
        }

        // Calculate the day value and divide by number of assignees (max 2)
        let dayValue = convertToDays(days);
        let dividedDayValue = dayValue / numAssignees;

        // Process each assignee
        assigneesArr.slice(0, 2).forEach((assignee) => {
          // Find or create assignee object
          let assigneeObj = data.find((item) => item.name === assignee);
          if (!assigneeObj) {
            assigneeObj = {
              name: assignee,
              unrated: initPriorityObject(),
              exceeds_expectation: initPriorityObject(),
              meets_expectation: initPriorityObject(),
              not_meeting_expectation: initPriorityObject(),
            };
            data.push(assigneeObj);
          }

          // Find the corresponding status object in the array
          let statusArray = assigneeObj[rating]?.[priority];
          if (statusArray) {
            let statusObj = statusArray.find((obj) =>
              obj.hasOwnProperty(status)
            );
            if (statusObj) {
              statusObj[status]++; // Increment count
              statusObj["Days"] += dividedDayValue; // Add divided days to corresponding field
            }
          }
        });
      });

    return data;
  }

  function initPriorityObject() {
    return {
      P0: initStatusArray(),
      P1: initStatusArray(),
      P2: initStatusArray(),
      P3: initStatusArray(),
      P4: initStatusArray(),
      P5: initStatusArray(),
    };
  }

  function initStatusArray() {
    return [
      { Completed: 0, Days: 0 },
      { Not_Started: 0, Days: 0 },
      { In_Progress: 0, Days: 0 },
      { Defective: 0, Days: 0 },
      { Pending: 0, Days: 0 },
      { Overdue: 0, Days: 0 },
      { Postponed: 0, Days: 0 },
    ];
  }

  const getUniqueMonths = (events) => {
    const monthSet = new Set();

    events.forEach((event) => {
      if (event.event_date) {
        const date = new Date(event.event_date);
        const formattedMonth = date.toLocaleString("en-US", {
          month: "short",
          year: "numeric",
        });
        monthSet.add(formattedMonth);
      }
    });

    return Array.from(monthSet).sort((a, b) => {
      return new Date(a) - new Date(b);
    });
  };

  // Fetch pie chart data when employee or filter changes
  const [showUnrated, setShowUnrated] = useState(true);

  useEffect(() => {
    const loadPieChartData = async () => {
      if (selectedEmployee) {
        const data = await fetchRatings(selectedEmployee, filter);

        let currentSeries = [...data.series];
        const currentLabels = ["Unrated", "EE", "ME", "NE"];
        const currentColors = ["#b3a2de", "#563A9C", "#8B5DFF", "#a786fc"];

        if (!showUnrated) {
          // Set unrated to 0
          currentSeries = [0, ...data.series.slice(1)];
        }

        // Calculate weighted values based on the number of days
        const weightedSeries = currentSeries.map((count, index) => {
          if (data.days && data.days[index] > 0) {
            return count / data.days[index]; // Weighted value
          }
          return count; // Fallback to raw count if days data is missing
        });

        // Calculate percentages for the pie chart
        const totalWeighted = weightedSeries.reduce((sum, val) => sum + val, 0);
        const percentages = weightedSeries.map(
          (val) => (val / totalWeighted) * 100
        );

        setPieChartData({
          series: currentSeries,
          options: {
            chart: {
              type: "donut",
              events: {
                dataPointSelection: function (event, chartContext, config) {
                  const selectedLabel =
                    config.w.config.labels[config.dataPointIndex];
                  const label =
                    selectedLabel === "EE"
                      ? "exceeds_expectation"
                      : selectedLabel === "NE"
                      ? "not_meeting_expectation"
                      : selectedLabel === "ME"
                      ? "meets_expectation"
                      : "unrated";
                  setselectedRate(label);
                },
                legendClick: function (chartContext, seriesIndex) {
                  const selectedLabel = currentLabels[seriesIndex];
                  handleLabelSelection(selectedLabel);
                },
              },
            },
            labels: currentLabels,
            colors: currentColors,
            fill: {
              type: "gradient",
            },
            tooltip: {
              y: {
                formatter: function (value) {
                  return value.toFixed(0) + (value <= 1 ? " day" : " days");
                },
              },
            },
            legend: {
              show: true,
              position: "bottom",
              labels: {
                colors: "#333",
              },
              onItemClick: {
                toggleDataSeries: false,
              },
              formatter: function (seriesName, opts) {
                const percentage =
                  opts.w.globals.series[opts.seriesIndex].toFixed(1);
                // Add visual indicator for unrated toggle state
                if (seriesName === "Unrated") {
                  return [`${seriesName}`];
                }
                return [seriesName];
              },
            },
            states: {
              active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                  type: "none",
                },
              },
            },
          },
        });
      }
    };

    // Helper function to handle label selection
    const handleLabelSelection = (selectedLabel) => {
      if (selectedLabel === "Unrated") {
        setShowUnrated((prev) => !prev); // Toggle the state
      } else {
        const mappedLabel =
          selectedLabel === "EE"
            ? "exceeds_expectation"
            : selectedLabel === "NE"
            ? "not_meeting_expectation"
            : selectedLabel === "ME"
            ? "meets_expectation"
            : "unrated";
        setselectedRate(mappedLabel);
      }
    };

    loadPieChartData();
  }, [
    selectedEmployee,
    filter,
    JSON.stringify(ratedData),
    selectedRate,
    showUnrated,
  ]);

  const taskStatuses = [
    { key: "not_started", label: "Not Started", icon: "/images/cancel.svg" },
    { key: "pending", label: "Pending", icon: "/images/hourglass.svg" },
    { key: "defective", label: "Defective", icon: "/images/radioactive.svg" },
    { key: "completed", label: "Completed", icon: "/images/checklist.svg" },
    {
      key: "in_progress",
      label: "In Progress",
      icon: "/images/processing-time.svg",
    },
    {
      key: "overdue",
      label: "Overdue",
      icon: "/images/coorperation.svg",
    },
    {
      key: "in_research",
      label: "In Research",
      icon: "/images/magnifying-glass.svg",
    },
    { key: "postponed", label: "Postponed", icon: "/images/time.svg" },
  ];

  function formattedDate(date) {
    var newDate = new Date(date);
    const options = { day: "2-digit", month: "short", year: "2-digit" };
    newDate = newDate.toLocaleDateString("en-IN", options)?.replace(" ", "-");
    const formattedDate2 = newDate?.replace(" ", "-");
    return formattedDate2;
  }

  // Inside your component:
  const [count1, setCount1] = useState(0);
  const totalTasks = Math.round(
    filteredData?.find((person) => person.name === selectedEmployee)
      ? Object.values(
          filteredData.find((person) => person.name === selectedEmployee) || {}
        )
          .slice(1)
          .reduce((sum, num) => sum + (num || 0), 0)
      : 0
  );
  useEffect(() => {
    // Reset count to 0 when totalTasks changes
    setCount1(0);
    const increment = Math.ceil(totalTasks / 10); // Will complete in 10 steps
    if (totalTasks > 0) {
      const timer = setInterval(() => {
        setCount1((prev) => {
          if (prev + increment >= totalTasks) {
            clearInterval(timer);
            return totalTasks;
          }
          return prev + increment;
        });
      }, 40);
      return () => clearInterval(timer);
    }
  }, [totalTasks]);

  // The rest of your code remains the same
  const [count2, setCount2] = useState(0);
  const finalValue = Math.round(
    pieChartData?.series?.reduce((sum, num) => sum + num, 0) || 0
  );

  useEffect(() => {
    setCount2(0);
    const increment = Math.ceil(finalValue / 10);
    if (finalValue > 0) {
      const timer = setInterval(() => {
        setCount2((prev) => {
          if (prev + increment >= finalValue) {
            clearInterval(timer);
            return finalValue;
          }
          return prev + increment;
        });
      }, 40);
      return () => clearInterval(timer);
    }
  }, [finalValue]);

  // In your component:
  const [slideClass, setSlideClass] = useState("");

  return (
    <div className="overflow-hidden">
      {chartVisible === 0 ? (
        <>
          <div
            className={`w-full overflow-hidden transition-all duration-700 ease-in-out ${
              show
                ? "fixed inset-0 lg:pl-20 flex items-center bg-slate-100/50 w-full opacity-100 justify-center z-50"
                : " "
            }`}
          >
            {show && (
              <div
                className={`max-md:p-1 md:p-5  rounded-lg  overflow-hidden  w-full transition-all duration-300 ease-in-out ${
                  slideClass || "slide-r-animation"
                } `}
              >
                <SchedulePopup
                  className=" bg-white   shadow-[0px_0px_0px_1px_rgba(0,0,0,0.06),0px_1px_1px_-0.5px_rgba(0,0,0,0.06),0px_3px_3px_-1.5px_rgba(0,0,0,0.06),_0px_6px_6px_-3px_rgba(0,0,0,0.06),0px_12px_12px_-6px_rgba(0,0,0,0.06),0px_24px_24px_-12px_rgba(0,0,0,0.06)] "
                  onReload={(val) => {
                    setEvents(val);
                    setShow(false);
                  }}
                  onClose={() => {
                    setSlideClass("slide-l-animation");
                    setTimeout(() => {
                      setShow(false);
                      setSlideClass(""); // Reset for next time
                    }, 500);
                  }}
                />
              </div>
            )}
          </div>
          <div className=" w-full flex justify-center">
            <div className="p-2 overflow-hidden w-full max-w-[1700px]">
              <div className="flex flex-col items-start justify-center md:mb-10 ">
                <div className="w-full flex  items-start max-md:justify-between md:justify-start gap-4">
                  <div
                    className=" w-32 max-md:mb-4 md:w-44 relative"
                    ref={dropdownRef}
                  >
                    {/* Selected Item */}
                    <button
                      disabled={!admin}
                      className={`w-full relative flex items-center justify-between   px-2 py-1.5  group font-semibold md:text-base  max-md:border-[1.5px] md:border-2 border-[#563A9C] hover:bg-purple-50  overflow-hidden  transition-all duration-200 ease-linear text-black/70  text-[10px] ${
                        isOpen ? "rounded-t-lg bg-purple-50  " : "rounded-lg "
                      }`}
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      {selectedEmployee || "Select Employee"}
                      <img
                        class={`md:w-5 md:h-5 w-4 h-4   stroke-black/70   transition-all duration-500 ease-in-out ${
                          isOpen ? "-rotate-90" : "rotate-90"
                        }`}
                        src="/images/drop1.svg"
                      />
                    </button>

                    <ul
                      className={`absolute no-scrollbar left-0  overflow-y-auto  w-full bg-purple-50  z-10  duration-700 transition-all ease-in-out ${
                        isOpen ? "h-48 rounded-b-lg  " : "h-0  rounded-lg "
                      }`}
                    >
                      {assignees?.map((name, index) => (
                        <li
                          key={index}
                          className={`px-2 py-1.5 text-xs md:text-sm flex justify-between items-center cursor-pointer hover:bg-purple-200 ${
                            selectedEmployee === name
                              ? "bg-purple-200 font-semibold"
                              : ""
                          }`}
                          onClick={() => {
                            setSelectedEmployee(name);
                            setIsOpen(false);
                          }}
                        >
                          {name}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div
                    className=" w-32 max-md:mb-4 md:w-44 relative"
                    ref={dropdownRef2}
                  >
                    {/* Selected Item */}
                    <button
                      className={`w-full relative flex items-center justify-between   px-2 py-1.5  group font-semibold md:text-base  max-md:border-[1.5px] md:border-2 border-[#563A9C] hover:bg-purple-50  overflow-hidden  transition-all duration-200 ease-linear text-black/70  text-[10px] ${
                        isOpen2 ? "rounded-t-lg bg-purple-50  " : "rounded-lg "
                      }`}
                      onClick={() => setIsOpen2(!isOpen2)}
                    >
                      {filter || "Select Month"}

                      <img
                        class={`md:w-5 md:h-5 w-4 h-4 stroke-black/70 transition-all duration-500 ease-in-out ${
                          isOpen2 ? "-rotate-90" : "rotate-90"
                        }`}
                        src="/images/drop1.svg"
                      />
                    </button>

                    <ul
                      className={`absolute no-scrollbar left-0  overflow-y-auto  w-full bg-purple-50  z-10  duration-700 transition-all ease-in-out ${
                        isOpen2
                          ? "h-44 md:h-48 rounded-b-lg  shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                          : "h-0  rounded-lg "
                      }`}
                    >
                      <li
                        className={`px-2 py-1.5 text-xs md:text-sm flex justify-between items-center cursor-pointer hover:bg-purple-200 ${
                          filter === "Entire"
                            ? "bg-purple-200 font-semibold"
                            : ""
                        }`}
                        onClick={() => {
                          setFilter("Entire");
                          setIsOpen2(false);
                        }}
                      >
                        Entire
                      </li>
                      {months?.map((name, index) => (
                        <li
                          key={index}
                          className={`px-2 py-1.5 text-xs md:text-sm flex justify-between items-center cursor-pointer hover:bg-purple-200 ${
                            filter === name ? "bg-purple-200 font-semibold" : ""
                          }`}
                          onClick={() => {
                            setFilter(name);
                            setIsOpen2(false);
                          }}
                        >
                          {name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="w-full flex md:flex-row max-md:flex-col items-center justify-center">
                  <div className="w-full flex items-center justify-center    md:w-1/2">
                    {loading2 ? (
                      <div className="w-full h-full  max-md:min-h-[300px] flex items-center justify-center opacity-50">
                        <img
                          className="w-20 h-20 animate-pulse"
                          src="/images/analytics.gif"
                          alt=""
                        />{" "}
                      </div>
                    ) : (
                      <div className=" w-full h-full items-center flex justify-center">
                        <div className="absolute mb-4 w-40  h-40  flex flex-col items-center gap-y-2 justify-center bg-white p-1 font-medium  rounded-full">
                          <button class="group relative w-24 md:w-32">
                            <div class="relative flex items-center gap-2 rounded-xl   p-1 pr-4">
                              <div class="flex items-center gap-2 rounded-lg ">
                                <div class="relative flex items-center bg-purple-50 max-md:p-0.5 md:p-1 rounded-lg">
                                  <img
                                    class="relative md:h-6 md:w-6 w-4 h-4 fill-purple-500"
                                    src="/images/list.svg"
                                    alt=""
                                  />
                                </div>

                                <div class="flex flex-col items-start leading-3 md:leading-4  justify-center">
                                  <div className="text-[8px] md:text-[10px] ">
                                    A total of
                                  </div>
                                  <div class="flex text-[13px] md:text-[16px] font-bold  ">
                                    {count1} {count1 === 1 ? "task" : "tasks"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </button>
                          <button class="group relative  w-24 md:w-32">
                            <div class="relative flex items-center gap-2 rounded-xl   p-1 pr-4">
                              <div class="flex items-center gap-2 rounded-lg   ">
                                <div class="relative flex items-center bg-purple-50 max-md:p-0.5 md:p-1  rounded-lg">
                                  <img
                                    class="relative md:h-6 md:w-6 w-4 h-4"
                                    src="/images/sun.svg"
                                    alt=""
                                  />
                                </div>
                                <div class="flex flex-col items-start leading-3 md:leading-4 justify-center">
                                  <div className="text-[8px] md:text-[10px] ">
                                    {showUnrated === true
                                      ? "Total Duration"
                                      : "Rated Duration"}
                                  </div>
                                  <div class="flex text-[13px] md:text-[16px] font-bold   ">
                                    {count2} {count2 === 1 ? "day" : "days"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </button>
                        </div>
                        <div className="w-full p-0 min-w-[370px] relative max-w-[500px]">
                          <ReactApexChart
                            options={pieChartData?.options || {}}
                            series={pieChartData?.series || []}
                            type="donut"
                            className=""
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <TaskBarChart
                    rate={selectedRate}
                    employee={selectedEmployee}
                    ratedData={ratedData}
                  />
                </div>
              </div>
              <div className="w-full overflow-hidden flex md:flex-row max-md:flex-col-reverse items-center justify-center md:my-5">
                <div className="w-full flex justify-center md:w-1/2 max-md:p-2 md:px-4 max-md:mt-5">
                  <div className=" max-w-[650px] w-full">
                    <AdminCalender
                      adminCalender={adminCalender}
                      onReload2={(data) => {
                        setEvents(data);
                      }}
                    />
                  </div>
                </div>
                <div className="w-full md:w-1/2">
                  {loading2 ? (
                    <>
                      <div className="w-full flex flex-col items-center justify-center gap-y-4 max-md:px-0 md:px-4 lg:p-0 ">
                        {/* Task Statuses */}
                        <div className=" max-md:w-full max-w-lg rounded-lg flex flex-wrap max-lg:gap-x-3 lg:gap-x-5 gap-y-5 justify-start items-start bg-white p-1 ">
                          {[...Array(9)].map((_, index) => (
                            <div
                              key={index}
                              className="flex flex-col items-center justify-center w-[30%] h-[85px] md:w-24 md:h-24 lg:w-32 lg:h-28 xl:w-36 xl:h-[120px]  text-xs font-semibold   bg-gray-200 gap-x-2    duration-300 ease-in-out rounded-lg  cursor-pointer group animate-pulse"
                            ></div>
                          ))}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {filteredData
                        .filter(
                          (assignee) => assignee.name === selectedEmployee
                        ) // Show only selected employee
                        .map((assignee) => {
                          const totalTasks = taskStatuses.reduce(
                            (total, status) =>
                              total + (assignee[status.key] || 0),
                            0
                          );

                          return (
                            <div className="w-full flex flex-col items-center justify-center gap-y-4 md:px-4 lg:p-0 max-md:mt-5">
                              {/* Task Statuses */}
                              <div
                                key={assignee.name}
                                className="w-[95%] md:w-full max-w-lg rounded-lg flex flex-wrap max-lg:gap-x-3 lg:gap-x-5 gap-y-5 justify-between md:justify-start items-start bg-white p-1 "
                              >
                                <div
                                  onClick={() => setShow(true)}
                                  className="flex flex-col items-center  bg-[#fff] justify-center w-[30%] h-[85px] md:w-24 md:h-24 lg:w-32 lg:h-28 xl:w-36 xl:h-[120px]  text-xs font-semibold text-[#313131]  gap-x-2 border-2 border-[#fff] hover:border-[#f9f6ff] hover:bg-[#f9f6ff] duration-300 ease-in-out rounded-lg p-1.5 cursor-pointer group"
                                >
                                  {/* Task Status Icon */}
                                  <div className="p-1 ">
                                    <img
                                      className=" w-8 h-8 lg:w-10 lg:h-10 xl:w-16 xl:h-16 rounded-lg "
                                      src="/images/image-gallery.svg"
                                      alt="Add Task"
                                    />
                                  </div>

                                  {/* Task Count */}
                                  <div className="flex flex-col items-center   justify-center">
                                    <div className="text-sm xl:text-base">
                                      New Task
                                    </div>
                                  </div>
                                </div>
                                {taskStatuses.map((status) => (
                                  <div
                                    key={status.key}
                                    onClick={() => {
                                      setSelectedStatus(status.label); // Use status.label here
                                      setChartVisible(1);
                                    }}
                                    className="flex flex-col items-center justify-center w-[30%] h-[85px] md:w-24 md:h-24 lg:w-32 lg:h-28 xl:w-36 xl:h-[120px]  text-xs font-semibold text-[#313131] bg-slate-50/35 gap-x-2 border-2 border-gray-200 hover:border-gray-400 hover:bg-slate-50 duration-300 ease-in-out rounded-lg p-1.5 cursor-pointer group"
                                  >
                                    {/* Task Status Icon */}
                                    <div className="p-1 ">
                                      <img
                                        className=" w-8 h-8 lg:w-10 lg:h-10 xl:w-12 xl:h-12 rounded-lg group-hover:animate-pulse"
                                        src={status.icon}
                                        alt={status.label}
                                      />
                                    </div>

                                    {/* Task Count */}
                                    <div className="flex flex-col items-center  leading-[6px] justify-center pt-2">
                                      <div className="text-[10px] xl:text-xs">
                                        {status.label}
                                      </div>
                                      <div className="text-sm lg:text-base xl:text-lg p-0 font-bold">
                                        {assignee[status.key] || 0} Tasks
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          );
                        })}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : chartVisible === 1 ? (
        <div className="w-full h-full p-2">
          <div className="App h-full">
            <EventScheduler
              adminCalender={adminCalender}
              allEvents={events}
              emp={selectedEmployee}
              st={selectedStatus}
              onReload={(data) => {
                setEvents(data);
              }}
              filter={filter}
              searchValue={searchTerm}
              onBack={() => setChartVisible(0)}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ApexChart;
